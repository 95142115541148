import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from "xlsx";

import { AccessControls } from 'src/app/core/models/user.permission.model';
import { AccessControlService } from 'src/app/core/services/access-control.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { MessageItemType } from 'src/app/shared/components/messages/message.model';
import { MessagesService } from 'src/app/shared/components/messages/messages.service';
import { ApplyThemeService } from 'src/app/shared/services/apply-theme.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { DropdownOptionsSettingsService } from 'src/app/shared/services/dropdown-options-settings';
import { StateManagementService } from 'src/app/shared/services/state.service';
import { IBadgeUpdateResponse } from '../../models/batch-upload.model';
import { DataService } from 'src/app/shared/services/data.service';
import { One2FiveLoadingService } from 'src/app/shared/components/app-loader/loader.service';

@Component({
  selector: 'app-badge-update',
  templateUrl: './badge-update.component.html',
  styleUrls: ['./badge-update.component.scss']
})
export class BatchUpdateComponent {
  accessControls: AccessControls;
  arrMarketList: any[] = [];
  brandNameOriginalList: any[] = [];
  marketListSelectedItems: any[] = [];
  marketDropdownSettings = {};
  file!: File;
  isFileValid: boolean = false;
  visibleBatchUploadWindow: boolean = false;

  constructor(private translate: TranslateService,
    public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    private stateServvice: StateManagementService,
    public commonUtilService: CommonUtilService,
    private dataService: DataService) {
    this.accessControls = this.accessControlService.configAccess(
      ConstantsService.BADGE_UPDATE
    );
  }

  ngOnInit() {
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
      this.changeTranslationWords();
    });

    this.marketDropdownSettings = {
      singleSelection: true,
      primaryKey: "regionName",
      text: this.commonUtilService.translateWords(["Select", "Brand", "Site"]),
      enableSearchFilter: true,
      classes: "ddCustomClass",
      maxHeight: 200,
      escapeToClose: true,
      lazyLoading: true
    };
    this.getBrandSite();
  }

  initialiseWindow(): void {
    this.visibleBatchUploadWindow = true;
  }
  getBrandSite() {
    this.arrMarketList = [];
    this.marketListSelectedItems = [];

    this.stateServvice.getCountrySelect().subscribe((data: any) => {
      this.arrMarketList = data;
      this.brandNameOriginalList = data;
    }, (error) => {
      MessagesService.showFailureAlert(error);
    });
  }

  onBrandSearchKeyUp(evt: any) {
    this.arrMarketList = this.brandNameOriginalList.filter(element => element.itemName.toString().toUpperCase().includes(evt.target.value.toString().toUpperCase()));
  }

  removeItemFromArray(arr: any, attr: any, value: any): any {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  onSelectFile(event: any) {
    this.validateFile(event.target.files);
    this.parseXlxs(event.target.files);
  }

  onFileDroped(target: any) {
    this.validateFile(target);
    this.parseXlxs(target);
  }

  parseXlxs(target: any) {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const _data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      this.file = target[0];
    };
    reader.readAsBinaryString(target[0]);
  }

  async validateFile(files: any) {
    if (files.length !== 1) {
      throw new Error(this.commonUtilService.translateMessage("Cannot use multiple files"));
    }

    const validExts = [".xlsx", ".xls"];
    let fileExt = files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));

    if (validExts.indexOf(fileExt) < 0) {
      this.isFileValid = false;
      MessagesService.alertMessages("Please upload valid file", "", "Validation Alert", MessageItemType.Warning);

      let invalidMessage = await this.commonUtilService.transalateInputText("Invalid file selected, valid files are of ")+ validExts.toString();
      invalidMessage += await this.commonUtilService.transalateInputText(" types");
      throw new Error(invalidMessage);
    } else {
      this.isFileValid = true;
    }
  }

  reUpload() {
    this.isFileValid = false;
  }

  validateForm(): boolean {
    if (!this.marketListSelectedItems.length) {
      MessagesService.alertMessages("Please select at least one market", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (!this.file) {
      MessagesService.alertMessages("Please upload file", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    return true;
  }

  uploadFile() {
    if (!this.validateForm()) return;

    const inputPayload = new FormData();
    inputPayload.append("marketId", this.marketListSelectedItems[0].marketId);
    inputPayload.append("file", this.file);
    One2FiveLoadingService.show();

    this.dataService.updateBadgeXls(inputPayload).subscribe((responseData: IBadgeUpdateResponse) => {
      One2FiveLoadingService.hide();
      if (responseData["HasErrors"]) {
        MessagesService.errorAlert("ERRORS.Error " + responseData["Errors"][0].Message);
        return;
      }
      this.visibleBatchUploadWindow = false;
      let messages: string[] = [`Update Badge Count : ${responseData.updatedCount}`,
      `Skipped Badge Count : ${responseData.skippedCount}`,
      `Badge Already Already Count : ${responseData.alreadyExistCount}`];
      responseData.messages.forEach(_messaage => messages.push(_messaage));
      MessagesService.successDetailsMessages(messages);
    });
  }

  onCloseUpdateWindow() {
    this.visibleBatchUploadWindow = false;
  }

  private async changeTranslationWords() {
    const translatedSearchLabel = await this.commonUtilService.transalateInputText("Search");
    const selectAllLabel = await this.commonUtilService.transalateInputText("DROPDOWNS.Select All");
    const unSelectAllLabel = await this.commonUtilService.transalateInputText("DROPDOWNS.UnSelect All");

    let brandSitePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Brand Site");
    this.marketDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.marketDropdownSettings, brandSitePlaceholderText, translatedSearchLabel, selectAllLabel, unSelectAllLabel);
  }

}
