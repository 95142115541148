import { Output, EventEmitter, Component } from "@angular/core";

import { ApplyThemeService } from "../../services/apply-theme.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";

@Component({
  selector: "app-profile-menu",
  templateUrl: "./profile-menu.component.html",
  styleUrls: ["./profile-menu.component.scss"]
})
export class ProfileMenuComponent {
  @Output() editProfile = new EventEmitter<boolean>();
  @Output() changePassword = new EventEmitter<boolean>();
  @Output() changeTheme = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();
  settingsOpen = false;

  constructor(public applyThemeService: ApplyThemeService,
    public commonUtilService: CommonUtilService) {
  }

  onEditProfile() {
    this.editProfile.emit(true);
  }

  onChangePassword() {
    this.changePassword.emit(true);
  }

  onChangeTheme() {
    this.changeTheme.emit(true);
  }

  onSettingsClicked() {
    this.settingsOpen = !this.settingsOpen;
  }

  onLogOut() {
    this.logout.emit(true);
  }

}
