export interface IState {
  data: any[];
  isLoading: boolean;
  message: string;
}

export const initialState: IState = {
  data: [],
  isLoading: false,
  message: ''
};