import { CountryData, languageData, NumberNullType } from "src/app/shared/models/referance.model";

export class UserProfileModel {
  firstName: string = "";
  lastName: string = "";
  salutation: string = "";
  nickname: string = "";
  emailId: string = "";
  streetNo: string = "";
  street: string = "";
  city: string = "";
  state: string = "";
  zipcode: string = "";
  companyName: string = "";
  imagePath: string = "";

  email: string = "";
  userNickname: string = "MSM";
}

export class UserAccountData {
  id: number | null = null;
  salutation: string = "";
  firstName: string = "";
  lastName: string = "";
  userName: string = "";
  email: string = "";
  language: languageData[] = [];
  country: CountryData[] = [];
  company: string| null = null;
  userRole: string = "";
  status: string = "";
  permissionGroupName: string = "";
  selectedMarkets: string[] = [];
}


export interface ILoginUserCrediantials {
  companyName: string;
  userName: string;
  password: string;
}

export class CurrentUser {
  token: string = "";
  userName: string = "";
  role: string = "";
  su: boolean = false;
  isExternalLogin: string | boolean = false;
  userProfile: UserProfileModel = new UserProfileModel;
}


export interface LatestLogin{
	profileId: number;
	activityTime:string;
	deviceType: string;
	deviceInfo:string;		
}