<div class="modal modal-md" [ngStyle]="{ 'display': loadUpdateEmailWindow ? 'block' : 'none' }">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header"
                [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
                <div></div>
                <div class="modalHeaderTitle">
                    {{ "Update Email Address" | translate}}
                </div>
                <button class="btn"
                    [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
                    (click)="onCloseWindowClick()">
                    <span class="icon icon-cancel"></span>
                </button>
            </div>

            <div class="modal-body p-4">
                <div class="row mt-1 AI_C">
                    <div class="col-md-1 col-lg-1"></div>
                    <div class="col-md-10 col-lg-10 note">
                        <strong>{{ "Note" | translate }}:</strong>
                        {{ "Please enter your email address as it will be used for communications in emergency
                        situations" |
                        translate }}.
                    </div>
                    <div class="col-md-1 col-lg-1"></div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-1 col-lg-1"></div>
                    <div class="col-md-10 col-lg-10">
                        <input type="email" class="w-100 boxShadow" name="emailaddress"
                            placeholder='{{"Enter email address"| translate}}' [(ngModel)]="userProfile.email" />
                    </div>
                    <div class="col-md-1 col-lg-1"></div>
                </div>
            </div>

            <div class="modal-footer gap-4 mt-1" [class.JC_SB]="errorMessage">
                <ng-container *ngIf="errorMessage">
                    <h1 class="errorMsg">{{ errorMessage | translate }}</h1>
                </ng-container>
                <button type="button" class="btn btn-primary" 
                    [disabled]="!userProfile.email"
                    [class.disabled]="!userProfile.email"
                    [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor, 'color': applyThemeService.$textColor }"
                    (click)="onSubmitEmail()">
                    {{ "BUTTONS.Submit" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>