<div class="overlayWrapper PF style-4">
    <div class="row mainRow">
        <div class="col-md-1"></div>
        <div class="col-md-10 themeDetailView">
            <div class="row containerHeader W-A"
                [ngStyle]="{ 'background-color': themeTemplateModel.themeColor, color: themeTemplateModel.textColor }">
                <div class="col-md-offset-1 col-md-9 editProfileName">
                    {{"MENUITEMS.Change Theme"| translate}}
                </div>
                <div class="col-md-offset-1 col-md-1">
                    <span class="closeEditProfile pull-right" (click)="onCloseChangeThemeWindow()">
                        <span class="iconContainerSpan spanContainer closeBtnContainer">
                            <span class="icon icon-cancel"></span>
                        </span>
                    </span>
                </div>
            </div>

            <div class="row rowMargin">
                <div class="col-md-6">
                    <div class="col-md-4 MT-5">
                        {{"Welcome"| translate}} {{"Title"| translate}} :
                    </div>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="themeTemplateModel.welcomeTitle"
                            class="reportNameInput boxShadow" name="welcomeTitle"
                            placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Welcome"| translate}} {{"Title"| translate}}' />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-4 MT-5">
                        {{"Welcome"| translate}} {{"Text"| translate}} :
                    </div>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="themeTemplateModel.welcomeText"
                            class="reportNameInput boxShadow" name="welcomeText"
                            placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Welcome"| translate}} {{"Text"| translate}}' />
                    </div>
                </div>
            </div>

            <div class="row rowMargin MT-10">
                <div class="col-md-6">
                    <div class="col-md-4 MT-5">
                        {{"Copyright Label"| translate}} :
                    </div>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="themeTemplateModel.copyrightLabel"
                            class="reportNameInput boxShadow" name="copyrightLabel"
                            placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Copyright Label"| translate}}' />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-4 MT-5">
                        {{"Document"| translate}} {{"Title"| translate}} :
                    </div>
                    <div class="col-md-8">
                        <input type="text" [(ngModel)]="themeTemplateModel.documentTitle"
                            class="reportNameInput boxShadow" name="copyrightLabel"
                            placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Document"| translate}} {{"Title"| translate}}' />
                    </div>
                </div>
            </div>

            <div class="row rowMargin uploadFileRow">
                <div class="col-md-6 profileImageCol">
                    <div class="col-md-4">
                        {{"Large"| translate}} {{"Logo"| translate}} :
                    </div>
                    <div class="col-md-8 TA_L">
                        <label *ngIf="!isLargeLogo" class="uploadImage">
                            <input type="file" (change)="onLargeFileChange($event)"
                                placeholder='{{"Select"| translate}} {{"Image"| translate}}'
                                accept="image/x-png,image/jpeg,image/jpg" />
                        </label>
                        <span *ngIf="isLargeLogo">
                            <a [href]="largeLogoPath" target="_blank">{{"BUTTONS.View"| translate}} {{"Large"|
                                translate}} {{"Logo"|
                                translate}}</a>
                            <span class="removeClass" (click)="onRemoveFile('isLargeLogo')">
                                <span class="labelRemove">{{"remove"| translate}}</span></span>
                        </span>
                    </div>
                </div>

                <div class="col-md-6 profileImageCol">
                    <div class="col-md-4">
                        {{"Small"| translate}} {{"Logo"| translate}} :
                    </div>
                    <div class="col-md-8 TA_L">
                        <label *ngIf="!isSmallLogo" class="uploadImage">
                            <input type="file" (change)="onSmallFileChange($event)"
                                placeholder='{{"Select"| translate}} {{"Image"| translate}}'
                                accept="image/x-png,image/jpeg,image/jpg" />
                        </label>
                        <span *ngIf="isSmallLogo">
                            <a [href]="smallLogoPath" target="_blank">{{"BUTTONS.View"| translate}} {{"Small"|
                                translate}} {{"Logo"|
                                translate}}</a>
                            <span class="removeClass" (click)="onRemoveFile('isSmallLogo')">
                                <span class="labelRemove">{{"remove"| translate}}</span></span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row rowMargin uploadFileRow">
                <div class="col-md-6 profileImageCol">
                    <div class="col-md-4">
                       {{"Favicon"| translate}} :
                    </div>
                    <div class="col-md-8 TA_L">
                        <label *ngIf="!isFavicon" class="uploadImage">
                            <input type="file" (change)="onFavIconImageChange($event)"
                                placeholder='{{"Select"| translate}} {{"Image"| translate}}'
                                accept="image/x-png,image/jpeg,image/jpg" />
                        </label>
                        <span *ngIf="isFavicon">
                            <a [href]="faviconPath" target="_blank">{{"BUTTONS.View"| translate}} {{"Favicon"|
                                translate}}</a>
                            <span class="removeClass" (click)="onRemoveFile('isFavicon')">
                                <span class="labelRemove">{{"remove"| translate}}</span></span>
                        </span>
                    </div>
                </div>

                <div class="col-md-6 profileImageCol">
                    <div class="col-md-4">
                        {{"Privacy Statement"| translate}} :
                    </div>
                    <div class="col-md-8 TA_L">
                        <label *ngIf="!isPrivacyStatement" class="uploadImage">
                            <input type="file" (change)="onPrivacyStatementChange($event)"
                                placeholder='{{"Select"| translate}} {{"HTML"| translate}} {{"Document"| translate}}'
                                accept="text/html" />
                        </label>
                        <span *ngIf="isPrivacyStatement">
                            <a [href]="privacyStatementPath" target="_blank">{{"BUTTONS.View"| translate}} 
                                {{"Privacy Statement" | translate }}
                            </a>
                            <span class="removeClass" (click)="onRemoveFile('isPrivacyStatement')">
                                <span class="labelRemove">{{"remove"| translate}}</span></span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row rowMargin uploadFileRow">
                <div class="col-md-6 profileImageCol">
                    <div class="col-md-4">
                        {{"About Us"| translate}} :
                    </div>
                    <div class="col-md-8 TA_L">
                        <label *ngIf="!isAboutUs" class="uploadImage">
                            <input type="file" (change)="onAboutUsFileChange($event)"
                                placeholder='{{"Select"| translate}} {{"HTML"| translate}} {{"Document"| translate}}'
                                accept="text/html" />
                        </label>
                        <span *ngIf="isAboutUs">
                            <a [href]="aboutUsPath" target="_blank">{{"BUTTONS.View"| translate}} {{"About Us"|
                                translate}}</a>
                            <span class="removeClass" (click)="onRemoveFile('isAboutUs')">
                                <span class="labelRemove">{{"remove"| translate}}</span></span>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Color Option -->
            <div class="row rowMargin uploadFileRow">
                <div class="col-md-4 colorOption">
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        {{"Theme"| translate}} {{"Color"| translate}} :
                    </div>
                    <div class="col-md-5">
                        <input class="circleColor" [style.background]="themeTemplateModel.themeColor"
                            [cpOKButton]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'" cpPosition="bottom-right"
                            cpDialogDisplay="popup" cpOutputFormat="hex" [(colorPicker)]="themeTemplateModel.themeColor"
                            (colorPickerSelect)="onClickThemeColorOkBtn($event)"
                            (cpSliderDragEnd)="onChangeThemeColor($event)" />
                    </div>
                </div>

                <div class="col-md-4 colorOption">
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        {{"Text"| translate}} {{"Color"| translate}} :
                    </div>
                    <div class="col-md-5">
                        <input class="circleColor" [style.background]="themeTemplateModel.textColor" [cpOKButton]="true"
                            [cpOKButtonClass]="'btn btn-primary btn-xs'" cpPosition="bottom-right"
                            cpDialogDisplay="popup" cpOutputFormat="hex" [(colorPicker)]="themeTemplateModel.textColor"
                            (colorPickerSelect)="onClickTextColorOkBtn($event)"
                            (cpSliderDragEnd)="onChangeTextColor($event)" />
                    </div>
                </div>

                <div class="col-md-4 colorOption">
                    <div class="col-md-6">
                        {{"Secondary"| translate}} {{"Color"| translate}} :
                    </div>
                    <div class="col-md-5">
                        <input class="circleColor W-50 H-20" [style.background]="themeTemplateModel.secondaryColor"
                            [cpOKButton]="true" cpPosition="bottom-left" cpDialogDisplay="popup"
                            [cpOKButtonClass]="'btn btn-primary btn-xs'" cpOutputFormat="hex"
                            [(colorPicker)]="themeTemplateModel.secondaryColor"
                            (colorPickerSelect)="onClickSecondaryColorOkBtn($event)"
                            (cpSliderDragEnd)="onChangeSecondaryColor($event)" />
                    </div>
                </div>
            </div>

            <div class="row rowMargin uploadFileRow MT-5">
                <div class="col-md-5 colorOption"></div>
                <div class="col-md-3 colorOption">
                    <button class="btn btn-primary changeToDefaultBtn"
                        [ngStyle]="{ 'background-color': themeTemplateModel.themeColor, 'border-color': themeTemplateModel.themeColor, color: themeTemplateModel.textColor }"
                        (click)="onChangeToDefaultTheme()">
                        {{"Change"| translate}} {{"to"| translate}} {{"Default"| translate}}
                    </button>
                </div>
                <div class="col-md-4 colorOption"> </div>
            </div>
            <!-- End Color Option -->

            <div class="row preview">
                <div class="row rowHeader">
                    <!-- Header -->
                    <div class="col-md-1"></div>

                    <div class="col-md-10 shadow" [style.background]="themeTemplateModel.themeColor">
                        <span *ngIf="isSmallLogo" class="logo"
                            [style.backgroundImage]="'url(' + smallLogoPath + ')'"></span>
                        <span *ngIf="!isSmallLogo" class="logo">
                            <img class="img-responsive" [src]="logoPlaceholder" alt="Brand Logo" />
                        </span>

                        <span class="userProfile" [style.background]="themeTemplateModel.themeColor">
                            <img class="smallProfile" src="/assets/images/profilePicBig.png" alt="logo" />
                            <label class="userName" for="name" [style.color]="themeTemplateModel.textColor">{{"Hi"|
                                translate}},
                                John</label>
                        </span>
                    </div>

                    <div class="col-md-1"></div>
                </div>
                <!-- End Header -->

                <!-- Main View -->
                <div class="row mainView">
                    <div class="col-md-1"></div>
                    <div class="col-md-2 drawerMenu" [style.background]="themeTemplateModel.themeColor">
                        <ul class="menuList">
                            <li [style.color]="themeTemplateModel.textColor"
                                [style.background]="themeTemplateModel.secondaryColor">
                                Lorem ipsum
                            </li>
                            <li [style.color]="themeTemplateModel.textColor">Lorem ipsum</li>
                            <li [style.color]="themeTemplateModel.textColor">Lorem ipsum</li>
                            <!-- <li [style.color]="themeTemplateModel.textColor">Lorem ipsum</li> -->
                        </ul>
                    </div>

                    <div class="col-md-8 contentDiv">
                        <div class="row">
                            <div class="pageTitle">
                                <label for="page" class="labelTitle">{{"Ratings" | translate}} {{"and" | translate}}
                                    {{"Reviews" |
                                    translate}}</label>
                            </div>
                        </div>

                        <div class="title" [style.background]="themeTemplateModel.themeColor">
                            <label for="page" class="MT-5 ML-10" [style.color]="themeTemplateModel.textColor">Lorem ipsum</label>
                        </div>
                        <div class="filterDiv"></div>

                        <div class="title" [style.background]="themeTemplateModel.themeColor">
                            <label for="page" class="MT-5 ML-10" [style.color]="themeTemplateModel.textColor">Lorem ipsum</label>
                        </div>
                        <div class="filterDiv"></div>
                    </div>

                    <div class="col-md-1"></div>
                </div>
                <!-- End Main View -->
            </div>

            <!-- Sumbit Button -->
            <div class="row TA_C">
                <div class="col-md-9"></div>
                <div class="col-md-2">
                    <button *ngIf="accessControls.isCreate" type="button" class="btn btn-primary customBtn MB-5"
                        [ngStyle]="{ 'background-color': themeTemplateModel.themeColor, 'border-color': themeTemplateModel.themeColor, color: themeTemplateModel.textColor }"
                        (click)="onSetNewThemeClick()">
                        {{"BUTTONS.Submit"| translate}}
                    </button>
                </div>
                <div class="col-md-1"></div>
            </div>
            <!-- End Sumbit Button -->
        </div>
        <div class="col-md-1"></div>
    </div>
</div>