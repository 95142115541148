import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityService } from "./security.service";

@Injectable({
  providedIn: "root"
})
export class CanActivateGuard implements CanActivate {

  constructor(private securityService: SecurityService) { }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkIfUserLoggedIn();
  }

  checkIfUserLoggedIn() {
    const isLoggedIn = this.securityService.checkIfUserLoggedIn();
    if (!isLoggedIn) {
      this.securityService.logoutUser();
    }
    return isLoggedIn;
  }

}
