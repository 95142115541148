import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[validateNumber]'
})
export class ValidateNumberDirective {
  @Input('validateNumber') decimals = '';

  private check(value: string) {
    let [length, precision] = this.decimals.split('.'),
      regExpString = `^([\\d]{0,${+length}})((\\.{1})([\\d]{1,${+precision}})?)?$`;
    return String(value).match(new RegExp(regExpString));
  }

  private run(oldValue: string) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;
      if (currentValue && !this.check(currentValue)) {
        let _control: NgControl | any = this.control.control;
        _control.patchValue(oldValue);
      }
    }, 0);
  }

  constructor(private el: ElementRef, 
    private control: NgControl) { }

  @HostListener("keydown", ["$event"])
  onKeyDown(_event: KeyboardEvent) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(_event: ClipboardEvent) {
    this.run(this.el.nativeElement.value);
  }

}