import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum CategoryActionTypes {
    GetCategoryLoad = '[Category] Get Category',
    GetCategorySuccess = '[Category] Get Category Success',
    GetCategoryFail = '[Category] Get Category Fail'
}

export class GetCategoryLoad implements Action {
    public readonly type = CategoryActionTypes.GetCategoryLoad;
    
    constructor(public payload: any) { }
}

export class GetCategorySuccess implements Action {
    public readonly type = CategoryActionTypes.GetCategorySuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetCategoryFail implements Action {
    public readonly type = CategoryActionTypes.GetCategoryFail;

    constructor(public error: HttpErrorResponse) { }
}


export type CategoryAction = GetCategoryLoad | GetCategorySuccess | GetCategoryFail;
