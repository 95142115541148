import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum ThemeDataActionTypes {
    GetThemeDataLoad = '[ThemeData] Get ThemeData',
    GetThemeDataSuccess = '[ThemeData] Get ThemeData Success',
    GetThemeDataFail = '[ThemeData] Get ThemeData Fail'
}

export class GetThemeDataLoad implements Action {
    public readonly type = ThemeDataActionTypes.GetThemeDataLoad;
    
    constructor(public payload: any) { }
}

export class GetThemeDataSuccess implements Action {
    public readonly type = ThemeDataActionTypes.GetThemeDataSuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetThemeDataFail implements Action {
    public readonly type = ThemeDataActionTypes.GetThemeDataFail;

    constructor(public error: HttpErrorResponse) { }
}


export type ThemeDataAction = GetThemeDataLoad | GetThemeDataSuccess | GetThemeDataFail;
