import { BadgeTemplateAction, BadgeTemplateActionTypes } from '../actions/badge-template-action';
import { BrandAction, BrandActionTypes } from '../actions/brand2local.action';
import { CampaignAction, CampaignActionTypes } from '../actions/campaign-action';
import { CategoryAction, CategoryActionTypes } from '../actions/category-data.action';
import { ModerationCodes4filterAction, ModerationCodes4filterActionTypes } from '../actions/moderationcodes-filter.action';
import { ProductAction, ProductActionTypes } from '../actions/product-data.action';
import { SavedFiltersAction, SavedFiltersActionTypes } from '../actions/saved-filters.action';
import { SourceAction, UserActionTypes } from '../actions/source.action';
import { ThemeDataAction, ThemeDataActionTypes } from '../actions/theme-data.action';
import { IState, initialState } from '../models/state.model';

export function sourceReducer(state = initialState, action: SourceAction | any): IState {

    switch (action.type) {
        case UserActionTypes.GetSourceLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case UserActionTypes.GetSourceSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case UserActionTypes.GetSourceFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

export function brandToLocalReducer(state = initialState, action: BrandAction | any): IState {

    switch (action.type) {
        case BrandActionTypes.GetBrandLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case BrandActionTypes.GetBrandSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case BrandActionTypes.GetBrandFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

export function productDataReducer(state = initialState, action: ProductAction | any): IState {

    switch (action.type) {
        case ProductActionTypes.GetProductLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case ProductActionTypes.GetProductSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case ProductActionTypes.GetProductFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

export function categoryDataReducer(state = initialState, action: CategoryAction | any): IState {

    switch (action.type) {
        case CategoryActionTypes.GetCategoryLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CategoryActionTypes.GetCategorySuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case CategoryActionTypes.GetCategoryFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

export function campaignsReducer(state = initialState, action: CampaignAction | any): IState {

    switch (action.type) {
        case CampaignActionTypes.GetCampaignLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CampaignActionTypes.GetCampaignSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case CampaignActionTypes.GetCampaignFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}


export function savedFiltersReducer(state = initialState, action: SavedFiltersAction | any): IState {

    switch (action.type) {
        case SavedFiltersActionTypes.GetSavedFiltersLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case SavedFiltersActionTypes.GetSavedFiltersSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case SavedFiltersActionTypes.GetSavedFiltersFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}


export function moderationCodes4filterReducer(state = initialState, action: ModerationCodes4filterAction | any): IState {

    switch (action.type) {
        case ModerationCodes4filterActionTypes.GetModerationCodes4filterLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case ModerationCodes4filterActionTypes.GetModerationCodes4filterSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case ModerationCodes4filterActionTypes.GetModerationCodes4filterFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

export function badgeTemplateReducer(state = initialState, action: BadgeTemplateAction | any): IState {

    switch (action.type) {
        case BadgeTemplateActionTypes.GetBadgeTemplateLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case BadgeTemplateActionTypes.GetBadgeTemplateSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case BadgeTemplateActionTypes.GetBadgeTemplateFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}


export function themeDataReducer(state = initialState, action: ThemeDataAction | any): IState {

    switch (action.type) {
        case ThemeDataActionTypes.GetThemeDataLoad: {
            return {
                ...state,
                isLoading: true
            }
        }

        case ThemeDataActionTypes.GetThemeDataSuccess: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                message: 'Data fetch successfully'
            }
        }
        case ThemeDataActionTypes.GetThemeDataFail: {
            return {
                data: [],
                isLoading: false,
                message: 'Something went wrong!'
            }
        }
        default:
            return state;
    }
}

