import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromActions from '../index';
import { API_URLS } from '../../constants/api_urls';

@Injectable()
export class O2FEffects {

    constructor(private actions$: Actions,
        private httpClient: HttpClient) {
    }

    getReviewSources$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.UserActionTypes.GetSourceLoad),
        map((action: fromActions.GetSourceSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.get<any[]>(API_URLS.reviewSourceURL, { headers: payload.headers })
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetSourceSuccess(response);
                        return new fromActions.GetSourceSuccess(response.results);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetSourceFail(error)))
                )
        }
        ))
    );

    getBrandToLocal$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.BrandActionTypes.GetBrandLoad),
        map((action: fromActions.GetBrandSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.get<any[]>(API_URLS.getCountrySelectData, { headers: payload.headers })
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetBrandSuccess(response);
                        return new fromActions.GetBrandSuccess(response.brandLocale);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetBrandFail(error)))
                )
        }
        ))
    );

    getProductData$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ProductActionTypes.GetProductLoad),
        map((action: fromActions.GetProductSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.post<any[]>(API_URLS.productDataMultiURL, payload.data, payload.isAddParams)
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetProductSuccess(response);
                        return new fromActions.GetProductSuccess(response.productDataList);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetProductFail(error)))
                )
        }
        ))
    );

    getCategoryData$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CategoryActionTypes.GetCategoryLoad),
        map((action: fromActions.GetCategorySuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.post<any[]>(API_URLS.categoryDataMultiURL, payload.data, payload.isAddParams)
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetCategorySuccess(response);
                        return new fromActions.GetCategorySuccess(response.categoryData);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetCategoryFail(error)))
                )
        }
        ))
    );

    getCampaignData$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.CampaignActionTypes.GetCampaignLoad),
        map((action: fromActions.GetCampaignSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.post<any[]>(API_URLS.getCampaignNameURL, payload.data, payload.isAddParams)
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetCampaignSuccess(response);
                        return new fromActions.GetCampaignSuccess(response.campaigns);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetCampaignFail(error)))
                )
        }
        ))
    );

    getSavedFilters$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.SavedFiltersActionTypes.GetSavedFiltersLoad),
        map((action: fromActions.GetSavedFiltersSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.get<any[]>(API_URLS.savefilterTemplateURL, { headers: payload.headers })
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetSavedFiltersSuccess(response);
                        return new fromActions.GetSavedFiltersSuccess(response.results);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetSavedFiltersFail(error)))
                )
        }
        ))
    );

    getModerationCodesForFilter$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ModerationCodes4filterActionTypes.GetModerationCodes4filterLoad),
        map((action: fromActions.GetModerationCodes4filterSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.post<any[]>(API_URLS.getModerationCodesForFilterURL, payload.data, payload.isAddParams)
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetModerationCodes4filterSuccess(response);
                        return new fromActions.GetModerationCodes4filterSuccess(response.moderationCode);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetModerationCodes4filterFail(error)))
                )
        }
        ))
    );

    getBadgeTemplates$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.BadgeTemplateActionTypes.GetBadgeTemplateLoad),
        map((action: fromActions.GetBadgeTemplateSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.get<any[]>(API_URLS.getBadgeDataUrl, { headers: payload.headers })
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetBadgeTemplateSuccess(response);
                        return new fromActions.GetBadgeTemplateSuccess(response);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetBadgeTemplateFail(error)))
                )
        }
        ))
    );

    getThemeData$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemeDataActionTypes.GetThemeDataLoad),
        map((action: fromActions.GetThemeDataSuccess) => action.payload),
        mergeMap((payload: any) => {
            return this.httpClient.get<any[]>(API_URLS.getThemeURL, { headers: payload.headers })
                .pipe(
                    map((response: any) => {
                        if (response.HasErrors) return new fromActions.GetThemeDataSuccess(response);
                        return new fromActions.GetThemeDataSuccess(response);
                    }),
                    catchError((error) =>
                        of(new fromActions.GetThemeDataFail(error)))
                )
        }
        ))
    );

}
