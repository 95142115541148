import { Component } from "@angular/core";

import { ApplyThemeService } from "src/app/shared/services/apply-theme.service";

@Component({
  selector: "app-master-layout",
  templateUrl: "./master-layout.component.html",
  styleUrls: ["./master-layout.component.scss"],
})
export class MasterLayoutComponent {
  
  constructor(public applyThemeService: ApplyThemeService) {}

}
