import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { One2FiveUtils } from "./one2five-utils";
import { AuthenticationService } from "../authentication/authentication.service";
import { CurrentUser } from "../models/user-profile.model";
import { MessagesService } from "src/app/shared/components/messages/messages.service";
import { MessageItemType } from "src/app/shared/components/messages/message.model";

const HOURS_UNITL_AUTO_LOGOUT = 1; // in hours
const CHECK_INTERVAL = 30000; // in ms
const LAST_ACTION_STORE_KEY = "lastAction";

@Injectable({ providedIn: "root" })
export class AutoLogoutService {
  intervalToCheckInactivity: any;

  constructor(private zone: NgZone,
    private authenticationService: AuthenticationService) {
    this.initializeService();
  }

  public getLastAction(): any {
    const key: any = localStorage.getItem(LAST_ACTION_STORE_KEY);
    return parseInt(key, 10);
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(LAST_ACTION_STORE_KEY, lastAction.toString());
  }

  initializeService() {
    if (One2FiveUtils.parseString(sessionStorage.getItem("currentUser"))) {
      if (One2FiveUtils.parseString(sessionStorage.getItem("currentUser")).token) {
        this.initListener();
        this.initInterval();
      }
    }
  }

  initListener() {
    if (One2FiveUtils.parseString(sessionStorage.getItem("currentUser")).token) {
      this.zone.runOutsideAngular(() => {
        this.listenToUserActivity();
      });
    }
  }

  listenToUserActivity() {
    document.body.addEventListener("mouseover", () => this.reset());
    document.body.addEventListener("keypress", () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    this.intervalToCheckInactivity = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const currentUser: CurrentUser = One2FiveUtils.parseString(
      sessionStorage.getItem("currentUser")
    );
    if (currentUser && currentUser.token) {
      if (this.checkIfTimeoutExceeded()) {
          MessagesService.alertMessages("ERRORS.SessionTimeout", "", "Session Timeout", MessageItemType.Error);
          this.clearIntervalAndLogoutUser();
      }
    } else {
      if (this.intervalToCheckInactivity) {
        clearInterval(this.intervalToCheckInactivity);
      }
    }
  }

  clearIntervalAndLogoutUser() {
    this.authenticationService.logoutUser();
    clearInterval(this.intervalToCheckInactivity);
  }

  checkIfTimeoutExceeded() {
    const now = Date.now();
    const timeleft = this.getLastAction() + HOURS_UNITL_AUTO_LOGOUT * 60 * 60 * 1000;
    const diff = timeleft - now;
    return diff < 0;
  }

}
