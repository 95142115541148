import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { DaterangepickerComponent, DaterangepickerConfig } from 'ng2-daterangepicker';
import ResizeObserver from 'resize-observer-polyfill';
import * as moment from 'moment';

import { MULTI_DROPDOWN_SETTINGS, SINGLE_DROPDOWN_SETTINGS } from 'src/app/shared/constants/constants';
import { DropdownOptionsSettingsService } from '../../services/dropdown-options-settings';
import { AppLocalStoreService } from '../../services/app-store.service';
import { MessagesService } from '../messages/messages.service';
import { One2FiveHelperService } from '../../services/one2five-helper.service';
import { One2FiveUtils, appendIdToDataStore, getCompanyName, getCurrentUserRole } from 'src/app/core/services/one2five-utils';
import { StateManagementService } from '../../services/state.service';
import { One2FiveLoadingService } from '../app-loader/loader.service';
import { DataService } from '../../services/data.service';
import { ApplyThemeService } from '../../services/apply-theme.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { MessageItemType } from '../messages/message.model';
import { IIdItemNamePair, IIdItemNameSelectedPair, IIdTextPair, One2FiveConstant } from '../../models/referance.model';
import { FilterAccessModel, ISaveFilterModel, ReviewFiltersModel } from '../../models/review-filter-model';
import { ReviewrInfo } from '../../models/interface';
import { IBrandToLocal } from '../../models/request.interface';

@Component({
  selector: 'app-review-filter',
  templateUrl: './review-filter.component.html',
  styleUrls: ['./review-filter.component.scss']
})
export class ReviewFilterComponent implements OnInit, AfterViewInit {
  @ViewChild('reviewFilterCollapsible', { static: true }) reviewFilterCollapsible!: ElementRef<HTMLDivElement>;
  @ViewChild(DaterangepickerComponent, { static: false }) private picker!: DaterangepickerComponent;

  filterAccess = new FilterAccessModel();
  reviewSource: string[] = [];
  defaultProductStatus: string = "";
  module: string = One2FiveConstant.REVIEW;
  productType: string = One2FiveConstant.Product;
  filterNameText: string = "Review Filters";
  moduleName: string = "";

  locale: string = '';
  saveState = 'Save View';
  isModeratorUserRole: boolean = false;

  selectedCategory: any[] = [];
  selectedProduct: any[] = [];
  selectedFromDate = '';
  selectedToDate = '';
  isReviewFilterOpen = false;

  categoryList: any[] = [];
  productList: any[] = [];

  reviewStatuses: any[] = AppLocalStoreService.getApprovedRejectedStatus();
  productStatus: any[] = AppLocalStoreService.getActiveInactivePair();
  starProduct: any[] = AppLocalStoreService.getTrueFalseStatus();

  sourceList: any[] = [];
  selectedSource: any[] = [];

  categoryDropdownSettings: any = {};
  productDropdownSettings: any = {};
  moderationStatusDropdownSettings: any = {};
  productStatusDropdownSettings: any = {};
  starProductDropdownSettings: any = {};
  sourceDropdownSettings: any = {};
  moderationCodesDropdownSettings: any = {};
  ratingsDropdownSettings: any = {};
  reviewAnswersDropdownSettings: any = {};
  badgeDropdownSettings: any = {};
  savedStatesDropdownSettings: any = {};
  selectedSavedStates: any = [];
  arrMarketList: IBrandToLocal[] = [];
  marketListSelectedItems: any[] = [];
  marketDropdownSettings: any = {};

  brandDropdownSettings: any = {};
  brandNameList: any[] = [];
  brandSelectedItems: any[] = [];

  reviewCountDropdownSettings: any = {};
  reviewOperators: IIdItemNamePair[] = AppLocalStoreService.getReviewOperators();
  reviewOperatorSelectedItem: IIdItemNamePair[] = [AppLocalStoreService.getReviewOperators()[0]];

  selectedMarkets: any[] = [];
  campaigns = [];
  campaignsSelectedItem = [];
  selectedCampaigns = '';
  campaignSettings: any = {};
  reviewerName: string = '';
  showReviewerField: boolean = false;

  datePickerPosition: string = "right";
  datePickerOptions: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    autoUpdateInput: true,
    parentEl: "#dateRangePickerParentRef",
    drops: 'down'
  };
  dateRange: any = {};

  showSaveFilterName = false;
  saveFilterByName = '';
  selectedState: Array<any> = [];
  savedStates: Array<any> = [];

  tempSelectedStarProduct: any[] = [];
  selectedStarProduct: any[] = [];
  selectedReviewStatus: any[] = [];
  tempSelectedModerationCodes: any[] = [];

  moderationCodes: Array<IIdTextPair> = [];
  selectedStateDetails: any = [];

  filters = new ReviewFiltersModel;
  productGroupTypeList = AppLocalStoreService.getOffSimpleComplexStatus();
  starRatings = AppLocalStoreService.getOne2FiveList();

  badgeTemplates = [];

  productGroupTypeSetting: any = {};
  selectedProductGroupApproachType = [];
  tempSelectedProductGroupStatus: any[] = [];
  selectedDateRange = '';

  selectedRatings: any = [];
  selectedBadgeTemplate: any = null;

  reviewAnswers: IIdItemNameSelectedPair[] = [];
  reviewAnswersChoice: any = null;

  isMarketDataLoaded: boolean = false;
  isProductDataLoaded: boolean = false;
  isCategoryDataLoaded: boolean = false;
  isCampaignNameDataLoaded: boolean = false;
  isModerationCodesDataLoaded: boolean = false;
  isBadgeTemplatesDataLoaded: boolean = false;
  isReviewSourcesDataLoaded: boolean = false;

  constructor(private router: Router,
    private translate: TranslateService,
    private stateService: StateManagementService,
    private dataService: DataService,
    private daterangepickerOptions: DaterangepickerConfig,
    public applyThemeService: ApplyThemeService,
    public commonUtilService: CommonUtilService,
    private one2FiveHelper: One2FiveHelperService) {
    this.reviewAnswers = AppLocalStoreService.getReviewAnswers();
  }

  @HostListener('document:keyup.escape', ['$event']) onkeyupHandler(_event: KeyboardEvent) {
    if (this.isReviewFilterOpen)
      this.openCloseReviewFilter();
  }

  ngOnInit() {
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.locale = element;
      this.translate.use(element);
      this.translateStaticLabels(element);
    });
  }

  ngAfterViewInit(): void {
    if(this.picker) {
      this.picker.datePicker.setStartDate(this.selectedFromDate);
      this.picker.datePicker.setEndDate(this.selectedToDate);
    }
  }

  private setReviewSource(_reviewSource: string[]) {
    this.filters.reviewSource = _reviewSource;
    let tempReviewSource: any = [];
    this.filters.reviewSource.forEach((item) => {
      tempReviewSource.push({ itemName: item });
    });
    this.getSources(tempReviewSource);
  }

  private emitFilters(_funName: string): void {
    console.log(_funName);

    this.one2FiveHelper.applyFilter(this.filters);
  }

  initializeFilterComponent(_filterAccess: FilterAccessModel, _module: string, _reviewSource: string[],
    _datePickerPosition: string = "right",
    _productType: string = One2FiveConstant.Product, _productStatus: string = "",
    _moduleName: string = "", _filterNameText: string = "Review Filters") {

    this.filterAccess = _filterAccess;
    this.module = _module;
    if (_reviewSource.length) {
      this.setReviewSource(_reviewSource);
    }
    this.datePickerPosition = _datePickerPosition;
    this.datePickerOptions.opens = _datePickerPosition;  // 'left' or 'right' depending on datePicker field position

    this.productType = _productType;
    this.defaultProductStatus = _productStatus;
    this.filterNameText = _filterNameText;
    if (this.moduleName) {
      this.moduleName = _moduleName;
      this.resetAllFilter(false);
      this.selectedDateRange = '';
      if (this.filterAccess.Date) {
        this.defaultDateRangeForDashboard();
      }
    }
    this.loadReferanceData();
  }

  private loadReferanceData() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.filters.reviewerId) {
        this.filters.reviewerId = '';
        this.commonUtilService.latestReviewerInfo('');
      }
    });
    this.showReviewerField = this.router.url.includes('review-management') ? true : false;
    this.isModeratorUserRole = getCurrentUserRole().toLowerCase() === One2FiveConstant.moderator;
    this.filters.company = getCompanyName();
    this.getBadgeTemplates();

    this.getSavedStates();
    this.getModerationCodesForFilter();
    this.detectReviewFilterHeightChanges();
    this.setDropDownSetting();
    this.getReviewerInfo();
    this.getBrandNames();
  }

  private async translateStaticLabels(_lang: any) {
    const translatedSearchLabel = await this.commonUtilService.transalateInputText("Search");
    const selectAllText = await this.commonUtilService.transalateInputText("DROPDOWNS.Select All");
    const unSelectAllText = await this.commonUtilService.transalateInputText("DROPDOWNS.UnSelect All");
    const brandSitesPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Brand Sites");
    const brandNamePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Brands");

    this.brandDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.brandDropdownSettings, brandNamePlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);
    this.marketDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.marketDropdownSettings, brandSitesPlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);
    const categoriesPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Categories");
    this.categoryDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.categoryDropdownSettings, categoriesPlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);
    const productsPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Products");
    this.productDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.productDropdownSettings, productsPlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);
    const sourcePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Review Source");
    this.sourceDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.sourceDropdownSettings, sourcePlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);
    const ratingsPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Ratings");
    this.ratingsDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.ratingsDropdownSettings, ratingsPlaceholderText, translatedSearchLabel, selectAllText, unSelectAllText);

    const moderationStatusPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Moderation Status");
    this.moderationStatusDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.moderationStatusDropdownSettings, moderationStatusPlaceholderText);
    const badgePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Badge");
    this.badgeDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.badgeDropdownSettings, badgePlaceholderText);
    const starPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.All Select Star");
    this.starProductDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.starProductDropdownSettings, starPlaceholderText);
    const productStatusPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Product Status");
    this.productStatusDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.productStatusDropdownSettings, productStatusPlaceholderText);
    const campaignsPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Campaigns");
    this.campaignSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.campaignSettings, campaignsPlaceholderText);
    const moderationCodePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Moderation Code");
    this.moderationCodesDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.moderationCodesDropdownSettings, moderationCodePlaceholderText);
    const selectTypePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Type");
    this.productGroupTypeSetting = DropdownOptionsSettingsService.translateDDOptionLabels(this.productGroupTypeSetting, selectTypePlaceholderText);
    const chooseAViewPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Choose a View...");
    this.savedStatesDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.savedStatesDropdownSettings, chooseAViewPlaceholderText);
    const reviewCountPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Type");
    this.reviewCountDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.reviewCountDropdownSettings, reviewCountPlaceholderText);
  }

  detectReviewFilterHeightChanges() {
    var resizeObserver = new ResizeObserver((entries: any) => {
      const entry = entries[0];
      const cr = entry.contentRect;
      this.one2FiveHelper.filterToggle(cr.height);
    });
    resizeObserver.observe(this.reviewFilterCollapsible.nativeElement);
  }

  getModerationCodesForFilter() {
    this.stateService.getModerationCodesForFilter(this.productType).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data && data.HasErrors) return;

      this.isModerationCodesDataLoaded = true;
      this.moderationCodes = [];
      data.forEach((val: any) => this.moderationCodes.push(Object.assign({}, val)));
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getBrandNames() {
    this.brandNameList = [];
    this.brandSelectedItems = [];

    this.dataService.getBrandNames().subscribe((data: any) => {
      if (data && data.HasErrors) return;

      let brandNameList: any = [];
      data.forEach((element: any) => {
        brandNameList.push({
          'brandCode': Number(element.brandCode),
          'brandName': !element.brandName ? '' : element.brandName,
          'brandshort': !element.brandshort ? '' : element.brandshort,
          'pieFormTemplate': !element.pieFormTemplate ? '' : element.pieFormTemplate,
        });
      });      
      this.brandNameList = One2FiveUtils.objectClone(brandNameList);
    }, (error: any) => {
      MessagesService.showFailureAlert("ERRORS." + error);
      One2FiveLoadingService.hide();
    });
  }

  getBrandSite(brandSites: any, isFillFilter: boolean = false) {
    this.arrMarketList = [];
    this.marketListSelectedItems = [];
    const brandNames = this.brandSelectedItems.map(_brand => _brand.brandName);

    this.stateService.getCountrySelect(null, brandNames).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data["HasErrors"]) return;

      this.isMarketDataLoaded = true;
      if(this.module == One2FiveConstant.QUESTION) {
        this.arrMarketList = data.filter((_brand: any) => _brand.regionName && _brand.questionAnswerEnable);
      } else {
        this.arrMarketList = data.filter((el: any) => el.regionName);
      }
      if (isFillFilter) {
        this.marketListSelectedItems = this.arrMarketList.filter((el: any) => el.regionName.toLowerCase() == brandSites.toLowerCase());
      } else {
        this.marketListSelectedItems = brandSites;
      }
      this.trimList();
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getProducts(brands: any, preSelectedProducts: any, categories: any, isFillFilter: boolean = false) {
    this.trimCategory();
    this.productList = [];
    this.selectedProduct = [];

    let brandsSelected: any = [];
    let categoriesSelected: any = [];
    if (isFillFilter) {
      brandsSelected = this.brandSelectedItems;
    } else {
      brands.forEach((item: any) => this.brandAssign(item, brandsSelected));
      categories.forEach((item: any) => categoriesSelected.push(item.itemName));
    }

    this.stateService.getProductData(categoriesSelected, brandsSelected, true, this.productType).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data["HasErrors"]) return;

      this.isProductDataLoaded = true;
      this.productList = data.filter((el: any) => el.itemName);
      if (isFillFilter) {
        this.selectedProduct = this.productList.filter((el: any) => el.itemName.toLowerCase() == preSelectedProducts.toLowerCase());
      } else {
        this.selectedProduct = preSelectedProducts;
      }
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getCategories(brands: any, preSelectedCategories: any) {
    this.categoryList = [];
    this.selectedCategory = [];
    let brandsSelected: any = [];

    brands.forEach((item: any) => {
      this.brandAssign(item, brandsSelected);
    });
    this.stateService.getCategoryData(brandsSelected, true, this.productType).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data.HasErrors) return;

      this.isCategoryDataLoaded = true;
      this.categoryList = data.filter((el: any) => el.itemName);
      this.selectedCategory = preSelectedCategories;
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  brandAssign(item: any, brandsSelected: any) {
    if (item.regionName) {
      brandsSelected.push(item.regionName);
    } else {
      brandsSelected.push(item);
    }
  }

  public updateDateRange(preStartDate: any, preEndDate: any) {
    if (this.picker) {
      this.picker.datePicker.autoUpdateInput = true;
    }
    this.selectedDateRange = preStartDate + ' - ' + preEndDate;
  }

  fillProductStatus(preProductStatus: any) {
    this.selectedStarProduct = [];
    if (preProductStatus.length === 0 || preProductStatus == null) {
      this.filters.productStatus = '';
    } else {
      this.selectedStarProduct = this.productStatus.filter(
        (item) => item.itemName === preProductStatus
      );
      this.trimProductStatus();
    }
  }

  trimProductStatus() {
    this.filters.productStatus = this.selectedStarProduct[0].itemName;
  }

  fillModerationStatus(preModerationStatus: any) {
    if (preModerationStatus.length === 0 || preModerationStatus == null) {
      this.filters.reviewStatus = '';
    } else {
      this.selectedReviewStatus = this.reviewStatuses.filter(
        (item) => item.itemName === preModerationStatus
      );
      this.trimModerationStatus();
    }
  }

  trimModerationStatus() {
    this.filters.reviewStatus = this.selectedReviewStatus[0].itemName;
  }

  fillStarProduct(isStarProduct: any) {
    if (isStarProduct == null || isStarProduct.length === 0) {
      this.filters.starProduct = '';
    } else {
      this.tempSelectedStarProduct = this.starProduct.filter(
        (item) => item.itemName.toLowerCase() === isStarProduct
      );
      this.trimStarProduct();
    }
  }

  trimStarProduct() {
    this.filters.starProduct = this.tempSelectedStarProduct[0].itemName;
  }

  removeDateRange() {
    if (this.picker) {
      this.picker.datePicker.element[0].value = '';
      this.picker.datePicker.autoUpdateInput = false;
    }
  }

  /**Change for API ENCODING /data/getCampaignName */
  getCampaignsData(brands: any, precampaigns: any) {
    this.campaigns = [];
    this.campaignsSelectedItem = [];

    const brandsSelected: string[] = [];

    brands.forEach((item: any) => {
      if (item.regionName) {
        brandsSelected.push(item.regionName.toString());
      } else {
        brandsSelected.push(item.toString());
      }
    });
    this.stateService.getCampaignNameData(brandsSelected, true).subscribe((data: any) => {
      this.isCampaignNameDataLoaded = true;
      if (data) {
        this.campaigns = data.filter((el: any) => el.itemName);
        this.campaignsSelectedItem = precampaigns;
        this.trimCamapigns();
      } else {
        this.campaigns = [];
      }
      this.hideFilterloaderStyle();
    },
      (error: any) => {
        MessagesService.showFailureAlert(error);
        this.hideFilterloaderStyle();
      }
    );
  }

  getBadgeTemplates() {
    this.stateService.getBadgeTemplates(this.module, true).subscribe((data: any) => {
      this.isBadgeTemplatesDataLoaded = true;
      if (data && data.HasErrors === false) {
        this.badgeTemplates = data.badgeList;
      } else {
        this.badgeTemplates = [];
      }
      this.hideFilterloaderStyle();
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getSavedStates() {
    this.applyFilterloaderStyle();
    this.saveState = 'Save View';

    this.tempSelectedStarProduct = [];
    this.selectedStarProduct = [];
    this.selectedReviewStatus = [];
    this.tempSelectedModerationCodes = [];
    this.selectedCategory = [];
    this.selectedProduct = [];
    this.filters.marketName = [];
    this.brandSelectedItems = [];
    this.marketListSelectedItems = [];
    this.filters.categoryName = [];
    this.filters.productExtId = [];
    this.filters.fromDate = '';
    this.filters.toDate = '';
    this.filters.reviewStatus = '';
    this.filters.productStatus = '';
    this.filters.starProduct = '';
    this.filters.reviewSource = [];
    this.selectedSource = [];
    this.filters.moderationCode = '';
    this.filters.campaignNames = [];
    this.tempSelectedProductGroupStatus = [];
    this.filters.productGrouping = '';
    this.filters.badge = null;
    this.filters.hasAnswers = false;
    this.filters.ratings = [];
    this.selectedBadgeTemplate = null;
    this.reviewAnswersChoice = null;
    this.selectedRatings = [];
    this.fillStarProduct(this.filters.starProduct);
    this.getBrandSite(this.filters.marketName);
    this.getProducts(this.filters.marketName, this.filters.productExtId, this.filters.categoryName);
    this.getCategories(this.filters.marketName, this.filters.categoryName);
    this.fillModerationCode(this.filters.moderationCode);
    this.getSources(this.filters.reviewSource);
    this.getCampaignsData(this.filters.marketName, this.filters.campaignNames);
    this.removeDateRange();
    this.fillModerationStatus(this.filters.reviewStatus);
    this.fillProductGroupingStatus(this.filters.productGrouping);

    this.selectedStarProduct = [];
    this.filters.productStatus = '';

    if (this.filterAccess.Date === true) {
      this.defaultDateRangeForDashboard();
    } else {
      this.selectedDateRange = '';
      this.selectedFromDate = '';
      this.selectedToDate = '';
      this.filters.toDate = '';
      this.filters.fromDate = '';
    }

    this.emitFilters("getSavedStates");

    this.stateService.fetchSavedTemplates().subscribe((data: any) => {
      this.hideFilterloaderStyle();
      this.savedStates = data;
      this.selectedState = [];
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  callAPIForGetCampaignNameData() {
    this.stateService.getCampaignNameData(this.filters.marketName, true).subscribe((data: any) => {
      this.isCampaignNameDataLoaded = true;
      if (!data || data.length == 0) {
        this.campaigns = [];
        return;
      }
      this.campaigns = appendIdToDataStore(data);
      this.hideFilterloaderStyle();
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getSaveFilterRequest(action: 'preview' | 'update' | 'delete' | 'submit') {
    const dataString: ISaveFilterModel = {
      templateName: action === 'submit' ? this.saveFilterByName : this.selectedState[0].itemName,
      templateId: action === 'submit' ? '' : this.selectedState[0].id + '',
      brandSite: this.filters.marketName,
      category: this.filters.categoryName,
      product: this.filters.productExtId,
      startDate: this.filters.fromDate,
      endDate: this.filters.toDate,
      reviewSource: this.filters.reviewSource,
      moderationStatus: this.filters.reviewStatus,
      productStatus: this.filters.productStatus,
      starProduct: this.filters.starProduct.toLowerCase(),
      campaign: this.filters.campaignNames,
      moderationCode: this.filters.moderationCode,
      action,
      searchText: '',
      productGrouping: this.filters.productGrouping,
      ratings: this.filters.ratings,
      badge: this.filters.badge,
      hasAnswers: this.filters.hasAnswers,
    };
    return dataString;
  }

  onChangeBookmark(selectedSavedState: any) {
    this.selectedSavedStates = [];
    this.clearReviewerData();
    this.selectedState = this.savedStates.filter((state) => state.id === selectedSavedState.id);
    this.selectedSavedStates = this.selectedState;
    One2FiveLoadingService.show();

    if (this.selectedState.length) {
      this.saveState = 'Update View';
      const dataString: ISaveFilterModel = this.getSaveFilterRequest('preview');

      this.dataService.fetchSavedfilters(dataString).subscribe((data: any) => {
        this.selectedStateDetails = data;

        this.filters.marketName = this.selectedStateDetails[0].brandSite.map((market: any) => market.regionName);
        this.filters.categoryName = this.selectedStateDetails[0].category.map((category: any) => category.itemName);
        this.filters.productExtId = this.selectedStateDetails[0].product.map((product: any) => product.itemName);
        this.filters.reviewSource = this.selectedStateDetails[0].reviewSource.map((reviewSource: any) => reviewSource.itemName);
        this.filters.campaignNames = this.selectedStateDetails[0].campaign.map((campaign: any) => campaign.itemName);
        this.filters.badge = this.selectedStateDetails[0].badge.id;
        this.selectedBadgeTemplate = this.badgeTemplates && this.badgeTemplates.filter((badge: any) => badge.badgeTemplateId === this.filters.badge);

        this.filters.hasAnswers = this.selectedStateDetails[0].hasAnswers;

        if (this.selectedStateDetails[0].hasAnswers === null) {
          this.reviewAnswersChoice = null;
        } else {
          this.reviewAnswersChoice = this.reviewAnswers.filter((reviewAnswer) =>
            this.filters.hasAnswers ? reviewAnswer.id === 1 : reviewAnswer.id === 2
          );
        }

        this.filters.ratings = this.selectedStateDetails[0].ratings;
        this.selectedRatings = this.starRatings.filter((rating) => this.filters.ratings.includes(rating.id));
        this.filters.toDate = this.selectedStateDetails[0].endDate;
        this.filters.fromDate = this.selectedStateDetails[0].startDate;

        this.getBrandSite(this.selectedStateDetails[0].brandSite);
        this.getProducts(this.selectedStateDetails[0].brandSite, this.selectedStateDetails[0].product, this.selectedStateDetails[0].category);
        this.getCategories(this.selectedStateDetails[0].brandSite, this.selectedStateDetails[0].category);
        this.removeDateRange();
        if (!(this.selectedStateDetails[0].startDate === '' || this.selectedStateDetails[0].endDate === '')) {
          this.updateDateRange(this.selectedStateDetails[0].startDate, this.selectedStateDetails[0].endDate);
        }
        this.fillStarProduct(this.selectedStateDetails[0].starProduct);
        this.fillModerationCode(this.selectedStateDetails[0].moderationCode);
        this.fillModerationStatus(this.selectedStateDetails[0].moderationStatus);
        this.fillProductStatus(this.selectedStateDetails[0].productStatus);
        this.getSources(this.selectedStateDetails[0].reviewSource);
        this.getCampaignsData(this.selectedStateDetails[0].brandSite, this.selectedStateDetails[0].campaign);
        this.one2FiveHelper.pageIndex(1);
        this.emitFilters("onChangeBookmark");
      });
    } else {
      this.saveState = 'Save View';

      this.tempSelectedStarProduct = [];
      this.selectedStarProduct = [];
      this.selectedReviewStatus = [];
      this.tempSelectedModerationCodes = [];
      this.selectedCategory = [];
      this.selectedProduct = [];
      this.filters.marketName = [];
      this.filters.categoryName = [];
      this.filters.productExtId = [];
      this.filters.reviewStatus = '';
      this.filters.productStatus = '';
      this.filters.starProduct = '';
      this.filters.reviewSource = [];
      this.selectedSource = [];
      this.filters.moderationCode = '';
      this.filters.toDate = '';
      this.filters.fromDate = '';
      this.filters.campaignNames = [];
      this.filters.productGrouping = '';
      this.tempSelectedProductGroupStatus = [];
      this.fillModerationCode(this.filters.moderationCode);
      this.fillStarProduct(this.filters.starProduct);
      this.getBrandSite(this.filters.marketName);
      this.getProducts(this.filters.marketName, this.filters.productExtId, this.filters.categoryName);
      this.getCategories(this.filters.marketName, this.filters.categoryName);
      this.getSources(this.filters.reviewSource);
      this.removeDateRange();

      if (this.filterAccess.Date === true) {
        this.defaultDateRangeForDashboard();
      } else {
        this.selectedDateRange = '';
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.filters.toDate = '';
        this.filters.fromDate = '';
      }
      this.selectedStarProduct = [];
      this.filters.productStatus = '';

      this.fillModerationStatus(this.filters.reviewStatus);
      this.getCampaignsData(this.filters.marketName, this.filters.campaignNames);
      this.fillProductGroupingStatus(this.filters.productGrouping);
      this.emitFilters("onChangeBookmark");
    }
  }

  /**Changes for JSON Encoding /data/savefiler API*/
  onSaveFilter() {
    if (this.selectedState.length === 0) {
      this.one2FiveHelper.showSaveFilterForm(true);
      this.showSaveFilterName = !this.showSaveFilterName;
    } else {
      this.applyFilterloaderStyle();

      this.trimProduct();
      this.trimCategory();
      this.trimList();
      this.trimSources();
      this.trimCamapigns();
      this.trimReviewOperators();

      const dataString: ISaveFilterModel = this.getSaveFilterRequest('update');
      this.dataService.onSavefilter(dataString).subscribe((data: any) => {
        this.hideFilterloaderStyle();
        if (!data) {
          MessagesService.errorMessage("Filter View not saved");
          return;
        }
        this.saveFilterByName = '';
        if (data[`HasErrors`]) {
          MessagesService.showFailureAlert(data[`Errors`][0].Message);
          return;
        }
        this.getSavedStates();
        MessagesService.successAlert("Filter view Saved successfully");
        this.selectedSavedStates = [];
      }, (error: any) => {
        MessagesService.showFailureAlert(error);
        this.hideFilterloaderStyle();;
      });
    }
  }

  /**Changes for JSON Encoding /data/savefiler API */
  onDeleteFilter() {
    if (this.filters.reviewerId) {
      this.clearReviewerData();
    }
    if (this.selectedState.length == 0 && this.selectedSavedStates.length == 0) {
      MessagesService.alertMessages("ERRORS.Please select filter state first.", "", "Validation Alert", MessageItemType.Warning);
      return;
    }
    const dataString: ISaveFilterModel = this.getSaveFilterRequest('delete');
    this.applyFilterloaderStyle();

    this.dataService.onSavefilter(dataString).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (!data) {
        MessagesService.errorMessage("Filter View not deleted");
        return;
      }

      this.getSavedStates();
      if (data[`HasErrors`]) {
        MessagesService.showFailureAlert(data[`Errors`][0].Message);
        return;
      }
      MessagesService.successAlert("Filter view Deleted successfully");
      this.selectedSavedStates = [];
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  updateSaveFilterTemplateName(templateName: string) {
    this.clearReviewerData();
    this.saveFilterByName = templateName;
  }

  /** Changes for JSON Encoding /data/savefiler API */
  onSaveFilterState() {
    if (this.saveFilterByName.trim().length == 0) {
      this.saveFilterByName = "";
      return;
    }

    this.trimProduct();
    this.trimCategory();
    this.trimList();
    this.trimSources();
    this.trimCamapigns();
    this.trimReviewOperators();
    const dataString: ISaveFilterModel = this.getSaveFilterRequest('submit');

    this.applyFilterloaderStyle();
    this.dataService.onSavefilter(dataString).subscribe((data: any) => {
      if (data.HasErrors) {
        this.hideFilterloaderStyle();
        return;
      }

      this.saveFilterByName = '';
      this.closeSaveFilterWindow();
      if (!data) {
        MessagesService.errorMessage("Filter View not saved"); return;
      }
      if (data[`HasErrors`]) {
        MessagesService.showFailureAlert(data[`Errors`][0].Message);
        return;
      }
      MessagesService.successAlert("Filter view Saved successfully");
      this.getSavedStates();
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  closeSaveFilterWindow() {
    this.showSaveFilterName = false;
  }

  setDropDownSetting(): void {
    if (getCurrentUserRole() === One2FiveConstant.Moderator) {
      this.marketDropdownSettings = DropdownOptionsSettingsService.getMarketSingleSelectionSettings();
    } else {
      this.marketDropdownSettings = DropdownOptionsSettingsService.getMarketMultiSelectSettings();
    }

    this.brandDropdownSettings = DropdownOptionsSettingsService.getBrandsSettings();
    this.categoryDropdownSettings = DropdownOptionsSettingsService.getCategoriesDropdownSettings();
    this.productDropdownSettings = DropdownOptionsSettingsService.getProductDropdownSettings();
    this.starProductDropdownSettings = DropdownOptionsSettingsService.getSingleDropdownSettings("All Select Star");
    this.productStatusDropdownSettings = DropdownOptionsSettingsService.getSingleDropdownSettings("Select Product Status");

    this.ratingsDropdownSettings = {
      singleSelection: false,
      primaryKey: 'itemName',
      text: this.commonUtilService.translateWords(["All", "Ratings"]),
      enableCheckAll: true,
      selectAllText: this.commonUtilService.translateWords(["Select", "All"]),
      unSelectAllText: this.commonUtilService.translateWords(["UnSelect", "All"]),
      enableSearchFilter: true,
      badgeShowLimit: 1,
      classes: 'ddCustomClass custom-class-example',
      lazyLoading: true,
      escapeToClose: true
    };
    this.reviewAnswersDropdownSettings = {
      text: this.commonUtilService.translateMessage("Show Reviews with/without Answers"),
      ...SINGLE_DROPDOWN_SETTINGS
    };
    this.badgeDropdownSettings = {
      text: this.commonUtilService.translateWords(["Select", "Badge"]),
      ...SINGLE_DROPDOWN_SETTINGS,
      labelKey: 'badgeName',
      primaryKey: 'badgeTemplateId'
    };
    this.moderationStatusDropdownSettings = {
      text: this.commonUtilService.translateWords(["Select", "Moderation", "Status"]),
      ...SINGLE_DROPDOWN_SETTINGS
    };
    this.sourceDropdownSettings = {
      singleSelection: false,
      text: this.commonUtilService.translateWords(["All", "Review", "Source"]),
      enableCheckAll: true,
      selectAllText: this.commonUtilService.translateWords(["Select", "All"]),
      unSelectAllText: this.commonUtilService.translateWords(["UnSelect", "All"]),
      enableSearchFilter: true,
      badgeShowLimit: 1,
      classes: 'ddCustomClass custom-class-example',
      escapeToClose: true
    };

    this.campaignSettings = {
      singleSelection: false,
      primaryKey: 'itemName',
      text: this.commonUtilService.translateWords(["Select", "Campaigns"]),
      ...MULTI_DROPDOWN_SETTINGS,
      enableSearchFilter: true,
      classes: 'ddCustomClass custom-class',
      lazyLoading: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      escapeToClose: true
    };
    this.moderationCodesDropdownSettings = {
      labelKey: 'text',
      text: this.commonUtilService.translateWords(["Select", "Moderation", "code"]),
      ...SINGLE_DROPDOWN_SETTINGS
    };

    this.setDaterangePickerOptions();

    this.productGroupTypeSetting = {
      singleSelection: true,
      text: this.commonUtilService.translateWords(["Select", "Type"]),
      labelKey: 'itemName',
      primaryKey: 'itemName',
      enableSearchFilter: false,
      badgeShowLimit: 1,
      maxHeight: 125,
      lazyLoading: true,
      escapeToClose: true,
      enableFilterSelectAll: false
    };

    this.savedStatesDropdownSettings = {
      text: this.commonUtilService.translateMessage('Choose a View') + '...',
      singleSelection: true,
      enableSearchFilter: true,
      badgeShowLimit: 1,
      enableFilterSelectAll: false
    };

    this.reviewCountDropdownSettings = {
      singleSelection: true,
      text: this.commonUtilService.translateWords(["Select", "Type"]),
      labelKey: 'itemName',
      primaryKey: 'itemName',
      enableSearchFilter: false,
      badgeShowLimit: 1,
      maxHeight: 210,
      lazyLoading: true,
      escapeToClose: true,
      enableFilterSelectAll: false
    };
  }

  openCloseReviewFilter() {
    this.isReviewFilterOpen = !this.isReviewFilterOpen;
    if (this.isReviewFilterOpen && (this.isMarketDataLoaded == false ||
      this.isProductDataLoaded == false || this.isCategoryDataLoaded == false ||
      this.isCampaignNameDataLoaded == false || this.isModerationCodesDataLoaded == false ||
      this.isBadgeTemplatesDataLoaded == false || this.isReviewSourcesDataLoaded == false)) {
      this.applyFilterloaderStyle();
    } else {
      this.hideFilterloaderStyle();
    }
  }

  callAPIForGetMarkets() {
    this.stateService.getCountrySelect().subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data.HasErrors) return;

      this.isMarketDataLoaded = true;
      let countrySelector: any[] = data.filter((el: any) => el.itemName);
      let arrMarketList: any = [];
      countrySelector.forEach((country) => {
        if (country.itemName) arrMarketList.push(country);
      });
      this.arrMarketList = appendIdToDataStore(arrMarketList);
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  callAPIForCategory() {
    this.stateService.getCategoryData(this.filters.marketName, true, this.productType).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data.HasErrors) return;

      this.isCategoryDataLoaded = true;
      this.categoryList = data.filter((el: any) => el.itemName);

      if (this.categoryList.length > 0) {
        this.categoryList = appendIdToDataStore(this.categoryList);
      }

    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  callAPIForProduct() {
    this.stateService.getProductData(this.filters.categoryName, this.filters.marketName, true, this.productType).subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data.HasErrors) return;

      this.isProductDataLoaded = true;
      if (data) {
        this.productList = data.filter((el: any) => el.itemName);

        if (this.productList.length > 0) {
          this.productList = appendIdToDataStore(this.productList);
        }
      }
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  getSources(preSelectedSources: any) {
    this.sourceList = [];
    this.selectedSource = [];
    this.stateService.getReviewSources().subscribe((data: any) => {
      this.hideFilterloaderStyle();
      if (data.HasErrors) return;

      this.isReviewSourcesDataLoaded = true;
      this.sourceList = data.filter((el: any) => el.itemName);
      if (this.sourceList.length == 0) return;

      this.sourceList.forEach((item) => {
        preSelectedSources.forEach((preItem: any) => {
          if (preItem.itemName === item.itemName) {
            const index = this.selectedSource.findIndex(sourceItem => sourceItem.itemName === item.itemName);
            if (index === -1) {
              this.selectedSource.push(item);
            } else {
              this.selectedSource[index] = item;
            }
          }
        });
      });
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      this.hideFilterloaderStyle();
    });
  }

  onSourceSelect(_event: any) {
    this.sourceSelectAction(_event);
  }
  onSourceDeSelect(_event: any) {
    this.sourceSelectAction(_event);
  }
  onSourceSelectAll(_event: any) {
    this.sourceSelectAction(_event);
  }
  onSourceDeSelectAll(_event: any) {
    this.sourceSelectAction(_event);
  }
  sourceSelectAction(_event: any) {
    this.trimSources();
    this.emitFilters("sourceSelectAction");
  }
  trimSources() {
    this.filters.reviewSource = [];
    this.one2FiveHelper.pageIndex(1);
    this.selectedSource.forEach((item) => this.filters.reviewSource.push(item.itemName.toString()));
  }

  onMarketExtraction(countryValue: any) {
    this.campaigns = [];
    this.campaignsSelectedItem = [];
    this.filters.categoryName = [];
    this.selectedCategory = [];
    this.productList = [];
    this.selectedProduct = [];
    this.marketValueExtraction(countryValue);
  }

  marketValueExtraction(_countryValue: any) {
    this.trimList();
    this.callAPIForGetCampaignNameData();
    this.callAPIForCategory();
    this.callAPIForProduct();
    if (this.filters.marketName.length >= 1) {
      this.one2FiveHelper.pageIndex(1);
    } else {
      this.one2FiveHelper.pageIndex(0);
    }
    this.emitFilters("marketValueExtraction");
  }

  trimList() {
    this.filters.marketName = [];
    this.marketListSelectedItems && this.marketListSelectedItems.forEach((market) => {
      this.filters.marketName.push(market.regionName.toString());
    });
  }

  searchedText() {
    if (this.filters.openSearch) {
      this.reviewerName = "";
      this.filters.reviewerId = "";
      this.searchFilter(this.filters.openSearch);
    }
  }

  private searchFilter(_searchText: string = "") {
    this.filters.openSearch = _searchText.trim();
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("searchFilter");
  }

  clearSearch() {
    this.filters.openSearch = '';
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("clearSearch");
  }

  fillModerationCode(preModerationCode: any) {
    if (preModerationCode.length === 0 || preModerationCode == null) {
      this.filters.moderationCode = '';
    } else {
      this.tempSelectedModerationCodes = this.moderationCodes.filter((item) => item.text === preModerationCode);
      this.trimModerationCode();
    }
  }
  trimModerationCode() {
    this.filters.moderationCode = this.tempSelectedModerationCodes[0].text;
  }

  onModerationCodeSelected(_event: any) {
    this.filters.moderationCode = _event.text;
    this.applyFilterloaderStyle();
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onModerationCodeSelected");
  }
  moderationCodeRemoved(_event: any) {
    this.filters.moderationCode = '';
    this.applyFilterloaderStyle();
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("moderationCodeRemoved");
  }

  onCategorySelect(category: any) {
    this.categoryValueExtraction(category);
  }
  onCategoryDeSelect(category: any) {
    this.categoryValueExtraction(category);
  }
  onCategorySelectAll(category: any) {
    this.categoryValueExtraction(category);
  }
  onCategoryDeSelectAll(category: any) {
    this.categoryValueExtraction(category);
  }
  categoryValueExtraction(_category: any) {
    this.productList = [];
    this.selectedProduct = [];
    this.trimCategory();
    this.callAPIForProduct();
    if (this.filters.categoryName.length >= 1) {
      this.one2FiveHelper.pageIndex(1);
    } else {
      this.one2FiveHelper.pageIndex(0);
    }
    this.emitFilters("categoryValueExtraction");
  }
  trimCategory() {
    this.filters.categoryName = [];
    if (this.selectedCategory.length > 0) {
      this.selectedCategory.forEach((category) => {
        this.filters.categoryName.push(category.itemName.toString());
      });
    }
  }

  onProductSelect(_value: any) {
    this.productChangeEmitter();
  }
  onProductDeSelect(_value: any) {
    this.productChangeEmitter();
  }
  onProductSelectAll(_value: any) {
    this.productChangeEmitter();
  }
  onProductDeSelectAll(_value: any) {
    this.productChangeEmitter();
  }
  private productChangeEmitter() {
    this.trimProduct();
    this.emitFilters("productChangeEmitter");
  }

  onBadgeTemplateSelect(_event: any) {
    this.filters.badge = _event.badgeTemplateId;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onBadgeTemplateSelect");
  }
  onBadgeTemplateRemove() {
    this.filters.badge = null;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onBadgeTemplateRemove");
  }
  trimProduct() {
    this.filters.productExtId = [];
    this.one2FiveHelper.pageIndex(1);
    if (this.selectedProduct.length > 0) {
      this.selectedProduct.forEach((product) => {
        this.filters.productExtId.push(product.itemName.toString());
      });
    }
  }

  onCampaignSelected(_event: any) {
    this.onTrimCamapignsEmitFilters();
  }
  onCampaignItemDeSelect(_event: any) {
    this.onTrimCamapignsEmitFilters();
  }
  onCampaignSelectAll(_event: any) {
    this.onTrimCamapignsEmitFilters();
  }
  onCampaignDeSelectAll(_event: any) {
    this.onTrimCamapignsEmitFilters();
  }
  onTrimCamapignsEmitFilters() {
    this.trimCamapigns();
    this.emitFilters("onTrimCamapignsEmitFilters");
  }
  trimCamapigns() {
    this.filters.campaignNames = [];
    if (this.campaignsSelectedItem.length > 0) {
      this.campaignsSelectedItem.forEach((campaign: any) => {
        this.filters.campaignNames.push(campaign.itemName.toString());
      });
    }
  }

  onBrandNameSelect(_brandName: any) {
    this.trimBrandNames();
  }
  onBrandNameDeSelect(_event: any) {
    this.trimBrandNames();
  }
  trimBrandNames() {
    this.filters.brandNames = [];
    if (this.brandSelectedItems.length > 0) {
      this.brandSelectedItems.forEach((_brandName: any) => {
        this.filters.brandNames.push(_brandName.brandName.toString());
      });
    }
    this.getBrandSite(null);
    this.emitFilters("trimBrandNames");
  }

  onReviewOperatorSelected(_event: any) {
    this.filters.reviewOperator = this.reviewOperators.length ? this.reviewOperatorSelectedItem[0].id : "";
    if (this.filters.reviewOperator == One2FiveConstant.all) {
      this.filters.reviewCount = 0;
      this.emitFilters("onReviewOperatorSelected");
    }
  }
  onReviewOperatorDeSelect(_event: any) {
    this.filters.reviewOperator = this.reviewOperators.length ? this.reviewOperatorSelectedItem[0].id : "";
    if (this.filters.reviewOperator == One2FiveConstant.all) {
      this.filters.reviewCount = 0;
      this.emitFilters("onReviewOperatorDeSelect");
    }
  }
  trimReviewOperators() {
    this.filters.reviewOperator = this.reviewOperators.length ? this.reviewOperatorSelectedItem[0].id : "";
    if (this.filters.reviewOperator == One2FiveConstant.all) {
      this.filters.reviewCount = 0;
    }
  }
  onSearchReviewCount() {
    if (this.filters.reviewCount <= 0) {
      this.filters.reviewCount = 0;
    }
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onSearchReviewCount");
  }

  onStarProductSelected(value: any) {
    this.filters.starProduct = value.itemName;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onStarProductSelected");
  }
  starProductRemoved(_value: any) {
    this.filters.starProduct = '';
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("starProductRemoved");
  }

  onApplyCalender(value: any, _daterange?: any) {
    this.filters.fromDate = value.picker.startDate.format('YYYY-MM-DD');
    this.filters.toDate = value.picker.endDate.format('YYYY-MM-DD');

    this.picker.datePicker.autoUpdateInput = true;

    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onApplyCalender");
  }
  onCalendarCanceled(value1: any) {
    this.picker.datePicker.autoUpdateInput = false;
    this.filters.fromDate = '';
    this.filters.toDate = '';
    value1.event.target.value = '';
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onCalendarCanceled");
  }

  onStarRatingChange() {
    this.filters.ratings = this.selectedRatings.map((rating: any) => rating.id);
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onStarRatingChange");
  }

  onProductStatusSelected(_event: any) {
    this.filters.productStatus = _event.itemName;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onProductStatusSelected");
  }
  productStatusRemoved(_event: any) {
    this.filters.productStatus = '';
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("productStatusRemoved");
  }

  onReviewStatusSelected(_event: any) {
    this.filters.reviewStatus = _event.itemName;
    this.emitFilters("onReviewStatusSelected");
  }
  reviewStatusRemoved(_event: any) {
    this.filters.reviewStatus = '';
    this.emitFilters("reviewStatusRemoved");
  }

  defaultDateRangeForDashboard() {
    const date = moment().format('YYYY-MM-DD');
    const currentYear = new Date(Date.now()).getFullYear();
    this.selectedFromDate = currentYear + '-01-01';
    this.selectedToDate = date;

    this.filters.toDate = this.selectedToDate;
    this.filters.fromDate = this.selectedFromDate;
    this.updateDateRange(this.filters.fromDate, this.filters.toDate);
  }
  onProductGroupTypeSelect(_event: any) {
    this.filters.productGrouping = '';
    this.filters.productGrouping = _event.itemName;
    this.emitFilters("onProductGroupTypeSelect");
  }
  onProductGroupTypeDeSelect(_item: any) {
    this.filters.productGrouping = '';
    this.emitFilters("onProductGroupTypeDeSelect");
  }

  fillProductGroupingStatus(productGrouping: any) {
    if (!productGrouping) {
      this.filters.productGrouping = One2FiveConstant.Off;
      this.tempSelectedProductGroupStatus = this.productGroupTypeList.filter(item => item.itemName === One2FiveConstant.Off);
    } else {
      this.tempSelectedProductGroupStatus = this.productGroupTypeList.filter(item => item.itemName === productGrouping);
      this.trimProductGroupingStatus();
    }
  }

  trimProductGroupingStatus() {
    this.filters.productGrouping = this.tempSelectedProductGroupStatus[0].itemName;
  }

  onSelectedDate(value: any, _datepicker?: any): void {
    this.dateRange.start = value.start;
    this.dateRange.end = value.end;
    this.dateRange.label = value.label;

    this.selectedFromDate = value.start.format('YYYY-MM-DD');
    this.selectedToDate = value.end.format('YYYY-MM-DD');
    this.updateDateRange(this.selectedFromDate, this.selectedToDate);
  }

  onReviewAnswerSelected(_event: { id: number }) {
    this.filters.hasAnswers = _event.id === 1;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("onReviewAnswerSelected");
  }

  reviewAnswerRemoved(_event: any) {
    this.filters.hasAnswers = false;
    this.one2FiveHelper.pageIndex(1);
    this.emitFilters("reviewAnswerRemoved");
  }

  resetAllFilter(isNavigationFromClearFilterButton: any): void {
    this.brandSelectedItems = [];
    this.marketListSelectedItems = [];
    this.filters.openSearch = '';
    this.selectedCategory = [];
    this.selectedProduct = [];
    this.selectedDateRange = '';
    this.selectedFromDate = '';
    this.selectedToDate = '';
    this.tempSelectedStarProduct = [];
    this.selectedStarProduct = [];
    this.selectedSource = [];
    this.selectedReviewStatus = [];
    this.tempSelectedModerationCodes = [];
    this.campaignsSelectedItem = [];
    this.tempSelectedProductGroupStatus = [];
    this.selectedRatings = [];
    this.reviewAnswersChoice = null;
    this.selectedBadgeTemplate = null;
    this.selectedSavedStates = [];
    this.filters = new ReviewFiltersModel();
    this.filters.company = getCompanyName();
    this.saveState = 'Save View';

    this.reviewOperatorSelectedItem = [AppLocalStoreService.getReviewOperators()[0]];
    this.filters.reviewOperator = One2FiveConstant.all;

    if (this.selectedState.length > 0) {
      this.savedStates = [];
      this.getSavedStates();
    }
    if (isNavigationFromClearFilterButton) {
      this.clearReviewerData();
      this.emitFilters("resetAllFilter");
    }
  }

  setDaterangePickerOptions(): void {
    const currentDate = new Date(Date.now());
    const currentMonth = currentDate.getMonth() + 1;

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'YYYY-MM-DD',
        cancelLabel: One2FiveConstant.Clear
      },
      autoUpdateInput: false,
      alwaysShowCalendars: false,
      maxDate: currentDate.getFullYear() + '-' + currentMonth + '-' + currentDate.getDate(),
      ranges: {
        Today: [moment(), moment()],
        'Last 7 Days': [moment().subtract(6, 'day'), moment()],
        'Last 15 Days': [moment().subtract(14, 'day'), moment()],
        'Last 30 Days': [moment().subtract(29, 'day'), moment()],
      },
      parentEl: '#dateRangeInputShow'
    };
    this.daterangepickerOptions.settings.skipCSS = true;
  }

  setdefaultProductStatus(): void {
    const defaultProductStatus = this.defaultProductStatus === One2FiveConstant.Active && this.filterAccess.ProductStatus === true ? One2FiveConstant.Active : "";
    this.fillProductStatus(defaultProductStatus);
  }

  showFilterLoader(data: any): void {
    if (this.isReviewFilterOpen === true && !data.length) {
      this.applyFilterloaderStyle();
    } else {
      this.hideFilterloaderStyle();
    }
  }

  applyFilterloaderStyle(): void {
    One2FiveLoadingService.show();
  }
  hideFilterloaderStyle(): void {
    One2FiveLoadingService.hide();
  }

  onBookmarkDeSelect(_event: any): void {
    this.selectedSavedStates = [];
    this.resetAllFilter(true);
  }

  getReviewerInfo() {
    this.commonUtilService.getreviewrData().subscribe({
      next: (reviewerData: ReviewrInfo) => {
        this.reviewerName = reviewerData.reviewerName || '';
        this.filters.reviewerId = this.reviewerName ? this.filters.reviewerId : "";
        this.isReviewFilterOpen = reviewerData.reviewerName ? true : false;
        this.getReviewerData(reviewerData);
      },
      error: (e) => console.error(e),
      complete: () => console.info('complete')
    });
  }

  onCloseReviewerData(): void {
    this.clearReviewerData();
    if (this.filters.reviewerId == '') {
      this.one2FiveHelper.pageIndex(1);
    }
    this.one2FiveHelper.applyFilter(this.filters);
  }

  getReviewerData(reviewerData: ReviewrInfo): void {
    if (this.reviewerName && reviewerData.reviewerId) {
      this.filters.reviewerId = String(reviewerData.reviewerId || '');
      if (this.filters.reviewerId == '') {
        this.one2FiveHelper.pageIndex(0);
      } else {
        this.one2FiveHelper.pageIndex(1);
      }
      this.one2FiveHelper.applyFilter(this.filters);
    }
  }

  clearReviewerData(): void {
    this.reviewerName = '';
    this.filters.reviewerId = '';
    this.filters.openSearch = '';
  }

  initializeReviewFilters(_marketName: string, _productId: string, _productType: string, _reviewIdSearchText: string = ""): void {
    this.isReviewFilterOpen = true;
    this.getBrandSite(_marketName, true);
    this.getProducts(_marketName, _productId, [""], true);

    this.productType = _productType;
    this.filters.fromDate = "";
    this.filters.toDate = "";
    this.selectedDateRange = '';
    this.filters.marketName = [_marketName];
    this.filters.productExtId = [_productId];

    this.filters.openSearch = _reviewIdSearchText;
    this.searchFilter(_reviewIdSearchText);
  }

  updateFilterAccess(_accessControl: FilterAccessModel): void {
    this.filterAccess = _accessControl;
  }

}
