import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class One2FiveLoadingService {

  private static _showLoadingSpinner: Subject<boolean> = new BehaviorSubject<boolean>(false);
  static showLoadingSpinner$: Observable<boolean> = One2FiveLoadingService._showLoadingSpinner.asObservable();
  public static show() {
    this._showLoadingSpinner.next(true);
  }
  public static hide() {
    this._showLoadingSpinner.next(false);
  }

  private static _showParentLoadingSpinner: Subject<boolean> = new BehaviorSubject<boolean>(false);
  static showParentLoadingSpinner$: Observable<boolean> = One2FiveLoadingService._showParentLoadingSpinner.asObservable();
  public static _show() {
    this._showParentLoadingSpinner.next(true);
  }
  public static _hide() {
    this._showParentLoadingSpinner.next(false);
  }

}