import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { One2FiveHelperService } from '../../services/one2five-helper.service';
import { ApplyThemeService } from '../../services/apply-theme.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { One2FiveLoadingService } from '../app-loader/loader.service';
import { delay, getCompanyName, getCurrentUserRole } from 'src/app/core/services/one2five-utils';
import { MessagesService } from '../messages/messages.service';
import { StateManagementService } from '../../services/state.service';
import { DataService } from '../../services/data.service';
import { One2FiveConstant } from '../../models/referance.model';
import { ApiPassKeys, RetailerPasskey } from '../../models/api-response.model';
import { IMarketModel } from 'src/app/modules/settings/models/manage-market.model';
import { ApiPassKeysRequest, CreateRetailerApikeyRequest, SetupRetailers } from './pass-key.model';

@Component({
  selector: 'app-pass-key',
  templateUrl: './pass-key.component.html',
  styleUrls: ['./pass-key.component.scss'],
  providers: [DatePipe]
})
export class PassKeyComponent implements OnChanges, OnInit {
  @ViewChild("descriptionInputRef", { static: false }) descriptionInputRef!: ElementRef;
  @Input("module") module: string = "";
  @Input("index") index: number = -1;
  @Input() marketData!: IMarketModel;
  @Input() newMarketData!: IMarketModel;
  @Input() retailerData!: SetupRetailers;
  @Output("onCloseModalWindow") onCloseModalWindow = new EventEmitter<boolean>();

  marketApiPassKeysData: ApiPassKeys[] = [];
  requestPassKeysData!: ApiPassKeysRequest;
  retailerResponseData: RetailerPasskey[] = [];

  visibleMarketPopup: boolean = false;
  expandRetailerFilterPanel = false;
  marketList: any[] = [];
  brandNameOriginalList: any[] = [];
  marketDropdownSettings = {};
  selectedMarkets: any[] = [];
  passKeyProductGroupingStatus = false;
  createRetailerApikeyRequest!: CreateRetailerApikeyRequest;

  visibleRetailerPopup: boolean = false;
  marketPassKeyForm!: FormGroup;
  retailerPassKeyForm!: FormGroup;
  editableIndex: number = -1;
  passKeyDescription: string = "";
  passKeySyndication: boolean = false;
  includeProductGrouping: boolean = false;
  expandMarketFilterPanel: boolean = false;
  passKeyEnabled: boolean = false;
  rateLimitEnabled: boolean = false;
  rateLimitPerMinute: number = 0;

  constructor(private fb: FormBuilder,
    private translate: TranslateService,
    public applyThemeService: ApplyThemeService,
    public one2FiveHelper: One2FiveHelperService,
    private dataService: DataService,
    private stateService: StateManagementService,
    public commonUtilService: CommonUtilService, private datePipe: DatePipe) {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.index == -1) return;

    this.editableIndex = -1;
    this.visibleMarketPopup = false;
    this.visibleRetailerPopup = false;
    this.passKeyDescription = "";
    this.passKeySyndication = false;
    this.passKeyEnabled = false;
    this.rateLimitEnabled = false;
    this.rateLimitPerMinute = 0;
    this.includeProductGrouping = false;
    this.includeProductGrouping = false;
    this.passKeyProductGroupingStatus = false;
    this.selectedMarkets = [];

    this.marketPassKeyForm = this.fb.group({
      marketPassKeyFields: this.fb.array([])
    });
    this.retailerPassKeyForm = this.fb.group({
      retailerPassKeyFields: this.fb.array([])
    });
    if (this.module === One2FiveConstant.Market) {
      this.visibleMarketPopup = true;
      this.expandMarketFilterPanel = false;
      this.getMarketAPIPassKeys("preview");
    }
    if (this.module === One2FiveConstant.Retailer) {
      this.visibleRetailerPopup = true;
      this.expandRetailerFilterPanel = false;
      this.getRetailerApiPasskeys();
      this.setDropDownSetting();
      this.getBrandSite([]);
    }
  }

  ngOnInit() {
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
    });
  }

  get marketPassKeyFields(): FormArray | any {
    return this.marketPassKeyForm.controls["marketPassKeyFields"] as FormArray;
  }
  get retailerPassKeyFields(): FormArray | any {
    return this.retailerPassKeyForm.controls["retailerPassKeyFields"] as FormArray;
  }

  private addMarketPassKeyRow(apiKeyId: number, description: string, apiPassKey: string, requestTime: number, syndication: boolean, includeProductGrouping: boolean, isViewData: boolean, enabled: boolean = false, rateLimitEnabled: boolean = false, rateLimitPerMinute: number = 0) {
    const passKeyForm = this.fb.group({
      apiKeyId: [apiKeyId],
      description: [{ value: description, disabled: isViewData }],
      apiPassKey:[{ value: apiPassKey, disabled: true }],
      requestTime: new FormControl({ value: this.datePipe.transform(requestTime, 'dd/MM/yyyy HH:mm:ss'), disabled: true }),
      syndication: [{ value: syndication, disabled: isViewData }],
      includeProductGrouping: [{ value: includeProductGrouping, disabled: isViewData }],
      enabled: [{ value: enabled, disabled: isViewData }],
      rateLimitEnabled: [{ value: rateLimitEnabled, disabled: isViewData }],
      rateLimitPerMinute: [{ value: rateLimitPerMinute, disabled: isViewData }, [Validators.pattern("^[0-9]*$"), Validators.required, Validators.min(0)]],
    });
    this.marketPassKeyFields.push(passKeyForm);
  }

  public async onMarketArrowClick() {
    this.expandMarketFilterPanel = !this.expandMarketFilterPanel;
    this.includeProductGrouping = true;
    await delay(0);
    this.descriptionInputRef?.nativeElement?.focus();
  }

  public onCreateMarketPassKeyClick(action: string) {
    this.getMarketAPIPassKeys(action, this.passKeyDescription, this.passKeySyndication, this.includeProductGrouping, this.passKeyEnabled, this.rateLimitEnabled, this.rateLimitPerMinute);
  }
  public onEditMarketRowClick(rowIndex: number, rowControls: any) {
    this.editableIndex = rowIndex;
    this.setMarketPassKeyRow(rowIndex, rowControls.getRawValue(), false);
  }

  private setMarketPassKeyRow(rowIndex: number, rowData: ApiPassKeys, isViewData: boolean) {
    const marketPassKeyForm = this.fb.group({
      apiKeyId: [rowData.apiKeyId],
      description: [{ value: rowData.description, disabled: isViewData }],
      apiPassKey: [{ value: rowData.apiPassKey, disabled: true }],
      requestTime: [{ value: rowData.requestTime, disabled: true }],
      syndication: [{ value: rowData.syndication, disabled: isViewData }],
      includeProductGrouping: [{ value: rowData.includeProductGrouping, disabled: isViewData }],
      enabled: [{ value: rowData.enabled, disabled: isViewData }],
      rateLimitEnabled: [{ value: rowData.rateLimitEnabled, disabled: isViewData }],
      rateLimitPerMinute: [{ value: rowData.rateLimitPerMinute, disabled: isViewData }, [Validators.pattern("^[0-9]*$"), Validators.required, Validators.min(0)]],
    });
    this.marketPassKeyFields.removeAt(rowIndex);
    this.marketPassKeyFields.insert(rowIndex, marketPassKeyForm);
  }

  private getMarketAPIPassKeys(action: string, description: string = "", syndication: boolean = false, includeProductGrouping: boolean = true, enabled: boolean = false, rateLimitEnabled: boolean= false, rateLimitPerMinute: number = 0)  {
    const requestInput = {
      "displayName": this.marketData.displayName,
      "description": description,
      "syndication": syndication.toString(),
      "isProductGrouping": includeProductGrouping.toString(),
      "enabled": enabled.toString(),
      "rateLimitEnabled": rateLimitEnabled.toString(),
      "rateLimitPerMinute": rateLimitPerMinute.toString(),
      "token": ""
    }
    One2FiveLoadingService.show();

    this.dataService.marketApiPassKeys(action, requestInput).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data["HasErrors"]) return;

      this.passKeyDescription = "";
      this.passKeySyndication = false;
      this.includeProductGrouping = false;
      this.rateLimitEnabled = false;
      this.rateLimitPerMinute = 0;
      this.expandMarketFilterPanel = false;
      this.passKeyEnabled = false;
      if (data && data?.apiPassKeys?.length) {
        this.marketPassKeyFields.clear();
        data.apiPassKeys.forEach((element: ApiPassKeys) => {
          this.addMarketPassKeyRow(element.apiKeyId, element.description, element.apiPassKey, element.requestTime, element.syndication, element.includeProductGrouping, true, element.enabled, element.rateLimitEnabled, element.rateLimitPerMinute);
        });
      }
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  public onUpdateMarketRowClick(rowIndex: number, rowControls: any) {
    const rowData: ApiPassKeys = rowControls.getRawValue();
    const requestInput = {
      "displayName": this.marketData.displayName,
      "description": rowData.description ? rowData.description : "",
      "syndication": rowData.syndication.toString(),
      "isProductGrouping": rowData.includeProductGrouping.toString(),
      "enabled": rowData.enabled.toString(),
      "rateLimitEnabled": rowData.rateLimitEnabled.toString(),
      "rateLimitPerMinute": rowData.rateLimitPerMinute,
      "token": ""
    }
    this.dataService.updateMarketApiPassKey(rowData.apiKeyId, requestInput).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data["HasErrors"]) return;

      if (data && data?.apiPassKeys?.length) {
        this.setMarketPassKeyRow(rowIndex, rowData, true);
        this.editableIndex = -1;
      }
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  public onCancelMarketRowClick(_rowIndex: number, _rowControls: any) {
    this.getMarketAPIPassKeys("preview");
    this.editableIndex = -1;
  }

  //  Retailer Region starts
  private getRetailerApiPasskeys(): void {
    One2FiveLoadingService.show();
    this.dataService.getRetailerApiPasskeys(Number(this.retailerData.retailerId)).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data["HasErrors"]) return;

      if (data && data?.apiPassKeys?.length) {
        this.retailerPassKeyFields.clear();
        data.apiPassKeys.forEach((element: ApiPassKeys) => {
          this.addRetailerPassKeyRow(element.apiKeyId, element.description, element.passkey, element.marketName,
            element.status, element.updatedTime, element.includeProductGrouping, element.syndication, element.enabled, element.rateLimitEnabled, element.rateLimitPerMinute, true);
        });
      }
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  private addRetailerPassKeyRow(apiKeyId: number, description: string, apiPassKey: string, marketName: string, status: boolean, updatedTime: number,
    includeProductGrouping: boolean = false, syndication: boolean = false, enabled: boolean = false, rateLimitEnabled: boolean = false, rateLimitPerMinute: number, isViewData: boolean): void {
    const passKeyForm = this.fb.group({
      apiKeyId: [apiKeyId],
      description: [{ value: description, disabled: isViewData }],
      apiPassKey: [{ value: apiPassKey, disabled: true }],
      marketName: new FormControl({ value: marketName, disabled: true }),
      status: new FormControl({ value: status, disabled: true }),
      updatedTime: new FormControl({ value: this.datePipe.transform(updatedTime, 'dd/MM/yyyy HH:mm:ss'), disabled: true }),
      includeProductGrouping: [{ value: includeProductGrouping, disabled: isViewData }],
      syndication: [{ value: syndication, disabled: isViewData }],
      enabled: [{ value: enabled, disabled: isViewData }],
      rateLimitEnabled: [{ value: rateLimitEnabled, disabled: isViewData }],
      rateLimitPerMinute: [{ value: rateLimitPerMinute, disabled: isViewData }],
    });
    this.retailerPassKeyFields.push(passKeyForm);
  }

  private createRetailerPassKey(description: string, syndication: boolean, enabled: boolean, rateLimitEnabled:boolean, rateLimitPerMinute: number) {
    const retailerInput = {
      'companyName': getCompanyName(),
      'retailerId': Number(this.retailerData.retailerId),
      'displayName': this.selectedMarkets[0].regionName,
      "description": description,
      "syndication": syndication.toString(),
      "enabled": enabled.toString(),
      "rateLimitEnabled": rateLimitEnabled.toString(),
      "rateLimitPerMinute": rateLimitPerMinute.toString(),
      "action": "submit"
    }

    this.dataService.createRetailerApiPasskeys(retailerInput).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data["HasError"]) return;

      this.passKeyDescription = "";
      this.passKeySyndication = false;
      this.passKeyEnabled = false;
      this.rateLimitEnabled = false;
      this.rateLimitPerMinute = 0;

      if (data && data?.apiPassKeys?.length) {
        this.retailerPassKeyFields.clear();
        data.apiPassKeys.forEach((element: ApiPassKeys) => {
          this.addRetailerPassKeyRow(element.apiKeyId, element.description, element.passkey, element.marketName,
            element.status, element.updatedTime, element.includeProductGrouping, element.syndication, element.enabled, element.rateLimitEnabled, element.rateLimitPerMinute, true);
        });
      }
      this.selectedMarkets = [];
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
      this.expandRetailerFilterPanel = false;
      MessagesService.errorMessage('');
    });
  }

  public onCreateRetailerPassKeyClick() {
    if (this.selectedMarkets.length == 0) return;

    this.createRetailerPassKey(this.passKeyDescription, this.passKeySyndication, this.passKeyEnabled, this.rateLimitEnabled, this.rateLimitPerMinute);
    this.expandRetailerFilterPanel = false;
    MessagesService.errorMessage('');
  }
  public onEditRetailerRowClick(rowIndex: number, rowControls: any) {
    this.editableIndex = rowIndex;
    this.setRetailerRow(rowIndex, rowControls.getRawValue(), false);
  }
  public onCancelRetailerRowClick(rowIndex: number, rowControls: any) {
    this.getRetailerApiPasskeys();
    this.editableIndex = -1;
  }
  public onUpdateRetailerRowClick(rowIndex: number, rowControls: any) {
    const rowData: ApiPassKeys = rowControls.getRawValue();
    const requestInput = {
      apiKeyId: rowData.apiKeyId,
      description: rowData.description ? rowData.description : "",
      isProductGrouping: rowData.includeProductGrouping,
      syndication: rowData.syndication.toString(),
      enabled: rowData.enabled.toString(),
      rateLimitEnabled: rowData.rateLimitEnabled.toString(),
      rateLimitPerMinute: rowData.rateLimitPerMinute,
      token: ""
    }
    this.dataService.updateRetailerApiPassKeys(requestInput).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data[`HasErrors`]) return;
      this.setRetailerRow(rowIndex, rowControls.getRawValue(), true);
      this.editableIndex = -1;
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  private setRetailerRow(rowIndex: number, rowData: ApiPassKeys, isViewData: boolean) {
    const retailerPassKeyForm = this.fb.group({
      apiKeyId: [rowData.apiKeyId],
      description: [{ value: rowData.description, disabled: isViewData }],
      apiPassKey: [{ value: rowData.apiPassKey, disabled: true }],
      marketName: [{ value: rowData.marketName, disabled: true }],
      status: [{ value: rowData.status, disabled: true }],
      updatedTime: [{ value: rowData.updatedTime, disabled: true }],
      includeProductGrouping: [{ value: rowData.includeProductGrouping, disabled: isViewData }],
      syndication: [{ value: rowData.syndication, disabled: isViewData }],
      enabled: [{ value: rowData.enabled, disabled: isViewData }],
      rateLimitEnabled: [{ value: rowData.rateLimitEnabled, disabled: isViewData }],
      rateLimitPerMinute: [{ value: rowData.rateLimitPerMinute, disabled: isViewData }],
    });
    this.retailerPassKeyFields.removeAt(rowIndex);
    this.retailerPassKeyFields.insert(rowIndex, retailerPassKeyForm);
  }
  //  Retailer Region ends


  onRetailerArrowClick(): void {
    this.expandRetailerFilterPanel = !this.expandRetailerFilterPanel;
  }
  setDropDownSetting(): void {
    const filterAccess = getCurrentUserRole() === One2FiveConstant.Moderator ? false : true;
    this.marketDropdownSettings = {
      singleSelection: true,
      primaryKey: "regionName",
      text: this.commonUtilService.translateWords(["All", "Brand", "Site"]),
      enableCheckAll: false,
      enableSearchFilter: filterAccess,
      classes: "ddCustomClass custom-class",
      badgeShowLimit: 1,
      escapeToClose: true,
      lazyLoading: true,
      enableFilterSelectAll: false
    };
  }

  getBrandSite(brandSites: any): void {
    this.marketList = [];
    this.brandNameOriginalList = [];
    this.selectedMarkets = [];

    this.stateService.getCountrySelect().subscribe((data: any) => {
      this.marketList = data.filter((el: any) => el.regionName);
      this.brandNameOriginalList = data.filter((el: any) => el.regionName);
      this.selectedMarkets = brandSites;
    }, (error: any) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  onBrandSearchKeyUp(evt: any) {
    this.marketList = this.brandNameOriginalList.filter(element => element.itemName.toString().toUpperCase().includes(evt.target.value.toString().toUpperCase()));
  }

  onCloseModalWindowClick(): void {
    this.onCloseModalWindow.emit(false);
  }

}
