import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";

import { environment } from "src/environments/environments";
import { API_URLS } from "../constants/api_urls";
import { CompaniesResponse, VoucherCampaignsResponse } from "../models/api-response.model";
import { HeaderData, LanguageModel, MenuBarData, StringNullType } from "../models/referance.model";
import { One2FiveUtils, getCompanyName, getHeaderPayload, getToken, validateResponse } from "src/app/core/services/one2five-utils";
import { CommonUtilService } from "src/app/core/services/common-util.service";
import { WelcomeNoteData } from "../models/class";
import { ApiPassKeysRequest, CreateRetailerApikeyRequest, SetupRetailers, UpdateRetailerApikeyRequest } from "../components/pass-key/pass-key.model";
import { PermissionGroupResponse } from "src/app/core/models/user.permission.model";
import { IBadgeResponseModel } from "../models/request.interface";
import { ISaveFilterModel } from "../models/review-filter-model";
import { ClientModel } from "../components/manage-client/manage-client";

@Injectable({ providedIn: "root" })
export class DataService {
    baseUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient,
        private commonUtilService: CommonUtilService) {
    }

    getThemeData(isDefault: boolean = true): Observable<any> {
        const headers: any = isDefault ? { company: "MSM Digital", isDefault: isDefault + "" } : { company: "MSM Digital" };
        return this.httpClient.get<any>(`${this.baseUrl}/app/data/fetch/rebranding`, { headers });
    }

    changeTheme(data: FormData): Observable<any> {
        data.append("company", getCompanyName());
        return this.httpClient.post<any>(API_URLS.changeThemeURL, data);
    }
    getCompany() {
        const headers = { token: getToken() };
        return this.httpClient.get<CompaniesResponse>(API_URLS.getCompaniesURL, { headers }).pipe(map((res) => res["results"]));
    }
    getMenubarData() {
        return this.httpClient.get<MenuBarData[]>(API_URLS.menuDataURL).subscribe(_menu => {
            this.commonUtilService.loadMenuItems(_menu);
        });
    }
    getHeaderData() {
        return this.httpClient.get<HeaderData[]>(API_URLS.headerDataURL);
    }
    getWelcomeNote(): Observable<any> {
        return this.httpClient.get<WelcomeNoteData>(API_URLS.welcomeNoteURL, { headers: getHeaderPayload() })
    }
    getLanguageList(): Observable<LanguageModel[]> {
        const headers = { token: getToken() };
        return this.httpClient.get<LanguageModel[]>(API_URLS.languages, { headers });
    }
    updateProfile(data: any): Observable<any> {
        let inputPayload = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.updateProfileURL, inputPayload);
    }
    updateProfileImage(data: any): Observable<any> {
        const imageUploadURL = API_URLS.imageUploadURL + One2FiveUtils.parseString(sessionStorage.getItem("currentUser")).userId;
        return this.httpClient.post<any>(imageUploadURL, data, { headers: getHeaderPayload() }).pipe(map((response) => response.imagePath));
    }
    changePassword(data: any) {
        let inputPayload = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.changePasswordURL, inputPayload);
    }

    getUserRoles() {
        return this.httpClient.post<any>(API_URLS.userRolesURL, getHeaderPayload());
    }

    getLanguageAndCountryData() {
        return this.httpClient.get<any>(API_URLS.getLanguageAndCountryDataURL, { headers: getHeaderPayload() }).pipe(map((res) => validateResponse(res["brandLocale"])));
    }

    getLanguageAndCountry() {
        const headers = {
            token: getToken(),
        };
        return this.httpClient.get<any>(API_URLS.getLanguageAndCountryURL, { headers: headers }).pipe(map((res) => validateResponse(res)));
    }
    getLanguagesList() {
        return this.httpClient.get<any[]>(API_URLS.getLanguagesListURL, { headers: getHeaderPayload() }).pipe(map((res: any) => res["languageCodeResponse"]));
    }
    getCountriesList() {
        return this.httpClient.get<any[]>(API_URLS.getCountriesListURL, { headers: getHeaderPayload() }).pipe(map((res: any) => res["countryCodeResponse"]));
    }
    getPermissionsData(data: any) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<PermissionGroupResponse>(API_URLS.getPermissionsDataURL, inputPayload);
    }

    marketApiPassKeys(action: string, data: ApiPassKeysRequest) {
        let url = "";
        if (action == "preview") url = API_URLS.previewMarketApiPassKeyURL;
        if (action == "submit") url = API_URLS.createMarketApiPassKeyURL;

        data["token"] = getToken();
        let inputPayload: any = { ...data, ...{ "action": action } };

        return this.httpClient.post<any>(url, inputPayload);
    }

    public updateMarketApiPassKey(apiKeyId: number, data: ApiPassKeysRequest): any {
        data['token'] = getToken();
        return this.httpClient.put<any>(`${API_URLS.updateMarketApiPassKeyURL}/${apiKeyId}`, data);
    }

    getCreateMarketApiPasskeys(data: ApiPassKeysRequest) {
        data["token"] = getToken();
        return this.httpClient.post<any>(API_URLS.getAndCreateMarketApiPassKeyURL, data);
    }

    getRetailers(companyName: string) {
        return this.httpClient.get<any>(API_URLS.getRetailersURL + '?companyName=' + companyName);
    }

    createAndUpdateRetailers(data: SetupRetailers) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.createAndUpdateRetailersURL, inputPayload);
    }

    createRetailerApiPasskeys(data: CreateRetailerApikeyRequest) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.CreateRetailerApiPassKeyURL, inputPayload);
    }
    getRetailerApiPasskeys(retailerId: number) {
        return this.httpClient.get<any>(API_URLS.getRetailerApiPassKeyURL + '?retailerId=' + retailerId);
    }

    updateRetailerApiPassKeys(data: UpdateRetailerApikeyRequest) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.UpdateRetailerApiPassKeyURL, inputPayload);
    }

    getbadges(reviewId: StringNullType = null, contentType: string = "REVIEW"): Observable<IBadgeResponseModel> {
        const data = {
            "reviewId": reviewId,
            "contentType": contentType
        };
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.badgeurl, inputPayload);
    }

    uploadReviewXls(data: FormData): Observable<any> {
        data.append("token", getToken());
        return this.httpClient.post<any>(API_URLS.uploadReviewXlsURL, data);
    }

    updateBadgeXls(data: FormData): Observable<any> {
        data.append("token", getToken());
        return this.httpClient.post<any>(API_URLS.updateBadgeXlsURL, data);
    }

    fetchSavedfilters(data: ISaveFilterModel) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.savefilterURL, inputPayload).pipe(map((res) => res["filter"]));
    }

    onSavefilter(data: ISaveFilterModel) {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.savefilterURL, inputPayload);
    }

    getBrandNames() {
        return this.httpClient.get<any[]>(API_URLS.getBrandNamesURL, { headers: getHeaderPayload() }).pipe(map((res: any) => res["brandList"]));
    }

    previewCompanydetails(companyId: string) {
        return this.httpClient.get<any>(API_URLS.previewCompanydetails + '/' + companyId, { headers: { token: getToken() } });
    }

    submitCompanydetails(inputData: ClientModel) {
        let inputPayload: any = { ...inputData, ...{ token: getToken() } };
        return this.httpClient.post<any>(API_URLS.submitCompanydetails, inputPayload);
    }

    updateCompanydetails(inputData: ClientModel) {
        let client= new ClientModel;
        client.id = inputData.id;
        client.companyName = inputData.companyName;
        client.brand = inputData.brand;
        client.senderEmail = inputData.senderEmail;
        let inputPayload: any = { ...client, ...{ token: getToken() } };
        return this.httpClient.put<any>(API_URLS.updateCompanydetails+ '/' + client.id, inputPayload);
    }
    
    fetchVoucherCampaign() {
        return this.httpClient.get<VoucherCampaignsResponse>(API_URLS.fetchVoucherCampaignURL, { headers: getHeaderPayload() });
    }

}