import { NumberNullType } from "./referance.model";

export class ReviewFiltersModel {
  categoryName: string[];
  reviewStatus: string;
  company: string;
  reviewSource: string[];
  productExtId: string[];
  starProduct: string;
  marketName: string[];
  toDate: string;
  fromDate: string;
  campaignNames: string[];
  productStatus: string;
  moderationCode: string;
  openSearch: string;
  token: string;
  productGrouping: string;
  ratings: number[];
  hasAnswers: any;
  badge: NumberNullType;
  includeSensitiveData?: any;
  reviewerId: string = "";
  brandNames: string[] = [];
  reviewCount: number = 0;  
  reviewOperator: string = "all";

  constructor() {
    this.categoryName = [];
    this.reviewStatus = '';
    this.company = '';
    this.reviewSource = [];
    this.productExtId = [];
    this.starProduct = '';
    this.marketName = [];
    this.toDate = '';
    this.fromDate = '';
    this.campaignNames = [];
    this.productStatus = '';
    this.moderationCode = '';
    this.openSearch = '';
    this.token = '';
    this.productGrouping = '';
    this.ratings = [];
    this.hasAnswers = false;
    this.badge = null;
    this.includeSensitiveData = false;
    this.reviewerId = "";  
  }
}

export enum FilterLabels {
  BrandSite = 'Brand Site',
  Category = 'Category',
  Product = 'Product',
  Date = 'Date',
  StarProduct = 'Star Product',
  ProductStatus = 'Product Status',
  ModerationCode = 'Moderation Code',
  ModerationStatus = 'Moderation Status',
  ReviewSource = 'Review Source',
  Campaign = 'Campaign',
  OpenSearch = 'Open Search',
  ProductGrouping = 'Product Grouping',
}

export class FilterAccessModel {
  BrandName: boolean = false;
  BrandSite: boolean = false;
  Category: boolean = false;
  Product: boolean = false;
  Date: boolean = false;
  StarProduct: boolean = false;
  ProductStatus: boolean = false;
  ModerationCode: boolean = false;
  ModerationStatus: boolean = false;
  ReviewSource: boolean = false;
  Campaign: boolean = false;
  OpenSearch: boolean = false;
  ProductGrouping: boolean = false;
  Badges: boolean = false;
  StarRating: boolean = false;
  ReviewAnswers: boolean = false;
  ReviewCount: boolean = false;
}

export interface ISaveFilterModel {
  templateName: string;
  brandSite: string[];
  category: string[];
  product: string[];
  startDate: string;
  endDate: string;
  reviewSource: string[];
  moderationStatus: string;
  productStatus: string;
  starProduct: string;
  campaign: string[];
  moderationCode: string;
  templateId: any;
  action: string;
  searchText: string;
  productGrouping: string;
  ratings: number[];
  badge: NumberNullType;
  hasAnswers: boolean;
}