<div class="overlayWrapper PF style-4">
  <div class="editProfileDetailView">
    <div class="row containerHeader" [ngStyle]="{
        'background-color': applyThemeService.$themeColor,
        color: applyThemeService.$textColor
      }">
      <div class="col-md-offset-1 col-md-9 editProfileName">
        {{"Edit Profile" |translate}}
      </div>
      <div class="col-md-offset-1 col-md-1">
        <span class="closeEditProfile pull-right" (click)="destroyEditProfileView()">
          <span class="iconContainerSpan spanContainer closeBtnContainer">
            <span class="icon icon-cancel"></span>
          </span>
        </span>
      </div>
    </div>

    <div class="row rowMargin">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-2 labelHintText">
            <label for="name">{{"Name" | translate}} :</label>
          </div>
          <div class="col-md-8">
            <select class="salutation cursorPointer" name="" [(ngModel)]="userProfile.salutation">
              <option class="salutationOptions" value="Mr" selected>{{"Mr"|translate}}</option>
              <option class="salutationOptions" value="Mrs">{{"Mrs"|translate}}</option>
              <option class="salutationOptions" value="Ms">{{"Ms"|translate}}</option>
            </select>
            <input class="nameFields" type="text" name="" placeholder="{{'First'|translate}} {{'Name' | translate}}"
              [(ngModel)]="userProfile.firstName" />
            <input class="nameFields" type="text" name="" placeholder="{{'Last'|translate}} {{'Name' | translate}}"
              [(ngModel)]="userProfile.lastName" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row rowMargin">
          <div class="col-md-1"></div>
          <div class="col-md-2 labelHintText">
            <label for="nickname ">{{"Nickname" | translate}} :</label>
          </div>
          <div class="col-md-8">
            <input class="nickname" type="text" name="" placeholder="{{'Nickname'|translate}}"
              [(ngModel)]="userProfile.userNickname" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row rowMargin">
          <div class="col-md-1"></div>
          <div class="col-md-2 labelHintText">
            <label for="email">{{"Email" | translate}} :</label>
          </div>
          <div class="col-md-8">
            <input class="emailField" type="email" name="" placeholder="{{'Email'|translate}} {{'Address'|translate}}"
              [(ngModel)]="userProfile.emailId" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row rowMargin">
          <div class="col-md-3 labelHintText">
            <label for="Company_Name">{{"Company" | translate}} {{"Name" | translate}} :</label>
          </div>
          <div class="col-md-8">
            <label class="F_B">Electrolux</label>
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-3"></div>

          <div class="col-md-8">
            <button type="button" class="btn btn-primary customBtn" [ngStyle]="{
                'background-color': applyThemeService.$themeColor,
                'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor
              }" (click)="onFileUploadClick()" [disabled]="commonUtilService.showLoader">
              {{"Submit" | translate}}
            </button>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
</div>