<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header"
            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div></div>
            <div class="modalHeaderTitle">
                {{ "MENUITEMS.Badge Update" | translate}}
            </div>
            <button class="btn"
                [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
                (click)="onCloseUpdateWindow()">
                <span class="icon icon-cancel"></span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row mt-1">
                <div class="col-md-2 col-lg-2"></div>
                <div class="col-md-2">
                    {{"Brand Site" | translate}} <sup class="redColor"> *</sup>:
                </div>
                <div class="col-md-6">
                    <angular2-multiselect [settings]="marketDropdownSettings" [data]="marketList"
                        [(ngModel)]="marketListSelectedItems">
                    </angular2-multiselect>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-p5 AI_C">
                <div class="col-md-4 col-lg-4"></div>
                <div class="col-md-6 TA_C">
                    <button type="button" class="btn btn-default importDummyBtn w-100">
                        <a download="Import Dummy" href="/assets/files/import_badge_template.xlsx" target="_blank">
                            {{"BUTTONS.Download" | translate}} {{"Dummy" | translate}} {{"File" | translate}}
                            <span class="icon icon-download"></span>
                        </a>
                    </button>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-p5 AI_C">
                <div class="col-md-2 col-lg-2"></div>
                <div class="col-md-8 col-lg-8 importDummyBtn">
                    <ng-container *ngIf="isFileValid else showInvalidFile">
                        <span class="greenColor">
                            {{"SUCCESS.File has been uploaded successfully" | translate}}!</span>
                        <div>{{"or" | translate}}</div>
                        <button type="button" class="btn btn-primary customBtn" [ngStyle]="{
                        'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                        color: applyThemeService.$textColor  }" (click)="reUpload()">
                            {{"BUTTONS.Retry" |translate }}
                        </button>
                    </ng-container>
                    <ng-template #showInvalidFile>
                        <div ng2FileDrop (onFileDrop)="onFileDroped($event)" class="dropzone">
                            <span>{{"Click to add or Drop a file here" | translate}} </span>
                            <span class="icon icon-upload"></span>
                            <input #badgeUploadFileRef type="file" (change)="onSelectFile($event)" class="H-36P opacity-0"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="accessControls.isCreate" class="modal-footer gap-4 mt-1" [class.JC_SB]="errorMessage">
            <ng-container *ngIf="errorMessage">
                <h1 class="errorMsg">{{ errorMessage | translate }}</h1>
            </ng-container>
            <button type="button" class="btn btn-primary" [ngStyle]="{ 'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor}" (click)="onUpdateFileSubmit()">
                {{"BUTTONS.Submit" |translate }}
            </button>
        </div>
    </div>
</div>