import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "src/environments/environments";
import { API_URLS } from "src/app/shared/constants/api_urls";
import { getHeaderPayload } from "../services/one2five-utils";

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    baseUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient) { }

    public authenticateUser(inputPayload: any): Observable<any> {
        return this.httpClient.post<any>(API_URLS.loginUserURL, inputPayload);
    }

    public sendMailToUser(inputPayload: any): Observable<any> {
        return this.httpClient.post<any>(API_URLS.sendMailToUserURL, inputPayload);
    }

    public sendLogingDeviceInfo(data: any): Observable<any> {
        let inputPayload: any = { ...data, ...getHeaderPayload() };
        return this.httpClient.post<any>(API_URLS.logingAndDeviceinfoUrl, inputPayload);
    }

    getPermissionsData(inputPayload: any): Observable<any> {
        return this.httpClient.post<any>(API_URLS.getPermissionsDataURL, inputPayload);
    }

    logoutUser() {
        return this.httpClient.post<any>(API_URLS.logoutURL, {});
    }

}