
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ApplyThemeService } from "./apply-theme.service";
import { ReviewFiltersModel } from "../models/review-filter-model";

@Injectable({ providedIn: "root" })
export class One2FiveHelperService {

    constructor(private applyThemeService: ApplyThemeService) { }

    public defaultThemeColor(): string {
        return this.applyThemeService.$themeColor;
    }
    public changeStyle(event: any): string {
        const hoverEffectColor = event.type === "mouseover" ? this.applyThemeService.$secondaryColor : this.applyThemeService.$themeColor;
        return hoverEffectColor;
    }

    private _filterToggle: Subject<number> = new BehaviorSubject<number>(-1);
    filterToggle$: Observable<number> = this._filterToggle.asObservable();
    public filterToggle(_menuItems: number) {
        this._filterToggle.next(_menuItems);
    }

    private _pageIndex: Subject<number> = new BehaviorSubject<number>(1);
    pageIndex$: Observable<number> = this._pageIndex.asObservable();
    public pageIndex(_menuItems: number) {
        this._pageIndex.next(_menuItems);
    }

    private _applyFilter: Subject<ReviewFiltersModel> = new Subject<ReviewFiltersModel>();
    applyFilter$: Observable<ReviewFiltersModel> = this._applyFilter.asObservable();
    public applyFilter(_filterModel: ReviewFiltersModel) {
        let timeRef = null;
        if (timeRef) clearTimeout(timeRef);
        timeRef = setTimeout(() => {
            this._applyFilter.next(_filterModel);
        }, 0);
    }

    private _showSaveFilterForm: Subject<boolean> = new BehaviorSubject<boolean>(false);
    showSaveFilterForm$: Observable<boolean> = this._showSaveFilterForm.asObservable();
    public showSaveFilterForm(_isSaveFilter: boolean) {
        this._showSaveFilterForm.next(_isSaveFilter);
    }

}