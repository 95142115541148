<label class="arrow" for=""></label>
<span class="menuView">
  <ul class="userProfileOptions">
    <li class="menuItem" (click)="onEditProfile()">
      <a class=""> {{ 'Edit Profile' | translate }}</a>
    </li>
    <hr />
    <li class="menuItem" (click)="onChangePassword()">
      <a class="">{{ 'Change Password' | translate }}</a>
    </li>
    <hr />

    <li class="menuItem" routerLink="/login" (click)="onLogOut()">
      <a class="">{{ 'Log Out' | translate }}</a>
    </li>
  </ul>
</span>