<div class="overlayWrapper PF style-4">
  <div [ngStyle]="{'width': visibleMarketPopup ? '' : '100em'}" class="passKeyDetailView">
    <div class="row containerHeader" [ngStyle]="{
          'background-color': applyThemeService.$themeColor,
          color: applyThemeService.$textColor
        }">
      <div class="col-md-offset-1 col-md-9 reportTemplate">
        {{ "Passkey" | translate }} {{ "Details" | translate }}
      </div>
      <div class="col-md-offset-1 col-md-1">
        <span class="iconContainerSpan spanContainer closeBtnContainer" (click)="closeMarketPassKeyForm()">
          <span class="icon icon-cancel"></span>
        </span>
      </div>
    </div>
    <div class="row rowMarginForForm">
      <div class="col-sm-12 col-md-12" *ngIf='visibleMarketPopup'>
        <div class="row">
          <div class="col-md-9 labelHintText">
            <label for="name">{{ marketData.marketName }}</label>
          </div>
          <div class="col-md-3 text-right" [class.disabled]="visibleAddPassKeySection"
            [attr.disabled]="visibleAddPassKeySection" (click)="onMarketPassKeyClick()">
            <div class="newTemplate" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)" [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 
                 color: applyThemeService.$textColor}">
              <span class="newReport">{{ "New" | translate }} {{ "PassKey" | translate }} </span>
            </div>
            <span class="paddingArrow"
              [ngStyle]="{transform: visibleAddPassKeySection ? 'rotate(90deg)' : 'rotate(0deg)'}">
              <span class="icon icon-right-arrow" [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 
              color: applyThemeService.$textColor}"></span>
            </span>
          </div>
        </div>
        <br />
        <div *ngIf="visibleAddPassKeySection" class="row cls_addSectionRow">
          <button id="closeIcon" (click)="visibleAddPassKeySection= false"
            [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">X</button>
          <div class="col-md-5 P-5 D-F MT-10">
            <label for="descriptionInput" class="col-md-3 label-1"> {{ "Description" | translate }}</label>
            <input type="text" #descriptionInputRef autofocus class="col-md-9 inputStyle boxShadow"
              [(ngModel)]="passKeyDescription" placeholder="{{'Enter'|translate}} {{'Description' | translate}}">
          </div>
          <div class="col-md-7 P-5">
            <div class="col-md-3 D-F">
              <label for="syndicationToggle" class="col-md-8 label-2"> {{ "Syndication" | translate }}</label>
              <ui-switch class="col-md-2" [(ngModel)]="passKeySyndication"></ui-switch>
            </div>
            <div class="col-md-4 D-F">
              <label for="productGroupingToggle" class="col-md-9 label-3"> {{ "Product Grouping" | translate }}</label>
              <ui-switch class="col-md-2" [(ngModel)]="includeProductGrouping"></ui-switch>
            </div>
            <div class="col-md-3 D-F">
              <label for="passKeyEnabledToggle" class="col-md-6 label-3"> {{ "Enabled" | translate }}</label>
              <ui-switch class="col-md-2" [(ngModel)]="passKeyEnabled"></ui-switch>
            </div>
            <div class="col-md-2">
              <button class="newTemplate BR_20P W-100P" [title]="'Submit' | translate"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }"
                (click)="onCreateMarketPassKeyClick('submit')">
                {{ "Submit" | translate }}
              </button>
            </div>
          </div>
        </div>
        <br />
        <table class="col-md-10 table table-hover">
          <thead>
            <tr>
              <th class="T_AC w950px">{{ "Description" | translate }} </th>
              <th class="T_AC w800px">{{ "Pass" | translate }} {{ "Key" | translate }} </th>
              <th class="T_AC w350px">{{ "Created" | translate }} {{ "Time" | translate }} </th>
              <th class="T_AC w100px">{{ "Syndication" | translate }} </th>
              <th class="T_AC w100px">{{ "Product Grouping" | translate }} </th>
              <th class="T_AC w100px">{{ "Enabled" | translate }} </th>
              <th class="T_AC w250px">{{ "Action" | translate }} </th>
            </tr>
          </thead>
          <tbody [formGroup]="marketPassKeyForm" class="clstable_body">
            <ng-container formArrayName="marketPassKeyFields">
              <ng-container *ngFor="let passKeys of marketPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td class="T_AC w450px">
                    <textarea formControlName="description" class="W-100P H-27"
                      placeholder="{{'Enter'|translate}} {{'Description' | translate}}" focus="true"></textarea>
                  </td>
                  <td class="T_AC w300px">
                    <input type="text" formControlName="apiPassKey" class="W-100P">
                  </td>
                  <td class="T_AC w200px">
                    <input type="text" formControlName="requestTime" class="W-100P">

                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <button *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                      (click)="onEditMarketRowClick(idx, passKeys)" class="editaction_btn"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_editactionicon">edit</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Cancel' | translate" class="MR-2 action_btn"
                      (click)="onCancelMarketRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">restore</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Save' | translate" class="action_btn"
                      (click)="onUpdateMarketRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">save</i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="col-sm-12 col-md-12" *ngIf='!visibleMarketPopup'>
        <div class="row">
          <div class="col-md-9 labelHintText">
            <label for="name">{{ retailerData.retailerName}}</label>
          </div>
          <div class="col-md-3 text-right" (click)="openCloseCreateDiv()">
            <div class="newTemplate" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)"
              [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
              <span class="newReport">{{ "New" | translate }} {{ "PassKey" | translate }}</span>
            </div>
            <span class="paddingArrow" [ngStyle]="{transform: expandFilterPanel ? 'rotate(90deg)' : 'rotate(0deg)'}">
              <span class="icon icon-right-arrow" [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 
              color: applyThemeService.$textColor}"></span>
            </span>
          </div>
        </div>
        <div *ngIf="expandFilterPanel" class="row">
          <div class="col-sm-12 col-md-12 filterContainerInside P-0">
            <div class="col-sm-4 col-md-4 filter P-0">
              <label for="marketList" class="col-sm-3 col-md-3 cls_fontSize16 MT-8"> {{ "Brand" | translate }}
                {{ "Site" | translate }}</label>
              <div class="col-sm-9 col-md-9">
                <angular2-multiselect [data]="arrMarketList" [(ngModel)]="marketListSelectedItems"
                  [settings]="marketDropdownSettings" (ngModelChange)="getBrandSite($event)">
                  <c-search>
                    <ng-template>
                      <input type="text" (keyup)="onBrandSearchKeyUp($event)" [placeholder]="'Search Brand'| translate"
                        class="cls_dropdownsearchinput" />
                    </ng-template>
                  </c-search>
                  <c-item>
                    <ng-template let-item="item">
                      <span>{{ item.itemName }}</span>
                    </ng-template>
                  </c-item>
                </angular2-multiselect>
              </div>
            </div>
            <ng-container *ngIf="marketListSelectedItems.length">
              <div class="col-md-4 P-5 D-F">
                <label for="descriptionInput" class="col-md-3 cls_fontSize16 PT-5"> {{ "Description" | translate
                  }}</label>
                <input type="text" #descriptionInputRef autofocus class="col-md-9 ML-10 inputStyle boxShadow"
                  [(ngModel)]="passKeyDescription" placeholder="{{'Enter'|translate}} {{'Description' | translate}}">
              </div>
              <div class="col-sm-3 col-md-3 P-0 P-5 MT-15">
                <div class="col-sm-6 col-md-6 P-0 P-5">
                  <label for="syndicationToggle" class="col-md-8 cls_fontSize16"> {{ "Syndication" | translate
                    }}</label>
                  <ui-switch class="col-md-4" [(ngModel)]="passKeySyndication"></ui-switch>
                </div>
                <div class="col-sm-6 col-md-6 P-0 P-5">
                  <label for="passKeyEnabledToggle" class="col-md-8 cls_fontSize16"> {{ "Enabled" | translate }}</label>
                  <ui-switch class="col-md-4" [(ngModel)]="passKeyEnabled"></ui-switch>
                </div>
              </div>
            </ng-container>
            <div class="col-sm-1 col-md-1 text-right">
              <div class="newTemplate BR_20P MT-15"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }"
                (click)="onCreateRetailerPassKeyClick()">
                <span class="newReport">{{ "BUTTONS.Submit" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <table class="col-md-10 table table-hover">
          <thead>
            <tr>
              <th class="table_header W-300">{{ "Description" | translate }}</th>
              <th class="table_header W-500">{{ "Pass" | translate }} {{ "Key" | translate }}</th>
              <th class="table_header">{{ "Market" | translate }} {{ "Name" | translate }}</th>
              <th class="table_header">{{ "Status" | translate }}</th>
              <th class="table_header">{{ "Syndication" | translate }}</th>
              <th class="table_header">{{ "Created" | translate }} {{ "Time" | translate }}</th>
              <th class="table_header">{{ "Product" | translate }} {{ "Grouping" | translate }}</th>
              <th class="table_header">{{ "Enabled"| translate}}</th>
              <th class="table_header">{{ "Action" | translate }}</th>
            </tr>
          </thead>

          <tbody [formGroup]="retailerPassKeyForm" class="clstable_body">
            <ng-container formArrayName="retailerPassKeyFields">
              <ng-container *ngFor="let passKeys of retailerPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td id="table_data" class="W-510">
                    <textarea formControlName="description" class="W-100P"
                      placeholder="{{'Enter'|translate}} {{'Description' | translate}}" focus="true"></textarea>
                  </td>
                  <td id="table_data" class="W-710">
                    <input type="text" formControlName="apiPassKey" class="W-100P">
                  </td>
                  <td id="table_data">
                    <input type="text" formControlName="marketName" class="W-100P">
                  </td>
                  <td id="table_data">
                    <input type="text" formControlName="status" class="W-100">
                  </td>
                  <td id="table_data" class="W-100">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td id="table_data" class="W-180">
                    <input type="text" formControlName="updatedTime" class="W-100P">
                  </td>
                  <td id="table_data" class="W-150">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td id="table_data" class="W-115">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td id="table_data" class="W-200">
                    <button *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                      (click)="onEditRetailerRowClick(idx, passKeys)" class="action_btn"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">edit</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Cancel' | translate" class="MR-2 action_btn"
                      (click)="onCancelRetailerRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">restore</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Save' | translate" class="action_btn"
                      (click)="onUpdateRetailerRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">save</i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>