import { Output, Component, OnInit, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { DataService } from "../../services/data.service";
import { environment } from "src/environments/environments";
import { ApplyThemeService } from "../../services/apply-theme.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";
import { One2FiveUtils, getCurrentUserRole, getToken, getUserName } from "src/app/core/services/one2five-utils";
import { MessagesService } from "../messages/messages.service";
import { UserProfileModel } from "src/app/core/models/user-profile.model";
import { MessageItemType } from "../messages/message.model";
import { One2FiveConstant } from "../../models/referance.model";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
})
export class EditProfileComponent implements OnInit {
  @Output() destroy = new EventEmitter<string>();
  userProfile: UserProfileModel = new UserProfileModel;
  updateProfileFormData!: FormData;
  clientimage = "/assets/images/profilePicBig.png";

  constructor(private dataService: DataService,
    public applyThemeService: ApplyThemeService,
    private translate: TranslateService,
    public commonUtilService: CommonUtilService) {
  }

  ngOnInit() {
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
    });
    this.userProfile = One2FiveUtils.parseString(sessionStorage.getItem("currentUser")).userProfile;
    this.userProfile.salutation = this.userProfile.salutation ?? One2FiveConstant.Mr;
    if (this.userProfile.imagePath) {
      this.clientimage = environment.apiUrl + this.userProfile.imagePath;
    }
  }

  destroyEditProfileView() {
    this.destroy.emit("");
  }

  validateFields(): boolean {
    const regexrEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.userProfile.emailId) {
      if (!this.userProfile.emailId.match(regexrEmail)) {
        MessagesService.alertMessages("ERRORS.Please Enter your valid email.", "", "Validation Alert", MessageItemType.Warning);
        return false;
      }
    }
    return true;
  }

  onSubmitProfile() {
    let userProfile = One2FiveUtils.objectClone(this.userProfile);
    userProfile["email"] = this.userProfile.emailId;

    sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject({
      role: getCurrentUserRole(),
      token: getToken(),
      userName: getUserName(),
      userProfile: userProfile
    }));
    this.dataService.updateProfile(userProfile).subscribe(async (data: any) => {
      if (data["HasErrors"]) {
        MessagesService.errorAlert("ERRORS.Profile Updation Error");
        this.commonUtilService.showLoader = false;
        return;
      }
      MessagesService.successAlert("SUCCESS.Profile Updated successfully");
      this.commonUtilService.showLoader = false;
      this.destroyEditProfileView();
    }, (error) => {
      MessagesService.errorMessage(error);
      this.commonUtilService.showLoader = false;
    });
  }

  fileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length == 0) return;
    
    const file: File = fileList[0];
    const reader: any = new FileReader();
    const that = this;
    reader.onloadend = function () {
      that.clientimage = reader.result.toString();
    };
    reader.readAsDataURL(file);
    this.updateProfileFormData = new FormData();
    this.updateProfileFormData.append("profileImage", file, file.name);
  }

  onFileUploadClick() {
    if (this.validateFields()) {
      this.commonUtilService.showLoader = true;
      if (!this.updateProfileFormData) {
        this.onSubmitProfile();
        return;
      }
      this.dataService.updateProfileImage(this.updateProfileFormData).subscribe((data: any) => {
        this.userProfile.imagePath = data;
        const current_user = sessionStorage.getItem("currentUser");
        const current_userjson = One2FiveUtils.parseString(current_user);
        current_userjson.userProfile.imagePath = this.userProfile.imagePath;
        sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_userjson));
        this.onSubmitProfile();
      }, (_error: any) => {
        MessagesService.errorMessage(_error);
        this.commonUtilService.showLoader = false;
      });
    }
  }

}
