import { environment } from "src/environments/environments";

const apiUrl = environment.apiUrl;
const reportingURL = environment.reportingUrl;
const premoderationUrl = environment.premoderationUrl;

const jsonFilesURL = "/assets/config/";
const API_REDIRECTOR = "/data/";
const APP_API_REDIRECTOR = "/app/data/";
const REPORTING_API_REDIRECTOR = "/reporting/data/";
const REPORT_SCHEDULING_API_REDIRECTOR = "/reportscheduling/data/";
const PIE_API_REDIRECTOR = "/pie/data/";
const VOUCHER_API_REDIRECTOR = "/voucher/data/";
const QUESTIONANSWER_URL_REDIRECTOR = "/questionanswer/data/";
const MODERATION_URL_REDIRECTOR = "/data/moderation/";
const ANSWER_URL_REDIRECTOR = "/questionanswer/";

export const API_URLS = {
	headerDataURL: jsonFilesURL + "headerData.json",
	menuDataURL: jsonFilesURL + "menubarData.json",
	themeURL: jsonFilesURL + "rebrand.json",

	//1.News and Support Page URLS
	getThemeURL: apiUrl + APP_API_REDIRECTOR + "fetch/rebranding",
	getCompaniesURL: apiUrl + APP_API_REDIRECTOR + "company",
	updateNewsAndSupportURL: apiUrl + APP_API_REDIRECTOR + "newsandsupport",
	submitNewsAndSupportMedia: apiUrl + APP_API_REDIRECTOR + "submit/newsandsupportmedia",
	updateNewsAndSupportMedia: apiUrl + APP_API_REDIRECTOR + "update/newsandsupportmedia",
	deleteNewsAndSupportMedia: apiUrl + APP_API_REDIRECTOR + "delete/newsandsupportmedia",

	//2.Ratings and Reviews Page: 
	getCountrySelectData: apiUrl + APP_API_REDIRECTOR + "brandToLocale",
	productDataMultiURL: apiUrl + APP_API_REDIRECTOR + "multi/productData",
	categoryDataMultiURL: apiUrl + APP_API_REDIRECTOR + "multi/categoryData",
	reviewSourceURL: apiUrl + APP_API_REDIRECTOR + "multi/sourcedata",
	savefilterTemplateURL: apiUrl + APP_API_REDIRECTOR + "savefiltertemplates",
	getModerationCodesForFilterURL: apiUrl + APP_API_REDIRECTOR + "moderatorCodeForFilter",
	getReviewsURL: apiUrl + APP_API_REDIRECTOR + "requestReview",
	savefilterURL: apiUrl + APP_API_REDIRECTOR + "savefilter",
	submitReviewURL: apiUrl + APP_API_REDIRECTOR + "moderateReview",
	getClientResponseURL: apiUrl + APP_API_REDIRECTOR + "preview/client-response",
	submitClientResponseURL: apiUrl + APP_API_REDIRECTOR + "submit/client-response",
	updateClientResponseURL: apiUrl + APP_API_REDIRECTOR + "update/client-response",
	deleteClientResponseURL: apiUrl + APP_API_REDIRECTOR + "delete/client-response",
	submitbadgesurl: apiUrl + APP_API_REDIRECTOR + "assignBadges",
	getBadgeDataUrl: apiUrl + APP_API_REDIRECTOR + "get/badgetemplates",
	getReiewKeywords: apiUrl + APP_API_REDIRECTOR + "automoderation/review/keywords/",

	//3. Dashboard:
	getCampaignNameURL: apiUrl + VOUCHER_API_REDIRECTOR + "getCampaignName",
	dashboardStatsURL: reportingURL + "/dashboard/statistics",
	dashboardExportURL: reportingURL + "/reportgeneration/dashboard/export",

	//4. Product Coverage:
	productCoverageStatsURL: reportingURL + "/productcoverage/statistics",

	//5. Pie Conversion:
	PieConversionStatsURL: reportingURL + "/pieconversion/statistics",
	pieConversionExportURL: reportingURL + "/reportgeneration/pie/export",

	//6. Global Comparison:
	globalComparisionStatsURL: reportingURL + "/globalcamparison/statistics",

	//7. Report Scheduling:
	getReportTemplateDataURL: apiUrl + REPORT_SCHEDULING_API_REDIRECTOR + "showReportingTemplate",
	submitReportTemplateDataURL: apiUrl + REPORT_SCHEDULING_API_REDIRECTOR + "reportingSchedule",
	updateReportTemplateDataURL: apiUrl + REPORT_SCHEDULING_API_REDIRECTOR + "updateReportingTemplate",
	deleteReportTemplateDataURL: apiUrl + REPORT_SCHEDULING_API_REDIRECTOR + "deleteReportingTemplate",

	//8. Platform Status:
	getPlatformStatusURL: apiUrl + APP_API_REDIRECTOR + "platformstatus",

	//9. Mailings:
	pieSetupURL: apiUrl + PIE_API_REDIRECTOR + "piesetup",
	getMailingStatisticsURL: apiUrl + PIE_API_REDIRECTOR + "statistics/mailing",
	getCampaignStatisticsURL: apiUrl + PIE_API_REDIRECTOR + "statistics/campaign",

	//10. Campaigns:
	fetchVoucherCampaignURL: apiUrl + VOUCHER_API_REDIRECTOR + "fetch/vouchercampaign",
	getVoucherCampaignStatisticsURL: apiUrl + VOUCHER_API_REDIRECTOR + "statistics/vouchercampaign",


	//11. Moderation:
	getModerationCountURL: apiUrl + APP_API_REDIRECTOR + "statistics/moderation",
	getLanguageAndCountryDataURL: apiUrl + APP_API_REDIRECTOR + "brandToLocale/languageAndCountry",

	//12. Verification:
	getVerificationStatisticsURL: apiUrl + APP_API_REDIRECTOR + "statistics/verification",

	//13. Voucher Campaigns:
	badgeurl: apiUrl + APP_API_REDIRECTOR + "badgingTemplates",
	deleteVoucherCampaignURL: apiUrl + VOUCHER_API_REDIRECTOR + "delete/vouchercampaign",
	submitVoucherCodeURL: apiUrl + VOUCHER_API_REDIRECTOR + "upload/vouchercode",
	voucherCodeGeneratorURL: apiUrl + VOUCHER_API_REDIRECTOR + "vcgenerator.json",
	changeStatusURL: apiUrl + VOUCHER_API_REDIRECTOR + "update/status/voucherCampaign",
	submitBadgesToVCampaignURL: apiUrl + VOUCHER_API_REDIRECTOR + "add/badge/vouchercampaign",

	//14. Manage Accounts:
	submitUserDataURL: apiUrl + APP_API_REDIRECTOR + "add/user",
	userRolesURL: apiUrl + APP_API_REDIRECTOR + "userRoles",
	getPermissionGroupsURL: apiUrl + APP_API_REDIRECTOR + "permissiongroupfilter",

	//15. Automated Badging:
	submitBadgeTemplateDataURL: apiUrl + APP_API_REDIRECTOR + "automatebadge",
	updateBadgeTemplateDataURL: apiUrl + APP_API_REDIRECTOR + "editbadge",

	//16. Pie Setup:
	updatePieSubmissionFormURL: apiUrl + PIE_API_REDIRECTOR + "pieformsetup",
	testEmailPieURL: apiUrl + PIE_API_REDIRECTOR + "test/piemail",
	pieFeedUploadURL: apiUrl + PIE_API_REDIRECTOR + "piesheettodata",
	changeStatusPIEURL: apiUrl + PIE_API_REDIRECTOR + "update/status/piecampaign",

	//17. Manage Markets: 
	getBrandNamesURL: apiUrl + APP_API_REDIRECTOR + "brand",
	setupMarketURL: apiUrl + APP_API_REDIRECTOR + "setupmarket",
	changeMarketStatusURL: apiUrl + APP_API_REDIRECTOR + "update/status/marketsetup",
	testEmailMarketURL: apiUrl + APP_API_REDIRECTOR + "test/mail",
	pieFeedGererationURL: apiUrl + APP_API_REDIRECTOR + "generateNDFeedsPerMarket",
	getFeedGenerationStatusURL: apiUrl + APP_API_REDIRECTOR + "getFeedGenerationStatus",
	getLanguageAndCountryURL: apiUrl + APP_API_REDIRECTOR + "languageandcountry",

	// 18. Permission Group:
	getModulesURL: apiUrl + APP_API_REDIRECTOR + "modules",
	getPermissionsDataURL: apiUrl + APP_API_REDIRECTOR + "preview/permissiongroup",
	submitPermissionsDataURL: apiUrl + APP_API_REDIRECTOR + "submit/permissiongroup",
	updatePermissionsDataURL: apiUrl + APP_API_REDIRECTOR + "update/permissiongroup",
	deletePermissionsDataURL: apiUrl + APP_API_REDIRECTOR + "delete/permissiongroup",

	//19. Review Upload:

	uploadReviewXlsURL: apiUrl + APP_API_REDIRECTOR + "upload/review",

	//20. Badge Update:

	updateBadgeXlsURL: apiUrl + APP_API_REDIRECTOR + "uploadbadges",

	//21. User Profile Update and change password:
	updateProfileURL: apiUrl + APP_API_REDIRECTOR + "updateProfile",
	changePasswordURL: apiUrl + APP_API_REDIRECTOR + "changePassword",

	//22. Some more application API’s:

	imageUploadURL: apiUrl + APP_API_REDIRECTOR + "uploadProfileImage/",
	getModerationCodesURL: apiUrl + APP_API_REDIRECTOR + "moderatorCode",
	getSearchDataURL: apiUrl + APP_API_REDIRECTOR + "openSearch",
	getSmartDashboardDataURL: apiUrl + REPORTING_API_REDIRECTOR + "reportingUrl",
	loginUserURL: apiUrl + APP_API_REDIRECTOR + "loginAuthenticate",
	productDataURL: apiUrl + APP_API_REDIRECTOR + "productData",
	categoryDataURL: apiUrl + APP_API_REDIRECTOR + "categoryData",
	getStasticsDataURL: apiUrl + REPORTING_API_REDIRECTOR + "reviewStatistics",
	createNewVoucherCampaignURL: apiUrl + VOUCHER_API_REDIRECTOR + "add/vouchercampaign",
	updateVoucherCampaignURL: apiUrl + VOUCHER_API_REDIRECTOR + "update/vouchercampaign",
	ManualSendoutVcURL: apiUrl + VOUCHER_API_REDIRECTOR + "sendmanualvouchercode",
	uploadEmailTemplateURL: apiUrl + VOUCHER_API_REDIRECTOR + "upload/emailtemplate",
	changeThemeURL: apiUrl + APP_API_REDIRECTOR + "rebrand",
	updatePieSetupPriorityURL: apiUrl + PIE_API_REDIRECTOR + "update/priority",
	logoutURL: apiUrl + APP_API_REDIRECTOR + "logout",
	companyNamesURL: apiUrl + APP_API_REDIRECTOR + "campanies",
	getCountriesListURL: apiUrl + APP_API_REDIRECTOR + "countrycode",
	getLanguagesListURL: apiUrl + APP_API_REDIRECTOR + "languagecode",
	sendMailToUserURL: apiUrl + APP_API_REDIRECTOR + "forgotpassword",
	getMarketServiceURL: apiUrl + APP_API_REDIRECTOR + "marketservices",
	changeFeatureStatusURL: apiUrl + APP_API_REDIRECTOR + "submit/setupmarketservices",
	logingAndActivityUrl: apiUrl + "/usertracking/logingandactivity",
	logingAndDeviceinfoUrl: apiUrl + "/usertracking/loginganddeviceinfo",
	userDetailsStatisticsUrl: apiUrl + "/usertracking/userdetailedstatistics",
	userStatisticsExportUrl: apiUrl + "/usertracking/userstatisticsexport",
	userReportStatisticsUrl: apiUrl + "/usertracking/statistics",
	getAndCreateMarketApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "marketapipasskeys",
	previewMarketApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "preview/marketapipasskeys",
	createMarketApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "submit/marketapipasskeys",
	updateMarketApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "update/marketapipasskeys",

	getRetailersURL: apiUrl + APP_API_REDIRECTOR + "getallretailers",
	createAndUpdateRetailersURL: apiUrl + APP_API_REDIRECTOR + "setupretailer",
	getRetailerApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "getretailerapikeys",
	CreateRetailerApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "setupretailerapikeys",
	UpdateRetailerApiPassKeyURL: apiUrl + APP_API_REDIRECTOR + "updateretailerapikey",
	getQuestionForModerationURL: apiUrl + QUESTIONANSWER_URL_REDIRECTOR + "requestquestions",
	moderateQuestionURL: apiUrl + QUESTIONANSWER_URL_REDIRECTOR + "moderatequestion",
	getQuestionCommentResponseURL: apiUrl + QUESTIONANSWER_URL_REDIRECTOR + "preview/client-response",
	submitQuestionCommentResponseURL: apiUrl + QUESTIONANSWER_URL_REDIRECTOR + "submit/client-response",
	welcomepageStatsURL: reportingURL + "/dashboard/welcomepage",
	welcomeNoteURL: reportingURL + "/dashboard/welcomenote",
	saveOrUpdateWelcomeNoteURL: reportingURL + "/dashboard/welcomenote/saveorupdate",
	languages: apiUrl + APP_API_REDIRECTOR + "fetch/languages",
	addLanguage: apiUrl + APP_API_REDIRECTOR + "add/language",
	updateLanguage: apiUrl + APP_API_REDIRECTOR + "update/language",
	deleteLanguage: apiUrl + APP_API_REDIRECTOR + "delete/language",
	languagesTempate: apiUrl + APP_API_REDIRECTOR + "fetch/defaultlanguage",
	getRuleTemplatesUrl: premoderationUrl + MODERATION_URL_REDIRECTOR + "rule/templates",
	getAutoModerationRuleList: premoderationUrl + MODERATION_URL_REDIRECTOR + "rules",
	autoModerationRule: premoderationUrl + MODERATION_URL_REDIRECTOR + "rule",
	previewQuestionanswerResponseURL: apiUrl + ANSWER_URL_REDIRECTOR + "preview/answer",
	submitQuestionanswerResponseURL: apiUrl + ANSWER_URL_REDIRECTOR + "submit/answer",
	updateQuestionanswerResponseURL: apiUrl + ANSWER_URL_REDIRECTOR + "update/answer",
	deleteQuestionanswerResponseURL: apiUrl + ANSWER_URL_REDIRECTOR + "delete/answer",
	getQuestionAttachments: apiUrl + ANSWER_URL_REDIRECTOR + "getattachements/Question",
	getAnswerAttachments: apiUrl + ANSWER_URL_REDIRECTOR + "getattachements/Answer",
	submitQuestion: apiUrl + ANSWER_URL_REDIRECTOR + "data/submit/question",
	getReviewAttachments: apiUrl + "/app/getattachements/Review",
	uploadPhotosUrl: apiUrl + "/app/uploadPhotos",
	uploadVideosUrl: apiUrl + "/app/uploadVideos",
	uploadDocumentsUrl: apiUrl + "/app/uploadDocuments",
	deleteAnswerAttachmentsURL: apiUrl + "/app/deleteattachments",
	previewDynamicRatingTemplate: apiUrl + APP_API_REDIRECTOR + "preview/dynamicratingtemplate",
	submitDynamicRatingTemplate: apiUrl + APP_API_REDIRECTOR + "submit/dynamicratingtemplate",
	updateDynamicRatingTemplate: apiUrl + APP_API_REDIRECTOR + "update/dynamicratingtemplate",
	deleteDynamicRatingTemplate: apiUrl + APP_API_REDIRECTOR + "delete/dynamicratingtemplate",

	migrateReviewsXlsURL: apiUrl + "/background/transferreview",
	updateProductsxmlToDataURL: apiUrl + "/background/xmltodata",
	getProductGenerationStatusURL: apiUrl + APP_API_REDIRECTOR + "getproductgenerationstatus",

	requestProductsURL: apiUrl + APP_API_REDIRECTOR + "requestproducts",
	fetchProductDetailsURL: apiUrl + APP_API_REDIRECTOR + "fetch/productdetails",

	previewCompanydetails: apiUrl + APP_API_REDIRECTOR + "preview/companydetails",
	submitCompanydetails: apiUrl + APP_API_REDIRECTOR + "submit/companydetails",
	updateCompanydetails: apiUrl + APP_API_REDIRECTOR + "update/companydetails",

	aiModerationStastics: apiUrl + APP_API_REDIRECTOR + "aimoderation/statistics",
	aiModerationReviewResults: apiUrl + APP_API_REDIRECTOR + "aimoderation/reviewresults",

	aiModerationStatus: apiUrl + APP_API_REDIRECTOR + "v1/review/",

	getSubscriptionPlans: apiUrl + APP_API_REDIRECTOR + "v1/subscription-plans",
	saveSubscriptionPlan: apiUrl + APP_API_REDIRECTOR + "v1/subscription-plan",
	updateSubscriptionPlanById: apiUrl + APP_API_REDIRECTOR + "v1/subscription-plans/",
	subscriptionActivePlanById: apiUrl + APP_API_REDIRECTOR + "v1/subscription-plans/",
	getSubscriptionFeatures: apiUrl + APP_API_REDIRECTOR + "v1/subscription-features",
};

export const COMMON_HTTP_HEADERS = {
	"Content-Type": "application/json;charset=UTF-8",
	"Access-Control-Allow-Origin": "*",
};

export const FORM_DATA_HTTP_Headers = {
	"Content-Type": "application/x-www-form-urlencoded",
};

