<div class="overlayWrapper PF style-4">
  <div class="editProfileDetailView">
    <div class="row containerHeader"
      [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
      <div class="col-md-offset-1 col-md-9 editProfileName">
        {{ "Change" | translate }} {{ "Password" | translate }}
      </div>
      <div class="col-md-offset-1 col-md-1">
        <span class="closeEditProfile pull-right" (click)="destroyChangePasswordView()">
          <span class="iconContainerSpan spanContainer closeBtnContainer">
            <span class="icon icon-cancel"></span>
          </span>
        </span>
      </div>
    </div>

    <div class="row"></div>
    <div class="row rowMargin">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10 note">
            <strong>{{ "Note" | translate }}:</strong>
            ({{ "Password" | translate }} {{ "should" | translate }} {{ "contain" | translate }} <strong>{{ "atleast" |
              translate }}</strong>
            {{ "One" | translate }} {{ "Uppercase" | translate }} {{ "letter" | translate }},
            {{ "One" | translate }} {{ "Lowercase" | translate }} {{ "letter" | translate }},
            {{ "One" | translate }} {{ "Number" | translate }}. {{ "Minimum" | translate }} {{ "of" | translate }} 6 {{
            "characters" | translate }}.)
            <br />
            <br />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <input class="password" type="password" name=""
              placeholder="{{ 'Old' | translate }} {{ 'Password' | translate }}" [(ngModel)]="changePassword.oldPassword" />
            <div *ngIf="isIncorrectOldPassword" class="isIncorrectOldPassword">
              {{ "Incorrect" | translate }} {{ "Old" | translate }} {{ "Password" | translate }}
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row rowMargin">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <input class="password" type="password" name=""
              placeholder="{{ 'New' | translate }} {{ 'Password' | translate }}" [(ngModel)]="changePassword.newPassword" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row rowMargin">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <input class="password" type="password" name=""
              placeholder="{{ 'Confirm' | translate }} {{ 'Password' | translate }}" [(ngModel)]="changePassword.confirmPassword" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <button type="button" class="btn btn-primary customBtn" [ngStyle]="{
                'background-color': applyThemeService.$themeColor,
                'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor
              }" (click)="onSubmitChangePassword()" [disabled]="commonUtilService.showLoader">
              {{ "BUTTONS.Submit" | translate }}
            </button>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="errorDiv" *ngIf="errorMessage">
  <h1 class="errorMsg">{{ errorMessage | translate }}</h1>
</div>