import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CommonUtilService } from '../../services/common-util.service';
import { SecurityService } from '../../services/security.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  
  constructor(private securityService: SecurityService, 
    private router: Router,
    private translate: TranslateService,
    public commonUtilService: CommonUtilService) {
  }

  ngOnInit() {
    this.commonUtilService.languageSubjectRef$.subscribe((_translation: any) => {
      this.translate.use(_translation);
    });
  }

  navigateToHomepage() {
    const isLoggedIn = this.securityService.checkIfUserLoggedIn();
    if (isLoggedIn) {
      this.router.navigateByUrl("/dashboard");
    } else {
      this.router.navigate(['/login']);
    }
  }
}
