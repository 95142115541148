import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutofocusDirective } from './autoFocus.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { HoverClassDirective } from './hover-class.directive';
import { ScrollDetectorDirective } from './scroll-detector.directive';
import { ValidateNumberDirective } from './only-numbers.directive';
import { BackGroundColorDirective } from './background-color.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';

@NgModule({
  declarations: [
    AutofocusDirective,
    AutoFocusDirective,
    HoverClassDirective,
    ScrollDetectorDirective,
    BackGroundColorDirective,
    ValidateNumberDirective,
    NumbersOnlyDirective
  ],
  imports: [
    CommonModule 
  ],
  exports: [
    AutofocusDirective,
    AutoFocusDirective,
    HoverClassDirective,
    ScrollDetectorDirective,
    BackGroundColorDirective,
    ValidateNumberDirective,
    NumbersOnlyDirective
  ]
})
export class SharedCustomDirective { }
