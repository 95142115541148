<div *ngIf="visibleBatchUploadWindow" class="modalContainer overlayWrapper PF style-4">
    <div class="editProfileDetailView">
        <div class="row containerHeader" [ngStyle]="{
          'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div class="col-md-offset-1 col-md-9 headerName">
                {{ "BUTTONS.Badge" | translate }} {{ "Update" | translate}}
            </div>
            <div class="col-md-offset-1 col-md-1">
                <span class="closeEditProfile pull-right" (click)="onCloseUpdateWindow()">
                    <span class="iconContainerSpan spanContainer closeBtnContainer">
                        <span class="icon icon-cancel"></span>
                    </span>
                </span>
            </div>
        </div>

        <div class="row containerHeader" [ngStyle]="{'background-color': 'white', color: applyThemeService.$textColor}">
            <div class="vertical-space"></div>
            <div class="row">
                <div class="col-md-3 filterlabel">
                    {{"Brand Site" | translate}} :
                </div>
                <div class="col-md-8 filter">
                    <angular2-multiselect [settings]="marketDropdownSettings" [data]="arrMarketList"
                        [(ngModel)]="marketListSelectedItems">
                    </angular2-multiselect>
                </div>
                <div class="col-md-3"></div>
            </div>

            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-8 editProfileName">
                    <button type="button" class="btn btn-default">
                        <a download="Import Dummy" href="/assets/files/import_badge_template.xlsx" target="_blank">
                            {{"BUTTONS.Download" | translate}} {{"Dummy" | translate}} {{"File" | translate}}
                            <span class="icon icon-download"></span>
                        </a>
                    </button>
                </div>
            </div>

            <div class="row">
                <div *ngIf="!isFileValid" class="col-md-8 col-md-offset-3 editProfileName">
                    <div ng2FileDrop (onFileDrop)="onFileDroped($event)" class="dropzone">
                        <span>{{"Click to add or Drop a file here" | translate}} </span>
                        <span class="icon icon-upload"></span>
                        <input type="file" (change)="onSelectFile($event)" class="fileContainer"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                </div>
                <div *ngIf="isFileValid" class="col-md-8 col-md-offset-3 editProfileName">
                    <span class="greenColor">{{"SUCCESS.File has been uploaded successfully" | translate}}!</span>
                    <div>{{"or" | translate}}</div>
                    <button type="button" class="btn btn-primary customBtn retryBtn" [ngStyle]="{
                'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor  }" (click)="reUpload()">
                        {{"BUTTONS.Retry" |translate }}
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3"></div>

                <div class="col-md-8">
                    <button *ngIf="accessControls.isCreate" type="button" class="btn btn-primary customBtn MB-20"
                        [ngStyle]="{
                'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor}" (click)="uploadFile()">
                        {{"BUTTONS.Submit" |translate }}
                    </button>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</div>