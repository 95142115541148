<div *ngIf="visibleParentSpinner" class="spinner1 fadeIn">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div>
  <div class="white_background">
    {{ "Loading" | translate }}...
  </div>
</div>

<div *ngIf="visibleSpinner" class="spinner1 fadeIn">
  <div class="spinner">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
      
  </div>
  <div class="white_background">
      {{ "Loading" | translate }}...
  </div>
</div>