export class MessageModel {
    description: string = "";
    message: string = "";
    title: string = "";
    type: MessageItemType = MessageItemType.None;
    position: string = "";
}
export class MessageItemModel {
    type: MessageItemType = MessageItemType.None;
    message: string = "";
}

export enum MessageItemType {
    None = 0,
    Info = 1,
    Error = 2,
    Warning = 3,
    Success = 4
}

export enum ToasterPosition {
    topRight = 'toast-top-right',
    topLeft = 'toast-top-left',
    bottomRight = 'toast-bottom-right',
    bottomLeft = 'toast-bottom-left',
    // Other positions you would like
}