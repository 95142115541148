import { Subject } from 'rxjs/internal/Subject';

export const COMMON_SINGLE_DROPDOWN_SETTINGS: any = {
  singleSelection: true,
  enableCheckAll: false,
  enableSearchFilter: true,
  selectAllText: '',
  unSelectAllText: '',
  filterSelectAllText: '',
  filterUnSelectAllText: '',
  enableFilterSelectAll: false,
  badgeShowLimit: 1,
  lazyLoading: true,
  maxHeight: 400,
  position: 'bottom',
  text: '',
  searchBy: [],
  classes: 'ddCustomClass',
  labelKey: '',
  searchPlaceholderText: '',
  noDataLabel: '',
  primaryKey: 'id',
};

export const SINGLE_DROPDOWN_SETTINGS = {
  singleSelection: true,
  enableCheckAll: false,
  enableSearchFilter: false,
  badgeShowLimit: 1,
  classes: 'ddCustomClass',
  escapeToClose: true,
};

export const MULTI_DROPDOWN_SETTINGS = {
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
};

export const GLOBAL$ = { UNSUBSCRIBE_REQUEST$: new Subject<void>() };


export enum ServiceName {
	Moderation = 'Moderation',
	Pie = 'PIE',
	ReviewDistribution = 'Review distribution',
	ReviewSubmission = 'Review submission',
	Voucher = 'Voucher',
}