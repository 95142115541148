import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum ModerationCodes4filterActionTypes {
    GetModerationCodes4filterLoad = '[ModerationCodes4filter] Get ModerationCodes4filter',
    GetModerationCodes4filterSuccess = '[ModerationCodes4filter] Get ModerationCodes4filter Success',
    GetModerationCodes4filterFail = '[ModerationCodes4filter] Get ModerationCodes4filter Fail'
}

export class GetModerationCodes4filterLoad implements Action {
    public readonly type = ModerationCodes4filterActionTypes.GetModerationCodes4filterLoad;
    
    constructor(public payload: any) { }
}

export class GetModerationCodes4filterSuccess implements Action {
    public readonly type = ModerationCodes4filterActionTypes.GetModerationCodes4filterSuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetModerationCodes4filterFail implements Action {
    public readonly type = ModerationCodes4filterActionTypes.GetModerationCodes4filterFail;

    constructor(public error: HttpErrorResponse) { }
}


export type ModerationCodes4filterAction = GetModerationCodes4filterLoad | GetModerationCodes4filterSuccess | GetModerationCodes4filterFail;
