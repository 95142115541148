import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutofocusDirective } from './autoFocus.directive';
import { HoverClassDirective } from './hover-class.directive';
import { ScrollDetectorDirective } from './scroll-detector.directive';
import { ValidateNumberDirective } from './only-numbers.directive';
import { BackGroundColorDirective } from './background-color.directive';

@NgModule({
  declarations: [
    AutofocusDirective,
    HoverClassDirective,
    ScrollDetectorDirective,
    BackGroundColorDirective,
    ValidateNumberDirective
  ],
  imports: [
    CommonModule 
  ],
  exports: [
    AutofocusDirective,
    HoverClassDirective,
    ScrollDetectorDirective,
    BackGroundColorDirective,
    ValidateNumberDirective
  ]
})
export class SharedCustomDirective{ }
