import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { One2FiveUtils } from '../core/services/one2five-utils';
import { environment } from 'src/environments/environments';

const apiUrl = environment.apiUrl;

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslationLoader(http);
}

export class TranslationLoader implements TranslateLoader {

  constructor(private httpClient: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
    const currentUser = sessionStorage.getItem("currentUser");
    const passKey = currentUser ? One2FiveUtils.parseString(currentUser).token : "";
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('token', passKey);

    if (lang === 'default' || passKey.length === 0) {
      return this.httpClient.get("./assets/i18n/default.json");
    }

    return this.httpClient.get(`${apiUrl}/app/data/fetch/language/${lang.toUpperCase()}`, { 'headers': headers })
      .pipe(map((response: any) => {
        if (response && response.value) {
          return One2FiveUtils.parseString(response.value);
        } else
          return this.httpClient.get("./assets/i18n/default.json");
      }
      ));
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    TranslateModule
  ]
})

export class CustomTranslationModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CustomTranslationModule
    }
  }
}
