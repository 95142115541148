import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ApplyThemeService } from '../../services/apply-theme.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { MessagesService } from '../messages/messages.service';
import { DataService } from '../../services/data.service';
import { UserProfileModel } from 'src/app/core/models/user-profile.model';
import { getCurrentUser, getUserProfileDetails, One2FiveUtils } from 'src/app/core/services/one2five-utils';
@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnChanges {
  @Input("ready2LoadUpdateEmailWindow") loadUpdateEmailWindow: boolean = false;
  @Output("closeUpdateWindow") closeUpdateWindow = new EventEmitter();

  userProfile = new UserProfileModel;
  errorMessage: string = "";

  constructor(public applyThemeService: ApplyThemeService,
    private dataService: DataService,
    public commonUtilService: CommonUtilService) {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (!this.loadUpdateEmailWindow) return;
    this.errorMessage = "";

    this.userProfile = getUserProfileDetails();
  }

  validateFields(): boolean {
    if (!this.userProfile?.email) return false;

    const regexrEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.userProfile.email.match(regexrEmail)) {
      this.errorMessage = "ERRORS.Please Enter valid email";
      return false;
    }
    return true;
  }

  onSubmitEmail() {
    if (!this.validateFields()) return;

    const dataObject: any = {};
    dataObject["email"] = this.userProfile.email;
    this.dataService.updateProfile(dataObject).subscribe(async (data: any) => {
      if (data["HasErrors"]) {
        this.errorMessage = "ERRORS.Email not updated. You can still go to edit profile to update email";
        this.onCloseWindowClick();
      } else {
        let current_user: any = getCurrentUser();
        current_user.userProfile.email = this.userProfile.email;
        sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_user));
        MessagesService.successAlert("Email updated successfully Thank you");
        this.onCloseWindowClick();
      }
    });
  }

  onCloseWindowClick() {
    this.loadUpdateEmailWindow = !this.loadUpdateEmailWindow;
  }

}
