import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonUtilService } from './core/services/common-util.service';
import { AccessControlService } from './core/services/access-control.service';
import { SecurityService } from './core/services/security.service';
import { ApplyThemeService } from './shared/services/apply-theme.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private translateService: TranslateService,
    public commonUtilService: CommonUtilService,
    private securityService: SecurityService,
    public accessControlService: AccessControlService,
    public applyThemeService: ApplyThemeService) {
  }

  ngOnInit(): void {
    if (this.securityService.checkIfUserLoggedIn()) {
      this.accessControlService.isLoggedIn = true;
    }
    const lang: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    this.commonUtilService.changeLocale(lang);
    this.translateService.use(lang);
  }

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(_event: any) {
    const locale: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    localStorage.clear();
    this.commonUtilService.changeLocale(locale);
    sessionStorage.clear();
  }

}
