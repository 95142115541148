import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ThemeTemplateModel } from "../models/theme.model";
import { ConstantsService } from "./constants.service";
import { StateManagementService } from "./state.service";
import { One2FiveUtils, isEmptyObject } from "src/app/core/services/one2five-utils";
import { One2FiveLoadingService } from "../components/app-loader/loader.service";
import { MessagesService } from "../components/messages/messages.service";

@Injectable({ providedIn: "root" })
export class ApplyThemeService {
  themeTemplate: ThemeTemplateModel = new ThemeTemplateModel;

  constructor(private stateService: StateManagementService) {
    this.themeTemplate.themeColor = ConstantsService._defaultThemeColor;
    this.themeTemplate.secondaryColor = ConstantsService._defaultSecondaryColor;
    this.themeTemplate.textColor = ConstantsService._defaultTextColor;
    this.themeTemplate.link = ConstantsService._defaultLink;
    this.themeTemplate.documentTitle = ConstantsService._defaultDocumentTitle;
  }
  public get $applyTheme(): boolean {
    return this.themeTemplate.isThemeFound;
  }
  public get $textColor(): string {
    return this.themeTemplate.textColor;
  }
  public get $themeColor(): string {
    return this.themeTemplate.themeColor;
  }
  public get $secondaryColor(): string {
    return this.themeTemplate.secondaryColor;
  }
  public get $hoverEffectColor(): string {
    return this.themeTemplate.hoverEffectColor;
  }
  public get $documentTitle(): string {
    return this.themeTemplate.documentTitle;
  }
  public get $link(): any {
    return this.themeTemplate.link;
  }
  public get $copyrightText(): string {
    return this.themeTemplate.copyrightText;
  }
  public get $welcomeText(): string {
    return this.themeTemplate.welcomeText;
  }
  public get $WelcomeTitle(): string {
    return this.themeTemplate.WelcomeTitle;
  }
  public get $privacyStatement(): string {
    return this.themeTemplate.privacyStatement;
  }
  public get $aboutUs(): string {
    return this.themeTemplate.aboutUs;
  }
  public get $largeLogo(): string {
    return this.themeTemplate.largeLogo;
  }
  public get $smallLogo(): string {
    return this.themeTemplate.smallLogo;
  }
  public get $favicon(): string {
    return this.themeTemplate.favicon;
  }

  private _loadNewTheme: Subject<any> = new BehaviorSubject<any>(null);
  loadNewTheme$: Observable<any> = this._loadNewTheme.asObservable();
  public loadNewTheme(_newTheme: ThemeTemplateModel) {
    this._loadNewTheme.next(_newTheme);
  }

  loadThemeData() {
    let me = this;
    me.themeTemplate.isThemeFound = false;
    One2FiveLoadingService.show();
    me.stateService.getThemeData().subscribe((data: any) => {
      if (isEmptyObject(data)) return;

      me.themeTemplate = One2FiveUtils.objectClone(data);
      me.themeTemplate.copyrightText = data.copyrightLabel;
      me.themeTemplate.WelcomeTitle = data.welcomeTitle;

      me.themeTemplate.largeLogo = me.themeTemplate.largeLogo ?? "https://s3.eu-central-1.amazonaws.com/one2five-prod/content_themes/MSM_Digital/largelogo.png";
      me.themeTemplate.favicon = me.themeTemplate.favicon ?? "https://s3.eu-central-1.amazonaws.com/one2five-prod/content_themes/MSM_Digital/favicon.png";

      me.themeTemplate.link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      me.themeTemplate.link.type = "image/x-icon";
      me.themeTemplate.link.rel = "icon";
      me.themeTemplate.link.href = me.themeTemplate.favicon;

      if (me.themeTemplate.link) {
        document.getElementsByTagName("head")[0].appendChild(me.themeTemplate.link);
      }
      if (me.themeTemplate.documentTitle) {
        document.title = me.themeTemplate.documentTitle;
      }
      me.themeTemplate.isThemeFound = true;
      me.loadNewTheme(me.themeTemplate);
      One2FiveLoadingService.hide();
    }, (_error: any) => {
      MessagesService.errorAlert(_error);
      One2FiveLoadingService.hide();
      me.themeTemplate.isThemeFound = true;
    });
  }

}
