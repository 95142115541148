import { Directive, ElementRef, HostListener, Input, Output, NgZone, EventEmitter, OnInit } from "@angular/core";

@Directive({
  selector: "[appScrollDetector]"
})
export class ScrollDetectorDirective implements OnInit {
  @Output() reachedToThreshold = new EventEmitter<boolean>();
  @Input() set goToTopBtn(_value: boolean) {
    this.el.nativeElement.scrollTop = 0;
  }

  @HostListener("scroll") scrollDetection() {
    if (this.el.nativeElement.scrollTop > 300) {
      this.reachedToThreshold.emit(true);
    } else {
      this.reachedToThreshold.emit(false);
    }
  }

  constructor(private el: ElementRef, 
    private zone: NgZone) { }

  ngOnInit() {
    this.zone.runOutsideAngular(() => { });
  }

}
