import { Component, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Subscription, lastValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';

import { delay } from "src/app/core/services/one2five-utils";
import { One2FiveConstant } from "../../models/referance.model";
import { MessageItemType, MessageModel } from "./message.model";
import { MessagesService } from "./messages.service";
import { ApplyThemeService } from "../../services/apply-theme.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";
@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent {
  @ViewChild("alertModalRef", { static: false }) alertModalRef!: ElementRef;
  themeColor = "#03A9F4";
  textColor = "#ffffff";
  secondaryColor = "#006694";
  isVisible: boolean = false;
  alertDisplayStyle: string = One2FiveConstant.none;
  confirmDisplayStyle: string = One2FiveConstant.none;

  messageModel: MessageModel = new MessageModel;
  private subscriptions: Subscription[] = [];

  visibleSuccessAlert: boolean = false;
  visibleErrorAlert: boolean = false;
  visibleFailureAlert: boolean = false;
  visiblePopupError: boolean = false;
  successAlert: string = "";
  errorAlert: string = "";
  failureAlert: string = "";
  popupAlert: string = "";

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.confirmDisplayStyle === One2FiveConstant.block) {
      this.confirmDisplayStyle = One2FiveConstant.none;
      MessagesService.confirmation(false);
    }
    if (this.alertDisplayStyle === One2FiveConstant.block) {
      this.alertDisplayStyle = One2FiveConstant.none;
    }
  }

  constructor(private translate: TranslateService,
    public applyThemeService: ApplyThemeService,
    public commonUtilService: CommonUtilService,
    private toastr: ToastrService) {
    this.subscriptions.push(MessagesService.alert$.subscribe(_ => this.alert(_)));
    this.subscriptions.push(MessagesService.confirm$.subscribe(_ => this.openConfirmationWindow(_)));
    this.subscriptions.push(MessagesService.close$.subscribe(_ => this.onCloseAlert()));
    this.subscriptions.push(MessagesService.notify$.subscribe(_ => this.showToastMessage(_.message, _.type)));
    this.subscriptions.push(MessagesService.errorMessage$.subscribe(_ => this.errorMessage(_)));
    this.subscriptions.push(MessagesService.showFailureAlert$.subscribe(_ => { this.showFailureAlert(_) }));
    this.subscriptions.push(MessagesService._successAlert$.subscribe(_ => this.showSuccessAlertMessage(_)));
    this.subscriptions.push(MessagesService._errorAlert$.subscribe(_ => this.showErrorAlertMessage(_)));
    this.subscriptions.push(MessagesService.hideAlertMessages$.subscribe(_ => { this.hideAlertMessages(_) }));
    this.subscriptions.push(MessagesService.popupAlertAlertMessages$.subscribe(_ => { this.showPopupAlertMessages(_) }));
    this.subscriptions.push(MessagesService.successDetailsMessages$.subscribe(_ => { this.successDetailsMessages(_) }));
  }

  private async showSuccessAlertMessage(_message: string) {
    if (!_message) return;
    this.successAlert = await this.transalateInputText(_message);
    this.visibleSuccessAlert = true;
    await delay(5000);
    this.visibleSuccessAlert = false;
  }
  private async showErrorAlertMessage(_message: string) {
    if (!_message) return;
    this.errorAlert = await this.transalateInputText(_message);
    this.visibleErrorAlert = true;
    await delay(5000);
    this.visibleErrorAlert = false;
  }
  private async showFailureAlert(_message: string) {
    if (!_message) return;
    this.failureAlert = await this.transalateInputText(_message);
    this.visibleFailureAlert = true;
    await delay(5000);
    this.visibleFailureAlert = false;
  }
  private async showPopupAlertMessages(_message: string) {
    if (!_message) return;
    this.popupAlert = await this.transalateInputText(_message);
    this.visiblePopupError = true;
    await delay(5000);
    this.visiblePopupError = false;
  }

  private hideAlertMessages(_isHide: boolean) {
    this.visibleFailureAlert = false;
    this.visibleSuccessAlert = false;
    this.visibleErrorAlert = false;
  }

  async transalateInputText(inputText: string): Promise<string> {
    let translatedMessage: any = await lastValueFrom(this.translate.get(inputText));
    return translatedMessage;
  }

  private async alert(_messageModel: MessageModel) {
    _messageModel.message = await this.transalateInputText(_messageModel.message);
    this.messageModel = _messageModel;
    this.alertDisplayStyle = One2FiveConstant.block;
  }

  private openConfirmationWindow(_messageModel: MessageModel) {
    this.confirmDisplayStyle = One2FiveConstant.block;
    this.messageModel = _messageModel;
  }

  onCancelConfirmationWindow() {
    this.confirmDisplayStyle = One2FiveConstant.none;
    MessagesService.confirmation(false);
  }

  onYesConfirmation() {
    this.confirmDisplayStyle = One2FiveConstant.none;
    MessagesService.confirmation(true);
  }

  onCloseAlert() {
    this.alertDisplayStyle = One2FiveConstant.none;
  }

  public async showToastMessage(message: string, type: MessageItemType) {
    message = await this.transalateInputText(message);
    this.toastr.clear();
    switch (type) {
      case MessageItemType.Success:
        this.toastr.success(message);
        break;
      case MessageItemType.Warning:
        this.toastr.warning(message);
        break;
      case MessageItemType.Error:
        this.toastr.error(message);
        break;
      default:
        this.toastr.info(message);
        break;
    }
  }

  private async errorMessage(message: string) {
    this.messageModel.message = await this.transalateInputText(message);
  }

  visibleSuccessMessaage: boolean = false;
  messages: string[] = [];
  successDetailsMessages(_messages : string[]) {
    this.messages = _messages;
    this.visibleSuccessMessaage = true;
  }
  onCloseMessageAlert() {
    this.visibleSuccessMessaage = false;
  }

}