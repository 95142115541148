import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum CampaignActionTypes {
    GetCampaignLoad = '[Campaign] Get Campaign',
    GetCampaignSuccess = '[Campaign] Get Campaign Success',
    GetCampaignFail = '[Campaign] Get Campaign Fail'
}

export class GetCampaignLoad implements Action {
    public readonly type = CampaignActionTypes.GetCampaignLoad;
    
    constructor(public payload: any) { }
}

export class GetCampaignSuccess implements Action {
    public readonly type = CampaignActionTypes.GetCampaignSuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetCampaignFail implements Action {
    public readonly type = CampaignActionTypes.GetCampaignFail;

    constructor(public error: HttpErrorResponse) { }
}


export type CampaignAction = GetCampaignLoad | GetCampaignSuccess | GetCampaignFail;
