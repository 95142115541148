import { Component, OnInit } from "@angular/core";

import { One2FiveLoadingService } from "./loader.service";
@Component({
  selector: "app-one2five-loader",
  templateUrl: "./one2five.loader.html",
  styleUrls: ["./one2five.loader.scss"],
})
export class One2FiveLoaderComponent implements OnInit {
  visibleParentSpinner: boolean = false;
  visibleSpinner: boolean = false;

  ngOnInit(): void {
    One2FiveLoadingService.showLoadingSpinner$.subscribe(_ => this.visibleSpinner = _);
    One2FiveLoadingService.showParentLoadingSpinner$.subscribe(_ => this.visibleParentSpinner = _);
  }

}
