import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { BrandToLocalModel } from '../models/datasource.models';

export enum BrandActionTypes {
    GetBrandLoad = '[Brand] Get Brand',
    GetBrandSuccess = '[Brand] Get Brand Success',
    GetBrandFail = '[Brand] Get Brand Fail'
}

export class GetBrandLoad implements Action {
    public readonly type = BrandActionTypes.GetBrandLoad;
    
    constructor(public payload: any) { }
}

export class GetBrandSuccess implements Action {
    public readonly type = BrandActionTypes.GetBrandSuccess;

    constructor(public payload: BrandToLocalModel[]) { }
}

export class GetBrandFail implements Action {
    public readonly type = BrandActionTypes.GetBrandFail;

    constructor(public error: HttpErrorResponse) { }
}


export type BrandAction = GetBrandLoad | GetBrandSuccess | GetBrandFail;
