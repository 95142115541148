
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { IState } from "./../models/state.model";

const getSourceState = createFeatureSelector<IState>('sources');
export const allSources = createSelector(getSourceState, (state: IState) => {
    return state;
});

const getBrand2LOcalState = createFeatureSelector<IState>('brand2Local');
export const brand2Local = createSelector(getBrand2LOcalState, (state: IState) => {
    return state;
});

const getProductDataState = createFeatureSelector<IState>('productData');
export const productData = createSelector(getProductDataState, (state: IState) => {
    return state;
});

const getCategoryDataState = createFeatureSelector<IState>('categoryData');
export const categoryData = createSelector(getCategoryDataState, (state: IState) => {
    return state;
});

const getCampaignState = createFeatureSelector<IState>('campaigns');
export const campaigns = createSelector(getCampaignState, (state: IState) => {
    return state;
});

const getsavedFiltersState = createFeatureSelector<IState>('savedFilters');
export const savedFilters = createSelector(getsavedFiltersState, (state: IState) => {
    return state;
});

const getModerationCodes4filterState = createFeatureSelector<IState>('moderationCodes4filter');
export const moderationCodes4filter = createSelector(getModerationCodes4filterState, (state: IState) => {
    return state;
});

const badgeTemplatesReducer = createFeatureSelector<IState>('badgeTemplates');
export const badgeTemplates = createSelector(badgeTemplatesReducer, (state: IState) => {
    return state;
});

const themeDataReducer = createFeatureSelector<IState>('themeData');
export const themeData = createSelector(themeDataReducer, (state: IState) => {
    return state;
});

// filter reduces
// export const ReviewfilterSources = createSelector(getSourceState, (state: IState) => {
//     const sources = state.data.slice(0, 10);
//     return { ...state, data: sources };
// });
