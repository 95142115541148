import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IIdItemNameCodePair } from '../models/datasource.models';

export enum UserActionTypes {
    GetSourceLoad = '[Source] Get Source',
    GetSourceSuccess = '[Source] Get Source Success',
    GetSourceFail = '[Source] Get Source Fail'
}

export class GetSourceLoad implements Action {
    public readonly type = UserActionTypes.GetSourceLoad;
    
    constructor(public payload: any) { }
}

export class GetSourceSuccess implements Action {
    public readonly type = UserActionTypes.GetSourceSuccess;

    constructor(public payload: IIdItemNameCodePair[]) { }
}

export class GetSourceFail implements Action {
    public readonly type = UserActionTypes.GetSourceFail;

    constructor(public error: HttpErrorResponse) { }
}


export type SourceAction = GetSourceLoad | GetSourceSuccess | GetSourceFail;
