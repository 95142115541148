import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { One2FiveUtils } from './one2five-utils';
import { CommonUtilService } from './common-util.service';
import { AccessControlService } from './access-control.service';
import { CurrentUser } from '../models/user-profile.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { One2FiveLoadingService } from 'src/app/shared/components/app-loader/loader.service';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	constructor(private router: Router,
        private authenticationService: AuthenticationService,
		private accessControlService: AccessControlService,
		private commonUtilService: CommonUtilService) {
		this.listenToLogoutuser();
	}

	listenToLogoutuser() {
		this.commonUtilService.logoutUser.subscribe(() => this.logoutUser());
	}

	checkIfUserLoggedIn() {
		return !!sessionStorage.getItem('currentUser');
	}

	getUserDetails() {
		return One2FiveUtils.parseString(sessionStorage.getItem('currentUser')) as CurrentUser;
	}

	logoutUser() {
		One2FiveLoadingService.show();
		this.authenticationService.logoutUser().subscribe(
			() => this.checkInternalOrExternalLogin(),
			() => this.checkInternalOrExternalLogin()
		);
	}

	checkInternalOrExternalLogin() {
		const currentUserSessionStorage = sessionStorage.getItem('currentUser');
		const currentUser = One2FiveUtils.parseString(currentUserSessionStorage) as CurrentUser;
		this.clearDataOnLogout();
		currentUser && currentUser.isExternalLogin !== false
			? this.logoutForExternalLogin(currentUser)
			: this.logoutForInternalLogin();
	}

	logoutForInternalLogin() {
		this.router.navigate(['/login']);
	}

	logoutForExternalLogin(currentUser: CurrentUser) {
		const historyURL = (currentUser.isExternalLogin as string).split('?')[0];
		window.location.href = historyURL;
	}

	clearDataOnLogout() {
		sessionStorage.clear();
		const locale: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
		localStorage.clear();
		localStorage.setItem("currentLanguage", locale.toLowerCase());
		this.accessControlService.isLoggedIn = false;
		this.accessControlService.permissionGroup = null;
		One2FiveLoadingService.hide();
	}

}
