import { Directive, Input, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[appHoverClass]",
})
export class HoverClassDirective {
  @Input() hoverColor: string = "";
  @Input() themeColor: string = "";
  @Input() selectedItem: string = "";
  nativeElement: any;

  constructor(private el: ElementRef) {
    this.nativeElement = this.el.nativeElement;
  }

  @HostListener("mouseover") mouseover() {
    if (this.selectedItem === this.nativeElement.title) {
      this.nativeElement.style.backgroundColor = this.hoverColor;
    } else {
      this.nativeElement.style.backgroundColor = this.hoverColor + "80";
    }
  }

  @HostListener("mouseout") mouseout() {
    if (this.selectedItem && this.selectedItem === this.nativeElement.title) {
      this.nativeElement.style.backgroundColor = this.hoverColor;
    } else {
      this.nativeElement.style.backgroundColor = this.themeColor;
    }
  }

}
