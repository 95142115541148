import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum BadgeTemplateActionTypes {
    GetBadgeTemplateLoad = '[BadgeTemplate] Get BadgeTemplate',
    GetBadgeTemplateSuccess = '[BadgeTemplate] Get BadgeTemplate Success',
    GetBadgeTemplateFail = '[BadgeTemplate] Get BadgeTemplate Fail'
}

export class GetBadgeTemplateLoad implements Action {
    public readonly type = BadgeTemplateActionTypes.GetBadgeTemplateLoad;
    
    constructor(public payload: any) { }
}

export class GetBadgeTemplateSuccess implements Action {
    public readonly type = BadgeTemplateActionTypes.GetBadgeTemplateSuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetBadgeTemplateFail implements Action {
    public readonly type = BadgeTemplateActionTypes.GetBadgeTemplateFail;

    constructor(public error: HttpErrorResponse) { }
}


export type BadgeTemplateAction = GetBadgeTemplateLoad | GetBadgeTemplateSuccess | GetBadgeTemplateFail;
