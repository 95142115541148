import { Directive, Input, ElementRef, OnInit } from "@angular/core";

import { One2FiveConstant } from "../models/referance.model";

@Directive({
  selector: "[backgroundColor]",
})
export class BackGroundColorDirective implements OnInit {
  @Input("aiStatus") aiStatus: string = "";
  @Input("actualStatus") actualStatus: string = "";

  constructor(private el: ElementRef) {}
  
  ngOnInit() {
    if(this.aiStatus.toUpperCase() == One2FiveConstant.Approved.toUpperCase() && this.aiStatus.toUpperCase() ==  this.actualStatus.toUpperCase()) {
      this.el.nativeElement.style.backgroundColor = "lightgreen";
    } else if(this.aiStatus.toUpperCase() == One2FiveConstant.Rejected.toUpperCase() && this.aiStatus.toUpperCase() ==  this.actualStatus.toUpperCase()) {
      this.el.nativeElement.style.backgroundColor = "lightgreen";
    } else if(this.aiStatus.toUpperCase() == One2FiveConstant.Approved.toUpperCase() && this.actualStatus.toUpperCase() ==  One2FiveConstant.Rejected.toUpperCase()) {
      this.el.nativeElement.style.backgroundColor = "#ec7272";
    } else if(this.actualStatus.toUpperCase() == One2FiveConstant.Approved.toUpperCase() && this.aiStatus.toUpperCase() ==  One2FiveConstant.Rejected.toUpperCase()) {
      this.el.nativeElement.style.backgroundColor = "#ec7272";
    } else {
      this.el.nativeElement.style.backgroundColor = "lightgray";
    }
  }

}
