import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IIdItemNameCodePair } from '../models/datasource.models';

export enum SavedFiltersActionTypes {
    GetSavedFiltersLoad = '[SavedFilters] Get SavedFilters',
    GetSavedFiltersSuccess = '[SavedFilters] Get SavedFilters Success',
    GetSavedFiltersFail = '[SavedFilters] Get SavedFilters Fail'
}

export class GetSavedFiltersLoad implements Action {
    public readonly type = SavedFiltersActionTypes.GetSavedFiltersLoad;
    
    constructor(public payload: any) { }
}

export class GetSavedFiltersSuccess implements Action {
    public readonly type = SavedFiltersActionTypes.GetSavedFiltersSuccess;

    constructor(public payload: IIdItemNameCodePair[]) { }
}

export class GetSavedFiltersFail implements Action {
    public readonly type = SavedFiltersActionTypes.GetSavedFiltersFail;

    constructor(public error: HttpErrorResponse) { }
}


export type SavedFiltersAction = GetSavedFiltersLoad | GetSavedFiltersSuccess | GetSavedFiltersFail;
