export const navigationItems = [
    "manageAccounts",
    "automatedBadging",
    "voucherCampaigns",
    "pieSetup",
    "autoModeration",
    "manageMarkets",
    "manageRetailers",
    "managePermissions",
    "languageSetup",
    "activity-mailing/voucher-campaigns",
    "activity-mailing/mailing",
    "activity-mailing/campaigns",
    "activity-mailing/moderation-verification-stats",
    "activity-mailing/userReporting",
    "review-management/services",
    "review-management/products"
]

export const navigation = {
    manageaccounts: "manageAccounts",
    automatedbadging: "automatedBadging",
    campaignlist: "voucherCampaigns",
    piesetup: "pieSetup",
    automoderation: "autoModeration",
    managemarkets: "manageMarkets",
    manageRetailers: "manageRetailers",
    managePermissions: "managePermissions",
    languageSetup: "multiLanguageSetup",

    voucherCampaigns: "activity-mailing/voucher-campaigns",
    mailing: "activity-mailing/mailing",
    campaigns: "activity-mailing/campaigns",
    moderationVerificationStats: "activity-mailing/moderation-verification-stats",
    statisticsReport: "activity-mailing/statisticsReport",

    services: "review-management/services",
    products: "review-management/products"
}

export const MenuItemUrlsConstants = {
    dashboard: "dashboard",

    questionModeration: "questionModeration",
    productOverview: "product-management/products",
    serviceOverview: "product-management/services",
    reviewManagementServices: "review-management/services",
    reviewManagementProducts: "review-management/products",

    activityMailingCampaigns: "activity-mailing/campaigns",
    activityMailing: "activity-mailing/mailing",
    activityMailingVoucherCampaigns: "activity-mailing/voucher-campaigns",
    activityMailingModerationVerification: "activity-mailing/moderation-verification-stats",
    activityMailingUserReporting: "activity-mailing/userReporting",
    activityMailingModerationStatistics: "activity-mailing/moderationStatistics",

    settingsVoucherCampaigns: "settings/voucherCampaigns",
    settingsManageAccount: "settings/manageAccount",
    settingsAutomatedBadging: "settings/automatedBadging",
    settingsPieSetup: "settings/pieSetup",
    settingsAutoModeration: "settings/autoModeration",
    settingsManageMarket: "settings/manageMarket",
    settingsManageRetailers: "settings/manageRetailers",
    settingsManagePermissions: "settings/managePermissions",
    settingsMultiLanguageSetup: "settings/multiLanguageSetup"
}

export const menuItemsUrl = {
    settingsPages: [
        "settings/voucherCampaigns",
        "settings/manageAccount",
        "settings/automatedBadging",
        "settings/pieSetup",
        "settings/autoModeration",
        "settings/manageMarket",
        "settings/manageRetailers",
        "settings/managePermissions",
        "settings/multiLanguageSetup"
    ],
    statisticsPages: [
        "activity-mailing/voucher-campaigns",
        "activity-mailing/mailing",
        "activity-mailing/campaigns",
        "activity-mailing/moderation-verification-stats",
        "activity-mailing/userReporting",
        "activity-mailing/moderationStatistics"
    ],
    reviewManagementPages: [
        "review-management/products",
        "review-management/services"
    ],
    productManagementPages: [
        "product-management/products",
        "product-management/services"
    ]
}