
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Store, select } from "@ngrx/store";
import * as fromActions from "src/app/shared/store/index";

import { environment } from "src/environments/environments";
import { One2FiveUtils, getCompanyName, getHeaderPayload, getToken } from "src/app/core/services/one2five-utils";

@Injectable({ providedIn: "root" })
export class StateManagementService {
    baseUrl: string = environment.apiUrl;

    constructor(private _store: Store<fromActions.IState>) {
        this.clearStateStorage();
    }

    private clearStateStorage() {
        localStorage.setItem("reviewSourcesPayload", "");
        localStorage.setItem("selectedCompanies", "");
        localStorage.setItem("productDataPayload", "");
        localStorage.setItem("categoryDataPayload", "");
        localStorage.setItem("campaignPayload", "");
        localStorage.setItem("savedFiltersPayload", "");
        localStorage.setItem("moderationCodesForFilterPayload", "");
        localStorage.setItem("badgeTemplatesPayload", "");
        localStorage.setItem("themeDataPayload", "");
        localStorage.setItem("permissionDataPayload", "");
        localStorage.setItem("permissionGroupPayload", "");
    }

    // state management 
    getReviewSources(): Observable<any> {
        const headers = getHeaderPayload();
        if (One2FiveUtils.stringifyObject(headers) !== localStorage.getItem("reviewSourcesPayload")) {
            localStorage.setItem("reviewSourcesPayload", One2FiveUtils.stringifyObject(headers));
            this._store.dispatch(new fromActions.GetSourceLoad({
                isCancellable: true, showLoader: true,
                headers: headers
            }));
        }

        return this._store.pipe(select(fromActions.allSources)).pipe(map((res: any) => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("reviewSourcesPayload", "");
            return data;
        }));
    }

    getCountrySelect(selectedCompanies: any = null, _brandNames: string[] = []): Observable<any> {

        const companyName = selectedCompanies ? selectedCompanies.toString() : getCompanyName();
        let headerstore = { company: companyName, brandNames: _brandNames.toString() };

        if (One2FiveUtils.stringifyObject(headerstore) !== localStorage.getItem("selectedCompanies")) {
            localStorage.setItem("selectedCompanies", One2FiveUtils.stringifyObject(headerstore));
            this._store.dispatch(new fromActions.GetBrandLoad({
                isCancellable: true, showLoader: true,
                headers: { token: getToken(), company: companyName, brandNames: _brandNames.toString() }
            }));
        }

        return this._store.pipe(select(fromActions.brand2Local)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("selectedCompanies", "");
            return data;
        }));
    }

    getProductData(categoryId: any, regionName: any, hideLoaderStatus?: boolean, productType: string = "Product"): Observable<any> {
        const inputPayload = { categoryName: categoryId, regionName, productType };
        let data: any = { ...inputPayload, ...getHeaderPayload() };

        if (One2FiveUtils.stringifyObject(data) !== localStorage.getItem("productDataPayload")) {
            localStorage.setItem("productDataPayload", One2FiveUtils.stringifyObject(data));
            this._store.dispatch(new fromActions.GetProductLoad({
                isCancellable: true,
                data: data,
                showLoader: hideLoaderStatus ? false : true,
                isAddParams: true
            }));
        }

        return this._store.pipe(select(fromActions.productData)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("productDataPayload", "");
            return data;
        }));
    }

    getCategoryData(regionName: any, hideLoaderStatus?: boolean, reviewType: string = "Product"): Observable<any> {
        const inputPayload = { regionName, reviewType };
        let data: any = { ...inputPayload, ...getHeaderPayload() };

        if (One2FiveUtils.stringifyObject(data) !== localStorage.getItem("categoryDataPayload")) {
            localStorage.setItem("categoryDataPayload", One2FiveUtils.stringifyObject(data));
            this._store.dispatch(new fromActions.GetCategoryLoad({
                isCancellable: true,
                data: data,
                showLoader: hideLoaderStatus ? false : true,
                isAddParams: true
            }));
        }

        return this._store.pipe(select(fromActions.categoryData)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("categoryDataPayload", "");
            return data;
        }));
    }

    getCampaignNameData(regionName: string[], hideLoaderStatus?: boolean): Observable<any> {
        const inputPayload = { regionNameList: regionName ? regionName : [] };
        let data: any = { ...inputPayload, ...getHeaderPayload() };
        if (One2FiveUtils.stringifyObject(data) !== localStorage.getItem("campaignPayload")) {
            localStorage.setItem("campaignPayload", One2FiveUtils.stringifyObject(data));
            this._store.dispatch(new fromActions.GetCampaignLoad({
                isCancellable: true,
                data: data,
                showLoader: hideLoaderStatus ? false : true,
                isAddParams: true
            }));
        }

        return this._store.pipe(select(fromActions.campaigns)).pipe(map(res => {
            let responseData: any = res.data;
            if (responseData.HasErrors) {
                responseData = null;
                localStorage.setItem("campaignPayload", "");
            }
            return responseData;
        }));
    }

    fetchSavedTemplates(): Observable<any> {
        const headers = getHeaderPayload();
        if (One2FiveUtils.stringifyObject(headers) !== localStorage.getItem("savedFiltersPayload")) {
            this._store.dispatch(new fromActions.GetSavedFiltersLoad({
                isCancellable: true, showLoader: true,
                headers: headers
            }));
        }

        return this._store.pipe(select(fromActions.savedFilters)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("savedFiltersPayload", "");
            return data;
        }));
    }

    getModerationCodesForFilter(type: string, hideLoaderStatus?: boolean): Observable<any> {
        const data = { ...getHeaderPayload(), ...{ type }};
        if (One2FiveUtils.stringifyObject(data) !== localStorage.getItem("moderationCodesForFilterPayload")) {
            localStorage.setItem("moderationCodesForFilterPayload", One2FiveUtils.stringifyObject(data));
            this._store.dispatch(new fromActions.GetModerationCodes4filterLoad({
                isCancellable: true,
                data: data,
                showLoader: hideLoaderStatus ? false : true,
                isAddParams: true
            }));
        }

        return this._store.pipe(select(fromActions.moderationCodes4filter)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("moderationCodesForFilterPayload", "");
            return data;
        }));
    }

    getBadgeTemplates(contentType: string, _hideLoaderStatus?: boolean): Observable<any> {
        const headers = {
            token: getToken(),
            contentType: contentType
        }

        if (One2FiveUtils.stringifyObject(headers) !== localStorage.getItem("badgeTemplatesPayload")) {
            localStorage.setItem("badgeTemplatesPayload", One2FiveUtils.stringifyObject(headers));
            this._store.dispatch(new fromActions.GetBadgeTemplateLoad({
                isCancellable: true, showLoader: true,
                headers: headers
            }));
        }

        return this._store.pipe(select(fromActions.badgeTemplates)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("badgeTemplatesPayload", "");
            return data;
        }));
    }

    getThemeData(isDefault: boolean = false): Observable<any> {
        let company = getCompanyName();
        const headers = isDefault ? { Company: company, isDefault: isDefault + "" } : { Company: company };

        if (One2FiveUtils.stringifyObject(headers) != localStorage.getItem("themeDataPayload")) {
            localStorage.setItem("themeDataPayload", One2FiveUtils.stringifyObject(headers));
            this._store.dispatch(new fromActions.GetThemeDataLoad({
                isCancellable: false,
                showLoader: true,
                headers: headers
            }));
        }

        return this._store.pipe(select(fromActions.themeData)).pipe(map(res => {
            const data: any = res.data;
            if (data.HasErrors) localStorage.setItem("themeDataPayload", "");
            return data.theme ? data.theme : {};
        }));
    }

}