import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-partial-star-rating',
  templateUrl: './partial-star-rating.component.html',
  styleUrls: ['./partial-star-rating.component.scss'],
})
export class PartialStarRatingComponent implements OnChanges {
  @Input() fontSize = 50;
  @Input() color: string = "";
  @Input() rating: number = 0;
  ratingPercent: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes[this.rating]) {
      this.convertRatingToPercent();
    }
  }

  convertRatingToPercent() {
    this.ratingPercent = this.rating * 20;
  }
  
}
