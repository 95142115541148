import { GeneralAPIResponse } from "src/app/shared/models/api-response.model";

export interface PermissionGroupResponse extends GeneralAPIResponse {
	permissionGroup: PermissionGroup;
	Locale: null;
	TypicalHoursToPost: number;
	Submissionid: null;
}

export interface PermissionGroup {
	permissionGroupId: number;
	permissionGroupName: string;
	userRole: string;
	modules: PermissionModule[];
}

export interface PermissionModule {
	moduleName: string;
	isCreate: boolean;
	isRead: boolean;
	isUpdate: boolean;
	isDelete: boolean;
}

export class AccessControls {
    isRead: boolean= false;
    isCreate: boolean= false;
    isUpdate: boolean= false;
    isDelete: boolean= false;
}