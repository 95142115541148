import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { CanActivateGuard } from "./core/services/can-activate.guard";
import { PageNotFoundComponent } from "./core/authentication/page-not-found/page-not-found.component";
import { MasterLayoutComponent } from "./layout/master-layout/master-layout.component";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./core/authentication/authentication.module').then(m => m.AuthenticatiomModule),
  },
  {
    path: "",
    component: MasterLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'review-management',
        loadChildren: () => import('./modules/review-management/review-management.module').then(m => m.ReviewManagementModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'questionModeration',
        loadChildren: () => import('./modules/question-moderation/question-moderation.module').then(m => m.QuestionModerationModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'statistics',
        loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'activity-mailing',
        loadChildren: () => import('./modules/activity-mailing/activity-mailing.module').then(m => m.ActivityMailingModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'reportScheduling',
        loadChildren: () => import('./modules/report-scheduling/report-scheduling.module').then(m => m.ReportSchedulingModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'globalComparison',
        loadChildren: () => import('./modules/global-comparison/global-comparison.module').then(m => m.GlobalComparisonModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'platformStatus',
        loadChildren: () => import('./modules/platform-status/platform-status.module').then(m => m.PlatformStatusModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SetingsModule),
        canActivate: [CanActivateGuard]
      },
      {
        path: 'product-management',
        loadChildren: () => import('./modules/product-overview/products-overview.module').then(m => m.ProductOverviewModule),
        canActivate: [CanActivateGuard]
      },
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
