import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environments';

if (environment.production) {
  enableProdMode();

  window.console.log = () => {}
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
