import { GeneralAPIResponse } from "./api-response.model";

export class ThemeTemplateModel {
    hoverEffectColor: string = "";
    link: any = "";
    documentTitle: string = "";
    copyrightText: string = "Copyright @MSM Digital systems private LTD.";
    WelcomeTitle: string = "Welcome to One2Five Review Moderation Platform";
    welcomeText: string =
      "As a MSM Digital client you get exclusive access to Social Commerce Usage.We gather reviews from different sources, store, moderate, analyze them and provide them for better business strategy.";
    largeLogo: string =
      "https://s3.eu-central-1.amazonaws.com/one2five-prod/content_themes/MSM_Digital/largelogo.png";
    themeColor: string = "#03A9F4";
    secondaryColor: string = "#006694";
    textColor: string = "#ffffff";
    copyrightLabel: string = "Copyright @MSM Digital systems private LTD.";
    welcomeTitle = "";
    privacyStatement: any = "";
    aboutUs: any = "";
    smallLogo: string = "";
    favicon: string = "";
    isThemeFound : boolean = false;
    showWindowAfterThemeApply: boolean = false;
}

export interface ThemeData extends GeneralAPIResponse {
	theme: ThemeTemplateModel;
	Locale: null;
	TypicalHoursToPost: number;
	Submissionid: null;
}