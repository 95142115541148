import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IItemModel } from '../models/datasource.models';

export enum ProductActionTypes {
    GetProductLoad = '[Product] Get Product',
    GetProductSuccess = '[Product] Get Product Success',
    GetProductFail = '[Product] Get Product Fail'
}

export class GetProductLoad implements Action {
    public readonly type = ProductActionTypes.GetProductLoad;
    
    constructor(public payload: any) { }
}

export class GetProductSuccess implements Action {
    public readonly type = ProductActionTypes.GetProductSuccess;

    constructor(public payload: IItemModel[]) { }
}

export class GetProductFail implements Action {
    public readonly type = ProductActionTypes.GetProductFail;

    constructor(public error: HttpErrorResponse) { }
}


export type ProductAction = GetProductLoad | GetProductSuccess | GetProductFail;
