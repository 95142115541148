<div class="modal-dialog modal-lg modal-xl">
  <div class="modal-content modal-container">
    <div class="modal-header"
      [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
      <div></div>
      <div class="modalHeaderTitle">
        {{ "Passkey Details" | translate }}
      </div>
      <button class="btn"
        [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
        (click)="onCloseModalWindowClick()">
        <span class="icon icon-cancel"></span>
      </button>
    </div>

    <div class="modal-body mb-1">
      <div *ngIf='visibleMarketPopup'>
        <div class="row AI_C pt-2">
          <div class="col-md-10">
            <h4>{{ marketData.marketName }}</h4>
          </div>
          <div class="col-md-2" (click)="onMarketArrowClick()">
            <div class="D-F JC_SE p-1 cr-pointer" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)"
              [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
              <span>{{ "New PassKey" | translate }}</span>
              <span [ngStyle]="{ transform: expandMarketFilterPanel ? 'rotate(90deg)' : 'rotate(0deg)'}">
                <span class="icon icon-right-arrow"></span>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="expandMarketFilterPanel" class="mt-p5 border1">
          <button id="closeIcon" (click)="expandMarketFilterPanel=false"
            [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
            <span>X</span>
          </button>
          <div class="row AI_C">
            <div class="col-lg-3 col-md-3">
              <input type="text" #descriptionInputRef [autofocus]="true" class="form-control" [(ngModel)]="passKeyDescription"
                placeholder="{{'Enter Description' | translate}}">
            </div>
            <div class="col-md-8 col-lg-8">
              <div class="D-F gap-3">
                <div class="D-F gap-2 pt-p5">
                  <label for="syndicationToggle" class="form-label fs-6"> {{ "Syndication" | translate }}</label>
                  <ui-switch [(ngModel)]="passKeySyndication"></ui-switch>
                </div>
                <div class="D-F gap-2 pt-p5">
                  <label for="productGroupingToggle" class="form-label fs-6"> {{ "Product Grouping" | translate
                    }}</label>
                  <ui-switch [(ngModel)]="includeProductGrouping"></ui-switch>
                </div>
                <div class="D-F gap-2 pt-p5">
                  <label for="passKeyEnabledToggle" class="form-label fs-6"> {{ "Enabled" | translate }}</label>
                  <ui-switch [(ngModel)]="passKeyEnabled"></ui-switch>
                </div>
                <div class="D-F gap-2 pt-p5">
                  <label for="rateLimitEnabledToggle" class="form-label fs-6"> {{ "Rate Limit" | translate }}</label>
                  <ui-switch [(ngModel)]="rateLimitEnabled"></ui-switch>
                </div>
                <input type="text" numbersOnly *ngIf="rateLimitEnabled" [autofocus]="true" class="form-control w-15"
                  [(ngModel)]="rateLimitPerMinute">
              </div>
            </div>
            <div class="col-lg-1 col-md-1">
              <button (click)="onCreateMarketPassKeyClick('submit')" class="btn btn-default"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                <span>{{ "BUTTONS.Submit" | translate }}</span>
              </button>
            </div>
          </div>
        </div>

        <table class="table table-responsive table-hover border1 mt-p5">
          <thead>
            <tr>
              <th class="T_AC w600px">{{ "Description" | translate }} </th>
              <th class="T_AC w600px">{{ "Pass" | translate }} {{ "Key" | translate }} </th>
              <th class="T_AC w350px">{{ "Created" | translate }} {{ "Time" | translate }} </th>
              <th class="T_AC w100px">{{ "Syndication" | translate }} </th>
              <th class="T_AC w100px">{{ "Product Grouping" | translate }} </th>
              <th class="T_AC w100px">{{ "Enabled" | translate }} </th>
              <th class="T_AC w200px">{{ "Rate Limit Enabled" | translate }} </th>
              <th class="T_AC w200px">{{ "Rate Limit/Minute" | translate }} </th>
              <th class="T_AC w-6">{{ "Action" | translate }} </th>
            </tr>
          </thead>
          <tbody [formGroup]="marketPassKeyForm" class="clstable_body">
            <ng-container formArrayName="marketPassKeyFields">
              <ng-container *ngFor="let passKeys of marketPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td class="T_AC">
                    <textarea formControlName="description" class="w-100 h-2"
                      placeholder="{{'Enter description' | translate}}" focus="true"></textarea>
                  </td>
                  <td class="T_AC">
                    <textarea formControlName="apiPassKey" class="w-100 h-2"></textarea>
                  </td>
                  <td class="T_AC w200px">
                    <input type="text" formControlName="requestTime" class="w-100">
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td class="T_AC w200px">
                    <ui-switch formControlName="rateLimitEnabled"></ui-switch>
                  </td>
                  <td class="T_AC w200px">
                    <input *ngIf="passKeys.controls.rateLimitEnabled.value" type="text" numbersOnly formControlName="rateLimitPerMinute" class="w-100">
                  </td>
                  <td class="w-6">
                    <div class="D-F gap-1">
                      <span *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                        (click)="onEditMarketRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">edit</i>
                      </span>
                      <span *ngIf="editableIndex == idx" [title]="'Cancel' | translate"
                        (click)="onCancelMarketRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">restore</i>
                      </span>
                      <span *ngIf="editableIndex == idx" [title]="'Save' | translate"
                        (click)="onUpdateMarketRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">save</i>
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf='visibleRetailerPopup'>
        <div class="row AI_C pt-2">
          <div class="col-md-10">
            <h4>{{ retailerData.retailerName}}</h4>
          </div>
          <div class="col-md-2" (click)="onRetailerArrowClick()">
            <div class="D-F JC_SE p-1 cr-pointer" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)"
              [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
              <span>{{ "New PassKey" | translate }}</span>
              <span [ngStyle]="{transform: expandRetailerFilterPanel ? 'rotate(90deg)' : 'rotate(0deg)'}">
                <span class="icon icon-right-arrow"></span>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="expandRetailerFilterPanel" class="mt-p5 border1">
          <button id="closeIcon" (click)="expandRetailerFilterPanel=false"
            [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
            <span>X</span>
          </button>
          <div class="row AI_C">
            <div class="col-lg-3 col-md-3">
              <div class="D-F AI_C">
                <label for="marketList" class="w-35">{{ "Brand Site" | translate }}</label>
                <angular2-multiselect class="w-100" [data]="marketList" [(ngModel)]="selectedMarkets"
                  [settings]="marketDropdownSettings" (ngModelChange)="getBrandSite($event)">
                  <c-search>
                    <ng-template>
                      <input type="text" (keyup)="onBrandSearchKeyUp($event)" [placeholder]="'Search Brand'| translate"
                        class="cls_dropdownsearchinput" />
                    </ng-template>
                  </c-search>
                  <c-item>
                    <ng-template let-item="item">
                      <span>{{ item.itemName }}</span>
                    </ng-template>
                  </c-item>
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-md-8 col-lg-8">
              <div *ngIf="selectedMarkets.length" class="row AI_C">
                <div class="col-md-4 col-lg-4">
                  <input type="text" #descriptionInputRef [autofocus]="true" class="form-control"
                    [(ngModel)]="passKeyDescription" placeholder="{{'Enter description' | translate}}">
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="D-F gap-2">
                    <div class="D-F gap-1 pt-p5">
                      <label for="syndicationToggle" class="form-label fs-6"> {{ "Syndication" | translate }}</label>
                      <ui-switch [(ngModel)]="passKeySyndication"></ui-switch>
                    </div>
                    <div class="D-F gap-1 pt-p5">
                      <label for="passKeyEnabledToggle" class="form-label fs-6"> {{ "Enabled" | translate }}</label>
                      <ui-switch [(ngModel)]="passKeyEnabled"></ui-switch>
                    </div>
                    <div class="D-F gap-2 pt-p5">
                      <label for="rateLimitEnabledToggle" class="form-label fs-6"> {{ "Rate Limit" | translate
                        }}</label>
                      <ui-switch [(ngModel)]="rateLimitEnabled"></ui-switch>
                    </div>
                    <input type="text" numbersOnly *ngIf="rateLimitEnabled" [autofocus]="true" class="form-control w-20"
                      [(ngModel)]="rateLimitPerMinute">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-md-1">
              <button (click)="onCreateRetailerPassKeyClick()" class="btn btn-default"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                <span>{{ "BUTTONS.Submit" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <table class="table table-responsive table-hover border1 mt-p5">
          <thead>
            <tr>
              <th class="table_header w-15">{{ "Description" | translate }}</th>
              <th class="table_header w-15">{{ "Pass" | translate }} {{ "Key" | translate }}</th>
              <th class="table_header w-8">{{ "Market" | translate }} </th>
              <th class="table_header w-8">{{ "Status" | translate }}</th>
              <th class="table_header w-10">{{ "Created" | translate }} {{ "Time" | translate }}</th>
              <th class="table_header w-6">{{ "Syndication" | translate }}</th>
              <th class="table_header w-8">{{ "Product" | translate }} {{ "Grouping" | translate }}</th>
              <th class="table_header w-6">{{ "Enabled"| translate}}</th>
              <th class="table_header w-10">{{ "Rate Limit Enabled"| translate}}</th>
              <th class="table_header w-10">{{ "Rate Limit/Minute"| translate}}</th>
              <th class="table_header w-6">{{ "Action" | translate }}</th>
            </tr>
          </thead>

          <tbody [formGroup]="retailerPassKeyForm" class="clstable_body">
            <ng-container formArrayName="retailerPassKeyFields">
              <ng-container *ngFor="let passKeys of retailerPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td id="table_data" class="w-20">
                    <textarea formControlName="description" class="w-100 h-2"
                      placeholder="{{'Enter description' | translate}}" focus="true"></textarea>
                  </td>
                  <td id="table_data" class="w-20">
                    <textarea formControlName="apiPassKey" class="w-100 h-2"></textarea>
                  </td>
                  <td id="table_data" class="w-8">
                    <input type="text" formControlName="marketName" class="w-100">
                  </td>
                  <td id="table_data" class="w-8">
                    <input type="text" formControlName="status" class="w-100">
                  </td>                 
                  <td id="table_data" class="w-10">
                    <input type="text" formControlName="updatedTime" class="w-100">
                  </td>
                  <td id="table_data" class="w-6">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td id="table_data" class="w-8">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td id="table_data" class="w-6">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td id="table_data" class="w-6">
                    <ui-switch formControlName="rateLimitEnabled"></ui-switch>
                  </td>
                  <td id="table_data" class="w-6">
                    <input type="text" *ngIf="passKeys.controls.rateLimitEnabled.value" numbersOnly formControlName="rateLimitPerMinute" class="w-100">
                  </td>
                  <td id="table_data" class="w-6">
                    <div class="D-F gap-1">
                      <span *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                        (click)="onEditRetailerRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">edit</i>
                      </span>
                      <span *ngIf="editableIndex == idx" [title]="'Cancel' | translate"
                        (click)="onCancelRetailerRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">restore</i>
                      </span>
                      <span *ngIf="editableIndex == idx" [title]="'Save' | translate"
                        (click)="onUpdateRetailerRowClick(idx, passKeys)">
                        <i class="material-icons cls_actionicon cr-pointer">save</i>
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>