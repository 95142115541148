import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import * as _ from 'lodash'

import { MessagesService } from 'src/app/shared/components/messages/messages.service';
import { LanguageModel } from 'src/app/shared/models/referance.model';
import { ReviewrInfo } from 'src/app/shared/models/interface';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilService {
  assetImagesPath: string = "/assets/images/";

  logoutUser = new Subject();
  private _loaderCount = 0;
  public get loaderCount() {
    return this._loaderCount;
  }
  public set loaderCount(value) {
    this._loaderCount = value;
    this.showLoader = this._loaderCount > 0;
    if (this._loaderCount < 0) {
      this._loaderCount = 0;
      console.warn("Loader Count Less that zero");
    }
  }
  private _showLoader = false;
  public get showLoader() {
    return this._showLoader;
  }
  public set showLoader(value) {
    setTimeout(() => {
      this._showLoader = value;
    }, 1);
  }

  constructor(private translateService: TranslateService) { }

  _languageSubjectRef = new BehaviorSubject<any>({ lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en' });
  languageSubjectRef$: Observable<any> = this._languageSubjectRef.asObservable();
  public changeLocale(locale: string) {
    if (locale != 'default') {
      localStorage.setItem("currentLanguage", locale.toLowerCase());
    }
    this._languageSubjectRef.next(locale);
  }

  public translateMessage(msg: string) {
    return this.translateService.instant(msg);
  }
  public translateWords(textArr: any) {
    let word = "";
    textArr.forEach((element: any, idx: number) => {
      if (idx < textArr.length - 1)
        word += this.translateService.instant(element) + " ";
      else
        word += this.translateService.instant(element);
    });
    return word;
  }

  async transalateInputText(inputText: string): Promise<string> {
    return lastValueFrom(this.translateService.get(inputText));
  }

  async translatedAndAlertMessage(_text: string) {
    let translated: any = await this.transalateInputText(_text);
    MessagesService.errorMessage(translated);
  }

  public stringToFirstCharUpper(str: String) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  public capitalizeInput(str: string) {
    return _.capitalize(str);
  }

  private _updateLanguageList: Subject<LanguageModel[]> = new BehaviorSubject<LanguageModel[]>([]);
  updateLanguageList$: Observable<LanguageModel[]> = this._updateLanguageList.asObservable();
  public updateLanguageList(languages: LanguageModel[]) {
    this._updateLanguageList.next(languages);
  }

  private _loadMenuItems: Subject<any> = new BehaviorSubject<any>(null);
  loadMenuItems$: Observable<any> = this._loadMenuItems.asObservable();
  public loadMenuItems(_menuItems: any) {
    this._loadMenuItems.next(_menuItems);
  }

  private _reviewerData = new BehaviorSubject<ReviewrInfo>({});
  getreviewrData(): Observable<ReviewrInfo>{
    return this._reviewerData.asObservable();
  }
  
  latestReviewerInfo(newUser:any){
    this._reviewerData.next(newUser); 
  }

}