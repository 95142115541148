import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { MessageItemType, MessageModel } from "./message.model";

@Injectable()
export class MessagesService {
  private static _alert: Subject<MessageModel> = new Subject<MessageModel>();
  public static alert$: Observable<MessageModel> = MessagesService._alert.asObservable();
  public static alert(message: MessageModel): void {
    MessagesService._alert.next(message);
  }

  private static _confirm: Subject<MessageModel> = new Subject<MessageModel>();
  public static confirm$: Observable<MessageModel> = MessagesService._confirm.asObservable();
  public static confirm(message: any): void {
    MessagesService._confirm.next(message);
  }
  private static _confirmation: Subject<boolean> = new Subject<boolean>();
  public static confirmation$: Observable<boolean> = MessagesService._confirmation.asObservable();
  public static confirmation(answer: boolean): void {
    MessagesService._confirmation.next(answer);
  }
  public static openConfirmationWindow(title: string, message: string): Promise<any> {
    let messageModel = new MessageModel;
    messageModel.message = message;
    messageModel.title = title;
    MessagesService._confirm.next(messageModel);

    return new Promise<void>((resolve => {
      MessagesService.confirmation$.subscribe((res: any) => resolve(res));
    }));
  }

  private static _close: Subject<any> = new Subject<any>();
  public static close$: Observable<any> = MessagesService._close.asObservable();
  public static close(message: any): void {
    MessagesService._close.next(message);
  }

  private static _notify: Subject<MessageModel> = new Subject<MessageModel>();
  public static notify$: Observable<MessageModel> = MessagesService._notify.asObservable();
  public static notify(message: MessageModel): void {
    MessagesService._notify.next(message);
  }

  public static notifyMessages(msg: string, type: MessageItemType): void {
    let message = new MessageModel();
    message.message = msg;
    message.type = type;
    MessagesService._notify.next(message);
  }

  private static _errorMessage: Subject<string> = new Subject<string>();
  public static errorMessage$: Observable<string> = MessagesService._errorMessage.asObservable();
  public static errorMessage(message: string): void {
    MessagesService._errorMessage.next(message);
  }
  public static alertMessages(msg: string, desc: string, title: string, type: MessageItemType): void {
    let messageModel = new MessageModel();
    messageModel.message = msg;
    messageModel.description = desc;
    messageModel.title = title;
    messageModel.type = type;
    MessagesService._alert.next(messageModel);
  }

  private static _successAlert: Subject<string> = new Subject<string>();
  public static _successAlert$: Observable<string> = MessagesService._successAlert.asObservable();
  public static successAlert(message: string): void {
    MessagesService._successAlert.next(message);
  }

  private static _errorAlert: Subject<string> = new Subject<string>();
  public static _errorAlert$: Observable<string> = MessagesService._errorAlert.asObservable();
  public static errorAlert(message: string): void {
    MessagesService._errorAlert.next(message);
  }
  
  private static _showFailureAlert: Subject<string> = new Subject<string>();
  public static showFailureAlert$: Observable<string> = MessagesService._showFailureAlert.asObservable();
  public static showFailureAlert(message: string): void {
    MessagesService._showFailureAlert.next(message);
  }

  private static _hideAlertMessages: Subject<boolean> = new Subject<boolean>();
  public static hideAlertMessages$: Observable<boolean> = MessagesService._hideAlertMessages.asObservable();
  public static hideAlertMessages(message: boolean = true): void {
    MessagesService._hideAlertMessages.next(message);
  }

  
  private static _popupAlertAlertMessages: Subject<string> = new Subject<string>();
  public static popupAlertAlertMessages$: Observable<string> = MessagesService._popupAlertAlertMessages.asObservable();
  public static popupAlertAlertMessages(message: string): void {
    MessagesService._popupAlertAlertMessages.next(message);
  }

  
  private static _successDetailsMessages: Subject<string[]> = new Subject<string[]>();
  public static successDetailsMessages$: Observable<string[]> = MessagesService._successDetailsMessages.asObservable();
  public static successDetailsMessages(message: string[]): void {
    MessagesService._successDetailsMessages.next(message);
  }
  
}
